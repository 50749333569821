import moment from "moment";
import React, { useMemo } from "react";
import { Card, Grid } from "semantic-ui-react";
import { useBooking } from "../../../context/booking-context";
import { Logger } from "../../../utils";

const BookingBlockDetail = ({
  bookingPropertyData,
  hotelInfo,
  checkInFormatted,
  checkoutFormatted,
  block,
}) => {
  const {
    name: room_name,
    room_description,
    min_price,
    incremental_price,
  } = block;
  const logger = Logger("BookingBlockDetail");
  const bookingCtx = useBooking();

  const { addedRooms, totalPrice } = bookingCtx.bookingState;

  const { noOfRooms } = addedRooms[0];

  const stayingDays = useMemo(() => {
    return moment(bookingPropertyData.checkout).diff(
      moment(bookingPropertyData.checkin),
      "days"
    );
  }, [bookingPropertyData.checkout, bookingPropertyData.checkin]);

  const refundable_until = moment(block.refundable_until).format(
    "HH:mm on DD MMM"
  );

  const { price, extra_charges_breakdown } = incremental_price[noOfRooms - 1];

  logger.log("log", block);

  const { tax, service, totalTaxAndCharges } =
    extra_charges_breakdown?.extra_charge.reduce(
      (a, b) => {
        a.totalTaxAndCharges = a.totalTaxAndCharges + b.amount;

        if (b.type === "VAT") a.tax = b;
        if (b.type === "SERVICECHARGE") a.service = b;
        return a;
      },
      { tax: null, service: null, totalTaxAndCharges: 0 }
    );

  logger.log("totalTaxAndCharges", totalTaxAndCharges);

  const bookingGuestsText = ({ adults, children }) => {
    let text = `${adults} adults`;
    let childrenCount = children.length;
    if (childrenCount == 1) {
      text += `, ${childrenCount} child (${children.map(
        (age) => `${age} ${age > 1 ? "years" : "year"} old`
      )})`;
    } else if (childrenCount > 1) {
      text += `, ${childrenCount} children (${children
        .map((age) => `${age} ${age > 1 ? "years" : "year"} old`)
        .join(", ")})`;
    }

    return text;
  };

  return (
    <Card.Group>
      <Card fluid>
        <Card.Content>
          <div className="payment-form-wrap">
            <div className="form-title">Booking Details</div>
            <Grid padded="vertically">
              <Grid.Column>
                <div className="selected-room-info">
                  <div className="room-info-label">Selected Room(s)</div>
                  <div className="room-info-title">
                    {noOfRooms}x{room_name}
                  </div>
                  <div className="room-info-label">{room_description}</div>
                </div>
                <div className="room-detail-list">
                  <div className="room-list-item">
                    <div className="list-label">
                      <img
                        src="./assets/img/payment/date-icon.svg"
                        alt="calendar"
                      />
                      <span>Check-In Time</span>
                    </div>
                    <div className="list-value">{checkInFormatted}</div>
                  </div>
                  <div className="room-list-item">
                    <div className="list-label">
                      <img
                        src="./assets/img/payment/date-icon.svg"
                        alt="calendar"
                      />
                      <span>Check-Out Time</span>
                    </div>
                    <div className="list-value">{checkoutFormatted}</div>
                  </div>
                  <div className="room-list-item">
                    <div className="list-label">
                      <img
                        src="./assets/img/payment/bed-icon.svg"
                        alt="calendar"
                      />
                      <span>Length of Stay</span>
                    </div>
                    <div className="list-value">{stayingDays} Night</div>
                  </div>
                  <div className="room-list-item">
                    <div className="list-label">
                      <img
                        src="./assets/img/payment/people-icon.svg"
                        alt="calendar"
                      />
                      <span>Group Size</span>
                    </div>
                    <div className="list-value">
                      {bookingGuestsText(bookingCtx.bookingState)}
                    </div>
                  </div>

                  <div className="room-list-item">
                    <div className="additional-charges-row">
                      <div className="d-flex align-items-center justify-between">
                        <div className="list-label">
                          <div>Price</div>
                          <div className="text-sm">(for all guests)</div>
                        </div>
                        <div className="list-value total-price">${price}</div>
                      </div>
                      <div className="d-flex align-items-center justify-between pt-4">
                        <div className="list-label">
                          <div className="text-sm">Additional charges</div>
                        </div>
                      </div>
                      {extra_charges_breakdown?.extra_charge.map(
                        (charge, i) => {
                          return (
                            <div
                              key={i}
                              className="d-flex align-items-center justify-between pt-4"
                            >
                              <div className="list-label">
                                <div>{charge.name}</div>
                              </div>
                              <div className="list-value">${charge.amount}</div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  {service?.charge_amount > 0 && (
                    <div className="room-list-item">
                      <div className="list-label">
                        <span>Resort fee</span>
                      </div>
                      <div className="list-value">${service?.amount}</div>
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

export const MemoizedBookingBlockDetail = React.memo(BookingBlockDetail);
export default BookingBlockDetail;
