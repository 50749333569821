import React from "react";
import { withApollo } from "react-apollo";
import * as Yup from "yup";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import PropertyMap from "../Properties/PropertyMap";
import { contactGQL } from "../../store/person/contact";
import { successToast, errorToast } from "../../components/Toaster";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CONTACT } from "../../utils/constants";
import { getClient } from "../../store/auth";
import { ToastContainer } from "react-toastify";
import mixpanel from "mixpanel-browser";

const contactClient = getClient(CONTACT);

function ContactUs() {
  const property = {
    name: "Seattle",
    id: "1",
    location: [-122.335167, 47.608013],
  };

  const resetAll = () => {
    return (
      (document.getElementById("both").checked = false),
      (document.getElementById("short").checked = false),
      (document.getElementById("long").checked = false),
      (document.getElementById("single").checked = false),
      (document.getElementById("multi").checked = false),
      (document.getElementById("condo").checked = false),
      (document.getElementById("other").checked = false)
    );
  };

  const checkbox = (value) => {
    const long = document.getElementById("long");
    const short = document.getElementById("short");
    const both = document.getElementById("both");
    if (value === "long") {
      return (
        (both.checked = false), (short.checked = false), (long.checked = true)
      );
    }
    if (value === "both") {
      return (
        (short.checked = false), (long.checked = false), (both.checked = true)
      );
    }
    if (value === "short") {
      return (
        (long.checked = false), (both.checked = false), (short.checked = true)
      );
    }
  };
  const submitManager = (e) => {
    const data = {
      multi: document.querySelector("#multi").checked,
      single: document.querySelector("#single").checked,
      condo: document.querySelector("#condo").checked,
      other: document.querySelector("#other").checked,
      long: document.querySelector("#long").checked,
      short: document.querySelector("#short").checked,
      both: document.querySelector("#both").checked,
    };
    const propertyArr = [];
    if (data.multi === true) {
      propertyArr.push("Apartment");
    }
    if (data.single === true) {
      propertyArr.push("House");
    }
    if (data.condo === true) {
      propertyArr.push("Condo");
    }
    if (data.other === true) {
      propertyArr.push("Other");
    }
    const propertyType = propertyArr.length === 0 ? null : propertyArr;
    try {
      mixpanel.track("Marketing Page Action", {
        ndbId: "0",
        sub: "Demo Request for Property Manager",
      });
      contactClient
        .mutate({
          mutation: contactGQL,
          variables: {
            contact: {
              occupation: "Property_Manager",
              email: e.email,
              notes: e.notes,
              fullName: e.name,
              company: e.company,
              phone: e.phone,
              propertyType,
              rentalType: data.long
                ? "Long_Term"
                : null || data.short
                ? "Short_Term"
                : null || data.both
                ? "Both"
                : null,
            },
          },
        })
        .then((res) => {
          resetAll();
          successToast("Thanks! We will be in touch with you shortly.");
        })
        .catch((error) => {
          resetAll();
          if (
            error.toString().includes("TypeError") ||
            error.toString().includes("read-only")
          ) {
            successToast("Thanks! We will be in touch with you shortly.");
          } else {
            errorToast("Failed to send: " + error);
          }
        });
    } catch (e) {
      errorToast("Fail to send: " + e);
    }
  };
  const submitPartners = (e) => {
    try {
      mixpanel.track("Marketing Page Action", {
        ndbId: "0",
        sub: "Demo Request for Partner",
      });
      contactClient
        .mutate({
          mutation: contactGQL,
          variables: {
            contact: {
              occupation: "Partner",
              email: e.email,
              notes: e.notes,
              company: e.company,
              fullName: e.name,
              phone: e.phone,
            },
          },
        })
        .then((res) => {
          successToast("Thanks! We will be in touch with you shortly.");
        })
        .catch((error) => {
          errorToast("Failed to send: " + error);
        });
    } catch (e) {
      errorToast("Failed to send: " + e);
    }
  };
  const submitRenters = (e) => {
    try {
      mixpanel.track("Marketing Page Action", {
        ndbId: "0",
        sub: "Demo Request for Renter",
      });
      contactClient
        .mutate({
          mutation: contactGQL,
          variables: {
            contact: {
              occupation: "Renter",
              email: e.email,
              notes: e.notes,
              fullName: e.name,
              phone: e.phone,
            },
          },
        })
        .then((res) => {
          successToast("Thanks! We will be in touch with you shortly.");
        })
        .catch((error) => {
          errorToast("Failed to send: " + error);
        });
    } catch (e) {
      errorToast("Failed to send: " + e);
    }
  };
  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <>
      <Header />
      <ToastContainer />
      <div class="banner banner-service-provider contact-banner">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <h1>Contact Leasera</h1>
              <h4>
                We help renters and property managers live and work better.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-section">
        <section class="contact-form">
          <div class="container">
            <div class="faqs-section">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link "
                    id="Managers-tab"
                    data-toggle="tab"
                    href="#Managers"
                    role="tab"
                    aria-controls="Managers"
                    aria-selected="false"
                  >
                    Property Managers
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="Renters-tab"
                    data-toggle="tab"
                    href="#Renters"
                    role="tab"
                    aria-controls="Renters"
                    aria-selected="true"
                  >
                    Renters
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="Partners-tab"
                    data-toggle="tab"
                    href="#Partners"
                    role="tab"
                    aria-controls="Partners"
                    aria-selected="false"
                  >
                    Partners
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  id="Managers"
                  role="tabpanel"
                  aria-labelledby="Managers-tab"
                >
                  <p class="contact-text">
                    We’re experiencing a new era of rental network. Your
                    management platform can no longer just offer good
                    accounting. It now needs to handle flexible leasing
                    opportunities and engage with the outside world more
                    effectively.{" "}
                  </p>
                  <p class="contact-text">
                    If you’re seeking powerful, margin boosting, service rich,
                    consumer wow’ing property management software, please fill
                    out the form and let us know how we can help!
                  </p>
                  <Formik
                    initialValues={{
                      name: "",
                      company: "",
                      email: "",
                      phone: "",
                      notes: "",
                    }}
                    onSubmit={(values, actions) => {
                      submitManager(values);
                      actions.resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Required").trim(),
                      company: Yup.string().required("Required").trim(),
                      email: Yup.string().email().required("Required").trim(),
                      phone: Yup.string()
                        .matches(phoneRegExp, "Phone number is not valid")
                        .required("Required"),
                      notes: Yup.string().required("Required").trim(),
                    })}
                    render={({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      handleReset,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form class="contact-formField" id="manager">
                        <div class="row">
                          <div class="col-12 col-lg-6">
                            <div className="form-group">
                              <label for="name">Full Name</label>
                              <Field
                                onBlur={handleBlur}
                                type="text"
                                class={`form-control ${
                                  errors.name && touched.name && "is-invalid"
                                }`}
                                name="name"
                                id="name"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="name"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="company">Company</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.company &&
                                  touched.company &&
                                  "is-invalid"
                                }`}
                                name="company"
                                id="company"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="company"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="email">Email Address</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.email && touched.email && "is-invalid"
                                }`}
                                name="email"
                                id="email"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="email"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.phone && touched.phone && "is-invalid"
                                }`}
                                name="phone"
                                id="phone"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="phone"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group checkbox-group">
                              <label class="checkbox-label">
                                Property Type (optional)
                              </label>
                              <p>What type of property do you own/manage?</p>

                              <div class="row">
                                <div class="col-3">
                                  <label class="custom-checkbox">
                                    Multifamily Apartment(s)
                                    <input
                                      type="checkbox"
                                      name="multi"
                                      id="multi"
                                      value="multi"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="col-3">
                                  <label class="custom-checkbox">
                                    Single family home(s)
                                    <input
                                      type="checkbox"
                                      name="single"
                                      id="single"
                                      value="single"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="col-2">
                                  <label class="custom-checkbox">
                                    Condo(s)
                                    <input
                                      type="checkbox"
                                      name="condo"
                                      id="condo"
                                      value="condo"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="col">
                                  <label class="custom-checkbox">
                                    Other
                                    <input
                                      type="checkbox"
                                      name="other"
                                      id="other"
                                      value="other"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group checkbox-group">
                              <label class="checkbox-label">
                                Rental Types (optional)
                              </label>
                              <p>
                                Do you/or are you seeking renters to sign a 6+
                                month lease.. then we consider it a long term
                                rental. Less than 6 months, let's talk about
                                short term management.
                              </p>
                              <div class="row">
                                <div class="col-3">
                                  <label class="custom-checkbox">
                                    Long Term Rental Property
                                    <input
                                      onClick={() => checkbox("long")}
                                      type="checkbox"
                                      name="long"
                                      id="long"
                                      value="long"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="col-3">
                                  <label class="custom-checkbox">
                                    Short Term Rental Property
                                    <input
                                      onClick={() => checkbox("short")}
                                      type="checkbox"
                                      name="short"
                                      id="short"
                                      value="short"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <div class="col">
                                  <label class="custom-checkbox">
                                    Both Long & Short Term
                                    <input
                                      onClick={() => checkbox("both")}
                                      type="checkbox"
                                      name="both"
                                      id="both"
                                      value="both"
                                      class="both"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="notes">Notes</label>
                              <Field
                                type="textarea"
                                class={`form-control ${
                                  errors.notes && touched.notes && "is-invalid"
                                }`}
                                name="notes"
                                id="notes"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="notes"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-button">
                              <button
                                type="submit"
                                class="btn btn-block btn-color"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
                <div
                  class="tab-pane fade show active"
                  id="Renters"
                  role="tabpanel"
                  aria-labelledby="Renters-tab"
                >
                  <p class="contact-text">
                    We’re experiencing a new era of rental network. Your rental
                    platform can no longer just offer payments. It now needs to
                    handle flexible leasing opportunities and engage with the
                    outside world more effectively.{" "}
                  </p>
                  <p class="contact-text">
                    If your seeking that ability to get more out of your rental
                    then fill out the form below.
                  </p>
                  <Formik
                    initialValues={{
                      name: "",
                      company: "",
                      email: "",
                      phone: "",
                      notes: "",
                    }}
                    onSubmit={(values, actions) => {
                      submitRenters(values);
                      actions.resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Required").trim(),
                      email: Yup.string().email().required("Required").trim(),
                      phone: Yup.string()
                        .matches(phoneRegExp, "Phone number is not valid")
                        .required("Required"),
                      notes: Yup.string().required("Required").trim(),
                    })}
                    render={({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      handleReset,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form class="contact-formField" id="manager">
                        <div class="row">
                          <div class="col-12 col-lg-12">
                            <div className="form-group">
                              <label for="name">Full Name</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.name && touched.name && "is-invalid"
                                }`}
                                name="name"
                                id="name"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="name"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="email">Email Address</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.email && touched.email && "is-invalid"
                                }`}
                                name="email"
                                id="email"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="email"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.phone && touched.phone && "is-invalid"
                                }`}
                                name="phone"
                                id="phone"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="phone"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="notes">Notes</label>
                              <Field
                                type="textarea"
                                class={`form-control ${
                                  errors.notes && touched.notes && "is-invalid"
                                }`}
                                name="notes"
                                id="notes"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="notes"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-button">
                              <button
                                type="submit"
                                class="btn btn-block btn-color"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
                <div
                  class="tab-pane fade"
                  id="Partners"
                  role="tabpanel"
                  aria-labelledby="Partners-tab"
                >
                  <p class="contact-text">
                    We’re experiencing a new era of rental network. Your
                    management platform can no longer just offer good
                    accounting. It now needs to handle flexible leasing
                    opportunities and engage with the outside world more
                    effectively.{" "}
                  </p>
                  <p class="contact-text">
                    If you’re seeking powerful, margin boosting, service rich,
                    consumer wow’ing property management software, please fill
                    out the form and let us know how we can help!
                  </p>
                  <Formik
                    initialValues={{
                      name: "",
                      company: "",
                      email: "",
                      phone: "",
                      notes: "",
                    }}
                    onSubmit={(values, actions) => {
                      submitPartners(values);
                      actions.resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Required").trim(),
                      company: Yup.string().required("Required").trim(),
                      email: Yup.string().email().required("Required").trim(),
                      phone: Yup.string()
                        .matches(phoneRegExp, "Phone number is not valid")
                        .required("Required"),
                      notes: Yup.string().required("Required").trim(),
                    })}
                    render={({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      handleReset,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form class="contact-formField" id="manager">
                        <div class="row">
                          <div class="col-12 col-lg-6">
                            <div className="form-group">
                              <label for="name">Full Name</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.name && touched.name && "is-invalid"
                                }`}
                                name="name"
                                id="name"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="name"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="company">Company</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.company &&
                                  touched.company &&
                                  "is-invalid"
                                }`}
                                name="company"
                                id="company"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="company"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="email">Email Address</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.email && touched.email && "is-invalid"
                                }`}
                                name="email"
                                id="email"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="email"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <Field
                                type="text"
                                class={`form-control ${
                                  errors.phone && touched.phone && "is-invalid"
                                }`}
                                name="phone"
                                id="phone"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="phone"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="notes">Notes</label>
                              <Field
                                type="textarea"
                                class={`form-control ${
                                  errors.notes && touched.notes && "is-invalid"
                                }`}
                                name="notes"
                                id="notes"
                                placeholder=""
                              />
                              <ErrorMessage
                                className="invalid-feedback"
                                name="notes"
                                component="div"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-button">
                              <button
                                type="submit"
                                class="btn btn-block btn-color"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="contact-map">
          <div class="container">
            <div class="contact-inner col-12">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="contact-mapContent">
                    <h5>Call Us</h5>
                    <p>(425) 400-7255</p>
                    <p>1866 506 0080</p>
                  </div>
                  <div class="contact-mapContent">
                    <h5>Email Us</h5>
                    <p>Questions: info@leasera.com</p>
                    <p>Help: support@leasera.com</p>
                  </div>
                </div>
                <div class="col-12 col-lg">
                  <div class="contact-mapIframe">
                    {/* <div className="property-details-map"> */}
                    <PropertyMap
                      searchStatus={true}
                      zoom={9}
                      properties={[property]}
                      isDetail={true}
                    />
                    {/* </div> */}
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d112053.88743846556!2d77.37180160000001!3d28.639231999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1618920485504!5m2!1sen!2sin" width="100%" height="225" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                  </div>
                </div>
              </div>
            </div>
            <img
              src="assets/img/marketing/square_circles.png"
              alt="Dots"
              class="fixed-square-image"
            />
          </div>
        </section>
      </div>
      <Newsletter />
      <FooterMain />
    </>
  );
}

export default withApollo(ContactUs);
