import moment from "moment";
import React, { useMemo, useState } from "react";
import { useBooking } from "../../context/booking-context";
import { AIR_CONDITIONER, FLAT_TV, Logger } from "../../utils";
import RoomDetailModal from "./RoomDetailModal";

const BookingRoomType = ({
  data,
  setNoOfRooms,
  setRoomPrice,
  setExtraCharges,
}) => {
  const [roomData, setRoomSelectionInfo] = useState([]);
  const [extraChargeBreakdown, setExtraChargeBreakdown] = useState([]);
  const bookingCtx = useBooking();
  const logger = Logger("BookingRoomType");

  const clearSelectedBlocks = (selectIndex) => {
    const selects = document.getElementsByClassName("form-select-room-ids");

    for (let i = 0; i < selects.length; i++) {
      if (i !== selectIndex) selects[i].value = "0";
    }
  };

  const optionsList = (ele) => {
    //TODO: this logic will work just in case of 1 room per order.
    const selected =
      bookingCtx?.bookingState?.addedRooms[0]?.id === ele.block_id;

    return Array.from({ length: 2 }, (_, index) => (
      <option
        key={`option_index_${ele.block_id}_${index}`}
        value={index}
        selected={selected}
      >
        {index}
      </option>
    ));
  };
  const setRoomData = (ele, copyEle) => {
    const prevData = [...copyEle];
    return prevData.reduce((acc, item) => acc + item[ele], 0);
  };

  const localJSON = (ele, value) => {
    if (value > 0)
      return {
        id: ele.block_id,
        price: ele.incremental_price[value - 1].price,
        extraCharges: calculateExtraCharges(ele, value - 1),
        noOfRooms: value,
      };
  };

  const setSelectedRoom = (e, ele, selectIndex) => {
    // const copyEle = [...roomData]; // single room can be
    const copyEle = [];
    const value = Number(e.target.value);
    let pushData = localJSON(ele, value);

    clearSelectedBlocks(selectIndex);

    if (copyEle.length === 0 && value !== 0) {
      copyEle.push(pushData);
      setRoomSelectionInfo([...copyEle]); // all rooms in this array
      bookingCtx.addRoom(pushData); // booking context add selected room
      logger.log("1!");
    } else {
      const index = copyEle.findIndex((item) => item.id === ele.block_id);
      logger.log("2!");
      if (index !== -1) {
        logger.log("3!");
        if (value === 0) {
          logger.log("4!");
          copyEle.splice(index, 1);
          setRoomSelectionInfo([...copyEle]);
          bookingCtx.deleteRoom(ele.block_id); // booking context delete selected room data
        } else {
          logger.log("5!");
          setRoomSelectionInfo([(copyEle[index] = pushData)]);
          bookingCtx.updateRoom(pushData); // booking context update selected room data
        }
      } else {
        logger.log("6!");
        copyEle.push(pushData);
        setRoomSelectionInfo([...copyEle]); // all rooms in this array
        bookingCtx.addRoom(pushData); // booking context add room
      }
    }

    logger.log("7!");

    setRoomPrice(setRoomData("price", copyEle), value, ele.name, ele.block_id);
    setNoOfRooms(setRoomData("noOfRooms", copyEle, value));
    setExtraCharges(setRoomData("extraCharges", copyEle, value));
  };

  const calculateExtraCharges = (ele, number) => {
    const charges =
      ele.incremental_price[number]?.extra_charges_breakdown?.extra_charge;
    const tempArr = [...extraChargeBreakdown];
    charges.map((ele) => {
      tempArr.push({
        type: ele.type,
        value: ele.amount,
      });
    });
    // console.log(tempArr)
    // setExtraChargeBreakdown(tempArr);
    return charges.reduce((acc, current) => current.amount + acc, 0).toFixed(2);
  };

  const cancellationTime = (info) => {
    const data = info.split(" ");
    const date = moment(new Date(data[0])).format("DD MMMM YYYY");
    const time = data[1];
    return `${time} on ${date}`;
  };

  const amenityList = (ele) => (
    <>
      <div className="room-subtitle">1 extra-large double bed</div>
      <div className="room-amenities-list">
        <div className="amenities-list-item">Rooms</div>
        <div className="amenities-list-item">
          {ele.room_surface_in_feet2 + "feet"}
        </div>
        {ele.free_wifi && <div className="amenities-list-item">Free Wi-fi</div>}
        {ele.smoking === "1" && (
          <div className="amenities-list-item">Smoking Room</div>
        )}
        {ele.room_description.includes(AIR_CONDITIONER) && (
          <div className="amenities-list-item">Air conditioning</div>
        )}
        {ele.room_description.includes(FLAT_TV) && (
          <div className="amenities-list-item">Flat screen TV</div>
        )}
      </div>
    </>
  );

  const rooms = useMemo(() => {
    return data?.block.reduce((a, b, i) => {
      if (!Object.keys(a).includes("room_" + b.room_id)) {
        a["room_" + b.room_id] = [];
      }

      a["room_" + b.room_id].push(b);

      return a;
    }, {});
  }, [data?.block]);

  return (
    <div className="booking-table-wrap">
      <div className="booking-table-head">
        <div className="table-head-col">
          <div className="booking-head-list">Room Type</div>
        </div>
        <div className="table-head-col">
          <div className="booking-head-list">Sleeps</div>
          <div className="booking-head-list">Today’s Price</div>
          <div className="booking-head-list">Your Choices</div>
          <div className="booking-head-list">Amount</div>
        </div>
      </div>
      {data?.block !== undefined &&
        Object.keys(rooms).map((roomId) => {
          const roomData = rooms[roomId][0];
          return (
            <>
              <div className="booking-table-row">
                <div className="booking-table-col">
                  <RoomDetailModal
                    data={roomData}
                    getAmenity={amenityList(roomData)}
                  />
                  {/* <div className="room-title">{roomData.name}</div> */}
                  {amenityList(roomData)}
                  <div className="room-facilities-list">
                    {roomData.facilities.map((item) => (
                      <div className="facilities-list-item">{item}</div>
                    ))}
                  </div>
                </div>
                <div className="booking-table-col">
                  {rooms[roomId].map((ele, index) => {
                    return (
                      <div className="room-table-row">
                        <div className="booking-body-list d-flex flex-wrap align-items-start">
                          {Array(ele.max_adults)
                            .fill("*")
                            .map(() => (
                              <img
                                alt="adult"
                                src="./assets/img/person-icon.svg"
                              />
                            ))}
                        </div>
                        <div className="booking-body-list price-col">
                          <div className="price-title">
                            {"$" + ele.incremental_price[0]?.price}
                          </div>
                          <div className="price-subtitle">
                            Excluded: ${calculateExtraCharges(ele, 0)}
                          </div>
                        </div>
                        <div className="booking-body-list booking-choices">
                          <ul>
                            {ele.breakfast_included && (
                              <li>Breakfast included</li>
                            )}
                            {ele.dinner_included && <li>Dinner included</li>}
                            {ele.lunch_included && <li>Lunch included</li>}
                            {ele.refundable ? (
                              <li>Refundable</li>
                            ) : (
                              <li>Non-refundable</li>
                            )}
                            {ele.can_pay_now && <li>Pay in advance</li>}
                            {ele.cancellation_info[0].fee === 0 && (
                              <li className="text-success">
                                Free cancellation until{" "}
                                {cancellationTime(
                                  ele.cancellation_info[0].until
                                )}
                              </li>
                            )}
                            {ele.payment_terms && (
                              <li>
                                {ele.payment_terms?.prepayment_description}
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="booking-body-list">
                          <select
                            className={`form-select form-select-room-ids`}
                            aria-label="Select Amount"
                            onChange={(e) => setSelectedRoom(e, ele, index)}
                          >
                            {optionsList(ele)}
                          </select>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default BookingRoomType;
