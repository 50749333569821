import React, { useState } from "react";
import { Button, Modal, Grid } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import { leadSchema, tourLeadSchema } from "./validations";
import { toast } from "react-toastify";
import { MICROSITE_INFO } from "../../utils/constants";
import { getClient } from "../../store/auth";
import {
  createTourGql,
  createLeadGql,
  tourTypesGql,
} from "../../store/person/contact";
import { CustomDropDown } from "./CustomDropDown";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

// const Context = React.createContext();
function TourSection(props) {
  /* eslint-disable */
  const micrositeClient = getClient(MICROSITE_INFO);
  const [open, setOpen] = React.useState(false);
  const [units, setUnits] = useState(props.units);
  const [propertyId, setPropertyId] = useState(props.propertyId);
  const [selectedDate, setSelectedDate] = useState("");
  const [leadStatus, setLeadStatus] = useState(null);
  const [tourTypes, setTourTypes] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [calendarTouched, setCalendarTouched] = useState(false);
  /* eslint-enable */

  /* eslint-disable */
  const tourLeadSubmit = async (values) => {
    const isValid = await leadSchema.isValid();
    createLead(values);
  };
  /* eslint-enable */

  const getTourTypes = () => {
    try {
      micrositeClient
        .query({
          query: tourTypesGql,
          variables: {
            location: propertyId,
          },
        })
        .then((response) => {
          setTourTypes(
            response.data.tourTypes.edges.filter((t) => t.node.selfBooking)
          );
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  const createLead = (obj) => {
    try {
      micrositeClient
        .mutate({
          mutation: createLeadGql,
          variables: {
            input: {
              email: obj.email,
              lastName: obj.lastName,
              firstName: obj.firstName,
              status: "Tour",
              source: "Microsite",
              phoneMobile: obj.phoneNumber,
              location: propertyId,
              preference: {
                unit: obj.unit,
              },
            },
          },
        })
        .then((response) => {
          if (
            response.data.createLead.response === null &&
            response.data.createLead.lead.id
          ) {
            setLeadStatus("Success");
            createTour(obj, response.data.createLead.lead.id);
          } else if (
            response.data.createLead.response !== null &&
            response.data.createLead.lead.id
          ) {
            createTour(obj, response.data.createLead.lead.id);
            setLeadStatus("Exists");
          } else {
            createTour(obj, response.data.createLead.lead.id);
            setLeadStatus("Failed");
            setOpen(false);
          }
        })
        .catch((e) => {
          setOpen(false);
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
  function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div
        className={classNames.overlayWrapper}
        style={{ marginTop: -350, marginLeft: -270 }}
        {...props}
      >
        <div className={classNames.overlay}>{children}</div>
      </div>
    );
  }
  const createTour = (obj, leadId) => {
    try {
      micrositeClient
        .mutate({
          mutation: createTourGql,
          variables: {
            input: {
              leadId: leadId,
              name: obj.firstName + " " + obj.lastName,
              tourType: obj.tourType,
              date: selectedDate,
              time: obj.timePreference,
              location: propertyId,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        })
        .then((response) => {
          setToastMessage(toast.success(response.data.createTour.response));
          setOpen(false);
        })
        .catch((e) => {
          setToastMessage(
            toast.warning(
              "We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."
            )
          );
          setOpen(false);
        });
    } catch (e) {
      if (leadStatus === "Success") {
        setToastMessage(
          toast.warning(
            "We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."
          )
        );
      } else {
        setToastMessage(
          toast.error("We were unable to retrieve your data, our apologies.")
        );
      }
      console.log(e);
    }
  };
  return (
    <Modal
      className="semanticModal tourRequestModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={<Button onClick={() => getTourTypes()}>Request Tour</Button>}
    >
      <Modal.Header>Schedule a Tour</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            unit: "",
            tourType: "",
            tourAgent: "",
            timePreference: "",
          }}
          validationSchema={tourLeadSchema}
          onSubmit={(values, { resetForm }) => {
            tourLeadSubmit(values);
            resetForm();
            setSelectedDate("");
          }}
        >
          {(formik) => (
            <Form
              id="tour-form"
              className="contact-form-wrap"
              style={{ background: "#F6F7FB" }}
            >
              <Grid columns="equal" relaxed>
                <Grid.Column>
                  <TextField label="First Name" name="firstName" type="text" />
                  <TextField label="Last Name" name="lastName" type="text" />
                </Grid.Column>

                <Grid.Column>
                  <TextField label="Email" name="email" type="email" />

                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    type="text"
                  />
                </Grid.Column>
              </Grid>
              <Grid columns="equal">
                <Grid.Column>
                  <CustomDropDown
                    label="Unit"
                    name="unit"
                    data="orderedUnits"
                    obj={units}
                    text="Choose a unit to tour:"
                  />

                  <CustomDropDown
                    label="Time Preference"
                    name="timePreference"
                    obj={["Morning", "Afternoon", "Evening"]}
                    text="Choose a time:"
                  />
                </Grid.Column>
                <Grid.Column>
                  <CustomDropDown
                    label="Tour Type"
                    name="tourType"
                    data="tourType"
                    obj={tourTypes}
                    text="Tour Type:"
                  />
                  <div className="row">
                    <div className="col-sm-6">
                      <label for="units" className="rld-single-input">
                        Suggest a date:
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <div
                        style={{
                          marginTop: "15px",
                        }}
                        name="dateTime"
                        onClick={() => setCalendarTouched(true)}
                        className={`${
                          selectedDate === "" &&
                          calendarTouched &&
                          "form-error-input"
                        }`}
                      >
                        <DayPickerInput
                          className="day-picker-input"
                          overlayComponent={CustomOverlay}
                          placeholder="DD/MM/YYYY"
                          style={{ background: "#f3f3f3" }}
                          value={selectedDate}
                          onDayChange={(day) =>
                            setSelectedDate(
                              new Date(day).toLocaleDateString("en-US")
                            )
                          }
                          dayPickerProps={{
                            disabledDays: { before: new Date() },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {selectedDate === "" && calendarTouched && (
                    <div
                      className="form-error-message"
                      style={{ textAlign: "right" }}
                    >
                      Please select a date
                    </div>
                  )}
                </Grid.Column>
              </Grid>
              <div className="btn-wrap text-center">
                <button
                  className="btn btn-yellow"
                  style={{ background: "green" }}
                  type="click"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default TourSection;
