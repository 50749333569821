import React from "react";
import { useHistory, withRouter, NavLink } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import Header from "../../components/Header";
import { ROUTES } from "../../utils";
import PropertySearch from "../../containers/PropertySearch";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import Testimonial from "../../components/Testimonial";

function Renter() {
  const history = useHistory();

  return (
    <>
      <Header />
      <div class="home-banner row renter-banner">
        <div className="container-fluid">
          <div
            id="carouselExampleCaptions"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="assets/img/Landing/banner-1.jpg"
                  class="carousel-bg-img"
                  alt="banner"
                />
                <div class="container d-flex align-items-center">
                  <div class="carousel-caption">
                    {/* <div class="carousel-caption-wrap"> */}
                    <h5>Welcome to a new life on leasing.</h5>
                    <p>
                      Find a new home, explore long-term furnished housing, or
                      dream big by browsing vacation spots.{" "}
                    </p>
                    {/* </div> */}
                    <div class="renter-search">
                      {/* <Form>
                        <div class="search-module-wrap">
                          <div class="search-input-wrap">
                            <div class="search-field">
                              <Form.Field>
                                <label for="searchLocation">Where are you going?</label>
                                <input class="form-fields" id="searchLocation" type="text" placeholder="Enter location" />
                              </Form.Field>
                            </div>
                            <div class="search-field">
                              <Form.Field>
                                <label for="checkinDate">Check-In Date</label>
                                <input class="form-fields" id="checkinDate" type="date" />
                              </Form.Field>
                            </div>
                            <div class="search-field">
                              <Form.Field>
                                <label for="checkoutDate">Check-Out Date</label>
                                <input class="form-fields" id="checkoutDate" type="date" />
                              </Form.Field>
                            </div>
                            <div class="search-field">
                              <Form.Field>
                                <label for="">Bed</label>
                                <select class="form-fields">
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                  <option value='3'>3</option>
                                  <option value='4'>4</option>
                                  <option value='5'>5</option>
                                </select>
                              </Form.Field>
                            </div>
                            <div class="search-field">
                              <Form.Field>
                                <label for="">Bath</label>
                                <select class="form-fields">
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                  <option value='3'>3</option>
                                  <option value='4'>4</option>
                                  <option value='5'>5</option>
                                </select>
                              </Form.Field>
                            </div>
                            <div class="search-field">
                              <Form.Field>
                                <label for="">Listing Type</label>
                                <select class="form-fields">
                                  <option value='Short Term Rental'>Short Term Rental</option>
                                  <option value='Short Term Rental'>Short Term Rental</option>
                                  <option value='Short Term Rental'>Short Term Rental</option>
                                  <option value='Short Term Rental'>Short Term Rental</option>
                                  <option value='Short Term Rental'>Short Term Rental</option>
                                </select>
                              </Form.Field>
                            </div>
                          </div>
                          <div class="search-button">
                            <Button
                              primary
                              type="submit"
                              size="huge"
                              className="renter-btn"
                              floated="right"
                            >
                              <img src="assets/img/marketing/icons-search.svg" alt="search" />
                            </Button>
                          </div>
                        </div>
                      </Form> */}
                      <PropertySearch history={history} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="home-banner renter-banner">
      <div className="container">
        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="carousel-caption">
              <div class="carousel-caption-wrap">

                
              </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div> */}
      <section class="home-widgets">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-home.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>1.4M+</h4>
                <p>Listings Available</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-money.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>$2,300+</h4>
                <p>Average renter savings</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-residents.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>13K+</h4>
                <p>Visitors each month</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-badge.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>#1</h4>
                <p>flexible rental options</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="half-image-section renter-image-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/homepage-house.svg"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/home_img_1.png"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/renter_img_1.png"
                  alt="Illustration"
                  class="fixed-top-image"
                ></img>
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
            <div class="col-md-6">
              <h3>Are you already a renter?</h3>
              <p class="text">
                Whether you rent from a Leasera property or not, we can help you
                track your rental information, automate payments, and earn
                points you can use for service or to help others in your
                community.
              </p>
              <p class="text">
                Start living that Leasera lifestyle today in just a few simple
                steps. Welcome to a new era.
              </p>
              {/* <div class="mt-5">
              <a class="homebtn">Add your rental</a>
            </div> */}
            </div>
          </div>
        </div>
      </section>
      <section class="half-image-section renter-point-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h3>Do more with points</h3>
              <p class="text">
                You're already a great consumer, and when renting, you should be
                rewarded for it. Reward yourself with services like massages,
                discounts, and fun experiences. You don’t have to be a renter to
                take advantage of your points, either.{" "}
              </p>
              <p class="text">
                Dog walks, food deliveries, hotel bookings, financial tools..
                unlock a powerful network and the points to make it even more
                affordable and fantastic.
              </p>
              {/* <div class="mt-5">
              <a class="homebtn">Get started earning rewards</a>
            </div> */}
            </div>
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/homepage-house.svg"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/renter_img_3.png"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/renter_img_2.png"
                  alt="Illustration"
                  class="fixed-top-image"
                ></img>
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="whyLeasera-section">
        <div class="container">
          {/* eslint-disable */}
          <a class="badge-btn">Leasera Marketplace</a>
          {/* eslint-enable */}
          <h4>A more accessible & convenient network of services we love.</h4>
          <p>
            A network of resources focusing their efforts right to your door,
            managed from the same application you pay your rent or speak with
            your property manager.
          </p>
          <div class="renterTab-section faqs-section">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="PetServices-tab"
                  data-toggle="tab"
                  href="#PetServices"
                >
                  <img
                    src="assets/img/marketing/icons-pet-black.svg"
                    alt="icon pet"
                  />
                  Pet Services
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="Grocery-tab"
                  data-toggle="tab"
                  href="#Grocery"
                >
                  <img
                    src="assets/img/marketing/icons-shop.svg"
                    alt="icon shop"
                  />
                  Grocery Shopping
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="Maintenance-tab"
                  data-toggle="tab"
                  href="#Maintenance"
                >
                  <img
                    src="assets/img/marketing/icons-maintenance-black.svg"
                    alt="icon maintenance"
                  />
                  Maintenance Professionals
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="LocalSites-tab"
                  data-toggle="tab"
                  href="#LocalSites"
                >
                  <img
                    src="assets/img/marketing/icons-travel.svg"
                    alt="icon local"
                  />
                  Local Sites
                </a>
              </li>
            </ul>
            <div class="tab-content nav nav-tabs" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="PetServices"
                role="tabpanel"
                aria-labelledby="PetServices-tab"
              >
                <div class="panel-heading" id="heading-A" role="tab">
                  <a
                    class="nav-link"
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img
                      src="assets/img/marketing/icons-pet-black.svg"
                      alt="icon pet"
                    />
                    Pet Services
                  </a>
                </div>
                <div
                  id="collapseOne"
                  data-parent="#myTabContent"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="heading-A"
                >
                  <div class="renter-tabContent">
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-topLeft"
                    />
                    <img
                      src="assets/img/marketing/pet-image.jpg"
                      alt="pet"
                      class="main-image"
                    />
                    <div class="renter-card">
                      <div class="renter-cardIcon">
                        <img
                          src="assets/img/marketing/icons-pet-black.svg"
                          alt="icon pet"
                        />
                      </div>
                      <h5>Pet Services</h5>
                      <p>
                        When you're a Leaseran, it means your furry friends are
                        too. We've curated pet experts that help you care for
                        your pets with experience and professionalism.
                      </p>
                      <NavLink
                        exact
                        to={ROUTES.PET_SERVICES}
                        activeClassName="active"
                      >
                        View More
                      </NavLink>
                    </div>
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-bottomRight"
                    />
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade "
                id="Grocery"
                role="tabpanel"
                aria-labelledby="Grocery-tab"
              >
                <div class="panel-heading" id="heading-B" role="tab">
                  <a
                    class="nav-link"
                    data-toggle="collapse"
                    href="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <img
                      src="assets/img/marketing/icons-shop.svg"
                      alt="icon shop"
                    />
                    Grocery Shopping
                  </a>
                </div>
                <div
                  id="collapseTwo"
                  data-parent="#myTabContent"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="heading-B"
                >
                  <div class="renter-tabContent">
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-topLeft"
                    />
                    <img
                      src="assets/img/marketing/grocery-shopImage.jpg"
                      alt="pet"
                      class="main-image"
                    />
                    <div class="renter-card">
                      <div class="renter-cardIcon">
                        <img
                          src="assets/img/marketing/icons-shop.svg"
                          alt="icon shop"
                        />
                      </div>
                      <h5>Grocery Shopping</h5>
                      <p>
                        Browse our list of services and see what delivery and
                        service options are available in your area.
                      </p>
                      <NavLink
                        exact
                        to={ROUTES.GROCERY_SHOPPING}
                        activeClassName="active"
                      >
                        View More
                      </NavLink>
                    </div>
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-bottomRight"
                    />
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="Maintenance"
                role="tabpanel"
                aria-labelledby="Maintenance-tab"
              >
                <div class="panel-heading" id="heading-C" role="tab">
                  <a
                    class="nav-link"
                    data-toggle="collapse"
                    href="#collapsethree"
                    aria-expanded="true"
                    aria-controls="collapsethree"
                  >
                    <img
                      src="assets/img/marketing/icons-maintenance-black.svg"
                      alt="icon maintenance"
                    />
                    Maintenance Professionals
                  </a>
                </div>
                <div
                  id="collapsethree"
                  data-parent="#myTabContent"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="heading-C"
                >
                  <div class="renter-tabContent">
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-topLeft"
                    />
                    <img
                      src="assets/img/marketing/maintenance-image.jpg"
                      alt="pet"
                      class="main-image"
                    />
                    <div class="renter-card">
                      <div class="renter-cardIcon">
                        <img
                          src="assets/img/marketing/icons-maintenance-black.svg"
                          alt="icon maintenance"
                        />
                      </div>
                      <h5>Maintenance Professionals</h5>
                      <p>
                        We seek the right person for the right job based upon
                        skillsets and experience. Our members simply ask.
                      </p>
                      <NavLink
                        exact
                        to={ROUTES.MARKETING_PROFESSIONAL}
                        activeClassName="active"
                      >
                        View More
                      </NavLink>
                    </div>
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-bottomRight"
                    />
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="LocalSites"
                role="tabpanel"
                aria-labelledby="LocalSites-tab"
              >
                <div class="panel-heading" id="heading-D" role="tab">
                  <a
                    class="nav-link"
                    data-toggle="collapse"
                    href="#collapsefour"
                    aria-expanded="true"
                    aria-controls="collapsefour"
                  >
                    <img
                      src="assets/img/marketing/icons-travel.svg"
                      alt="icon local"
                    />
                    Local Sites
                  </a>
                </div>
                <div
                  id="collapsefour"
                  data-parent="#myTabContent"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="heading-D"
                >
                  <div class="renter-tabContent">
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-topLeft"
                    />
                    <img
                      src="assets/img/marketing/localSite-image.jpg"
                      alt="pet"
                      class="main-image"
                    />
                    <div class="renter-card">
                      <div class="renter-cardIcon">
                        <img
                          src="assets/img/marketing/icons-travel.svg"
                          alt="icon local"
                        />
                      </div>
                      <h5>Local Sites</h5>
                      <p>
                        We put the "work" in network so that you don't have to.
                        Find the attractions & benefits of your local community
                        or ask a pro.
                      </p>
                      <NavLink
                        exact
                        to={ROUTES.LOCAL_SITES}
                        activeClassName="active"
                      >
                        View More
                      </NavLink>
                    </div>
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-bottomRight"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      <Newsletter />
      <FooterMain />
    </>
  );
}

export default compose(withRouter, withApollo)(Renter);
