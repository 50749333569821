// @flow

const waitForGlobal = (key, cb, maxWait = 2000) => {
  maxWait = maxWait - 100;
  if (typeof window !== "undefined" && window[key]) cb();
  else if (maxWait <= 0) return;
  else {
    setTimeout(function () {
      waitForGlobal(key, cb, maxWait);
    }, 100);
  }
};

export default waitForGlobal;
