import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import {
  Grid,
  Header,
  Image,
  Segment,
  Container,
  Button,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import FeaturedProperties from "../../components/FeaturedProperties";
import { ROUTES } from "../../utils/constants";
import HeaderContainer from "../../components/HeaderContainer";

import "./LandingPage.scss";
const benefits = [
  {
    title: "One lease",
    img: require("./images/icons/file.svg?inline"),
    text: "One site, any place with access to all services with your lease.",
  },
  {
    title: "One platform",
    img: require("./images/icons/house.svg?inline"),
    text: `Whether traveling for work, play or your long term stay, you’re covered.`,
  },
  {
    title: "One solution",
    img: require("./images/icons/torch.svg?inline"),
    text: `We’re constantly developing the next generation of automation & solutions for property owners & managers.`,
  },
  {
    title: "One network",
    img: require("./images/icons/planet.svg?inline"),
    text: `A variety of accommodations, services & all the tools to manage or live the lifestyle they help create.`,
  },
];

function LandingPage() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderContainer isHidden={true}>
        <FeaturedProperties history={history} />
        <Header
          as="h1"
          content="Get to know Leasera!"
          style={{
            fontSize: "2.6em",
            fontWeight: "bold",
            marginBottom: 0,
            whiteSpace: "nowrap",
            padding: "4.9rem 5rem",
          }}
        />
        <Segment vertical size="massive" className="middle-landing-background">
          <Container className="client-container">
            <Header as="h2">Leasera [lees er ah]</Header>
            <p className="paragraph-text">
              is a single source for all of your accommodations, services and
              rental market needs that gets more powerful each day.
            </p>
          </Container>
          <Image
            className="client-image"
            src="assets\img\Landing\renters.jpg"
          />
        </Segment>
        <Segment style={{ padding: "1.7em 0 4em 0" }} vertical size="massive">
          <Grid columns="2" centered>
            <Grid.Column width="6"></Grid.Column>
            <Grid.Column width="5">
              <Header
                as="h2"
                style={{
                  fontSize: "40px",
                  fontWeight: " 900",
                  letterSpacing: "0.11px",
                  color: "#021E40",
                }}
              >
                Renters
              </Header>
              <p className="paragraph-text">
                At Leasera, you can find the place of your dreams while being
                reassured that our properties are owned and operated by people
                who care about their renters, not just your rent. Build your
                future, directly access services, be rewarded.
                <br />
                <br />
                Three days or three years, Leasera is here to support your
                journey.
              </p>
              {/* <Button className="main-button" size="huge" as={Link} to={ROUTES["SIGNUP"]}>Create an account</Button> */}
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment style={{ padding: "4em 0 3em 0" }} vertical size="massive">
          <Grid columns="2" centered>
            <Grid.Column width={8} className="left-landing-text">
              {benefits.map((e, i) => (
                <>
                  <Grid row="3" key={i}>
                    <div className="warp-icon-and-heading">
                      <Grid.Row>
                        <Image src={e.img} />
                      </Grid.Row>
                      <Grid.Row className="ml-25">
                        <Header as="h2">{e.title}</Header>
                      </Grid.Row>
                    </div>
                    <Grid.Row>
                      <p className="list-text">{e.text}</p>
                    </Grid.Row>
                  </Grid>
                </>
              ))}
            </Grid.Column>
            <Grid.Column style={{ position: "relative" }} width={8}>
              <Image src="assets\img\Landing\benefits-bottom.jpg" />
              <Image
                src="assets\img\Landing\benefits-top.jpg"
                size="small"
                floated="right"
                className="detail-container-image"
              />
              <Button
                className="main-button"
                size="massive"
                as={Link}
                to={ROUTES["BLOG"]}
                style={{
                  left: "26px",
                  position: "absolute",
                  bottom: "26px",
                }}
              >
                Read More
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment style={{ padding: "0 0 2em 0" }} vertical size="massive">
          <Grid columns="2" centered>
            <Grid.Column width="8" className="left-image-container">
              <Grid>
                <Grid.Row columns={2} verticalAlign="bottom">
                  <Grid.Column width={9}>
                    <Image
                      src="assets\img\Landing\po1.jpg"
                      style={{ height: "265px" }}
                    />
                  </Grid.Column>
                  <Grid.Column width={7} style={{ paddingRight: "0" }}>
                    <Image
                      src="assets\img\Landing\po2.jpg"
                      style={{ height: "230px" }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} verticalAlign="top">
                  <Grid.Column width={7}>
                    <Image src="assets\img\Landing\po3.jpg" />
                  </Grid.Column>
                  <Grid.Column width={9} style={{ paddingRight: "0" }}>
                    <Image
                      src="assets\img\Landing\po4.jpg"
                      style={{ height: "600px" }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="8" style={{ paddingLeft: "0" }}>
              <Container className="property-text-container">
                <Header
                  as="h2"
                  style={{
                    fontSize: "40px",
                    fontWeight: " 900",
                    letterSpacing: "0.11px",
                    color: "#021E40",
                  }}
                >
                  Property Owners
                </Header>
                <p className="paragraph-text">
                  Leasera is your partner, offering the tools, retention &
                  service solutions you need for a new era of unified, verified
                  consumers. Build loyalty, create access & empower yourselves &
                  your renters.
                  <br />
                  <br />
                  Let us serve you.
                </p>
                <br />
                <br />
                {/* <Button className="main-button" size="huge" as={Link} to={ROUTES["SIGNUP"]}>Create an account</Button> */}
              </Container>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment vertical size="massive">
          <Grid columns="2" centered>
            <Grid.Column width="7" className="aboutus-left-container">
              <Container>
                <Header
                  style={{
                    fontSize: "40px",
                    fontWeight: " 900",
                    letterSpacing: "0.11px",
                    color: "#021E40",
                  }}
                >
                  Our Solution
                </Header>
                <p className="paragraph-text">
                  We have created a new business model for properties and a new
                  lifestyle of empowerment for consumers. New value, new
                  opportunities, & an all “new life on leasing™”.
                  <br />
                  <br />
                  Truly a new life on leasing. Leasera.
                </p>
                <br />
                {/* <Button className="main-button" size="huge" as={Link} to={ROUTES["SIGNUP"]}>Create an account</Button> */}
              </Container>
            </Grid.Column>
            <Grid.Column width="7" className="aboutus-right-container">
              <Container>
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      width={3}
                      textAlign="right"
                      className="aboutus-image-right"
                    >
                      <Image src={require("./images/icons/quote.svg?inline")} />
                    </Grid.Column>
                    <Grid.Column
                      width={13}
                      textAlign="left"
                      className="aboutus-text-right"
                    >
                      <p className="paragraph-text">
                        The renter becomes more savvy and sophisticated each
                        day. Companies must meet them in the middle. We’re going
                        a step further by not only serving them with the right
                        type of property, where they want it at a duration they
                        need like never before. We’re also rewarding them with
                        benefits for being the great customers they are.
                      </p>
                      <Header as="h3">Barret Newberry</Header>
                      <span>CEO & Co-Founder</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Grid.Column>
          </Grid>
        </Segment>
      </HeaderContainer>
    </>
  );
}

export default compose(withRouter, withApollo)(LandingPage);
