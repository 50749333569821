// @flow
const isProd = process.env.REACT_APP_STAGING === "production";
const isStaging = process.env.REACT_APP_STAGING === "dev";

// const BFF_URL ='https://us-central1-leasera-staging.cloudfunctions.net'
const BFF_URL =
  "https://service-location-search-ywkd3aaksq-uc.a.run.app/location_search/graphql";

module.exports = {
  env: process.env.REACT_APP_STAGING,
  isProd,
  isStaging,
  PORT: process.env.PORT || (isProd ? 8080 : 3000),
  BFF_URL,
  CUSTOM_ENV: process.env.CUSTOM_ENV,
  domain: "leasera.com",
  searchWebsiteUri: "https://leasera-production.web.app/",
  renterPortalUri: "https://dev.renter.leasera.com",
  managerPortalUri: "https://dev.manager.leasera.com",
  GRAPHQL_URL: BFF_URL,
  SENTRY_URL: "https://8375c4f116af410382124b89d8ffcbf5@sentry.io/1318669",
};
