// @flow
import moment from "moment";
import gql from "graphql-tag";

// import type { PropertyFilter as PropertyFilterResult } from './__generated__/PropertyFilter'
export const propertyFilterSchemaGql = gql`
  type PropertyFilter {
    googlePlaceId: String
    startDate: String
    endDate: String
    propertyType: String
    occupants: PropertyFilterOccupants
    place: PropertyFilterPlace
  }

  type PropertyFilterOccupants {
    rooms: Int
    adults: Int
    children: Int
  }

  type PropertyFilterPlace {
    price: PropertyFilterPlacePrice
    bedrooms: Int
    bathrooms: Int
    size: Int
    petsAllowed: Boolean
    parking: Boolean
    terrace: Boolean
  }

  type PropertyFilterPlacePrice {
    min: Int
    max: Int
  }

  extend type Query {
    propertyFilter: PropertyFilter
  }
`;
export const propertyFilterDefaults = {
  googlePlaceId: null,
  listType: 1,
  totalPrices: null,
  totalBaths: null,
  location: [47.6062095, -122.3320708],
  totalSizes: null,
  totalBeds: null,
  duration: [
    moment().format("YYYY-MM-DD"),
    moment().add(1, "days").format("YYYY-MM-DD"),
  ],
};

export const bookingPropertyFilterDefaults = {
  ...propertyFilterDefaults,
  // amenities: null,   //TO BE DISCUSSED
  adults: 1,
  children: null,
  rooms: 1,
};

export const longTermFilterDefaults = {
  ...propertyFilterDefaults,
  amenities: null,
  locationTypes: null,
};

export const allFilterDefaults = {
  ...propertyFilterDefaults,
  ...bookingPropertyFilterDefaults,
  ...longTermFilterDefaults,
};

export const propertyFilterGql = gql`
  query PropertyFilter {
    propertyFilter @client {
      googlePlaceId
      listType
      startDate
      endDate
      propertyType
      occupants {
        rooms
        adults
        children
      }
      place {
        price {
          min
          max
        }
        bedrooms
        bathrooms
        size
        petsAllowed
        parking
        terrace
      }
    }
  }
`;
