import React from "react";
import { withApollo } from "react-apollo";
import { Formik } from "formik";
import PropertySearchForm from "./PropertySearchForm";
import { propertyFilterDefaults } from "../../store/property";

import "./PropertySearch.scss";

const PropertySearch = ({
  layout = "relative",
  deviceWidth,
  isMediumDesktop,
  isTablet,
  client,
  propertyFilter,
  fromSearchResults,
  isListPage,
}) => {
  return (
    <Formik
      initialValues={{ ...propertyFilterDefaults }}
      render={({ setValues, values, setFieldValue }) => {
        return (
          <PropertySearchForm
            propertyFilter={propertyFilter}
            layout={layout}
            deviceWidth={deviceWidth}
            isMediumDesktop={isMediumDesktop}
            isTablet={isTablet}
            isListPage={isListPage}
            client={client}
            setValues={setValues}
            values={values}
            setFieldValue={setFieldValue}
            fromSearchResults={fromSearchResults}
          />
        );
      }}
    />
  );
};
export default withApollo(PropertySearch);
