import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Header as UiHeader,
  Segment,
  Container,
} from "semantic-ui-react";
import FooterMain from "../../components/Footer/FooterMain";
import { useHistory } from "react-router-dom";
import { ROUTES, MANAGER, RENTER } from "../../utils/constants";
import Header from "../../components/Header";
import "./SignUp.scss";

function SignUp() {
  const history = useHistory();
  return (
    <>
      <Header />
      <Segment vertical>
        <div className="top-signin-background">
          <Container
            textAlign="center"
            text
            className="signin-wrapper-container"
          >
            <UiHeader as="h2" textAlign="center" style={{ color: "white" }}>
              I am signing up as a :
            </UiHeader>
            <div className="middle-container">
              <Button
                primary
                type="submit"
                className="main-button siginin-btn"
                onClick={() =>
                  history.push({
                    pathname: ROUTES.SIGNUP_FORM_RENTER,
                    state: { isRenterSignUp: RENTER },
                  })
                }
              >
                Renter
              </Button>
              <Button
                primary
                type="submit"
                className="main-button siginin-btn"
                onClick={() =>
                  history.push({
                    pathname: ROUTES.SIGNUP_FORM_MANAGER,
                    state: { isRenterSignUp: MANAGER },
                  })
                }
              >
                Manager
              </Button>
            </div>
          </Container>
        </div>
      </Segment>
      <FooterMain />
    </>
  );
}
export default withRouter(SignUp);
