// @flow
import React from "react";
import { Message, Icon } from "semantic-ui-react";
import { withState, compose } from "recompose";
import { getAuthToken } from "../../store/auth";
import "./Banners.scss";

const Banners = ({
  setIsUserAgreedToDemo,
  hasClosedPointsOffer,
  setHasClosedPointsOffer,
  hasClosedCookiesReminder,
  setHasClosedCookiesReminder,
  isLoggedIn = Boolean(getAuthToken()),
}) => {
  return (
    <div className="chat-container">
      {/* {!isOpen && !isLoggedIn &&
                !hasClosedPointsOffer &&
                (typeof sessionStorage !== 'undefined' &&
                    !sessionStorage.getItem('hasClosedPointsOffer')) &&
                (<Message
                    onDismiss={() => handleDismiss(!isOpen)}>
                    <Icon name="star" />
                    <span onClick={() => {
                        if (typeof sessionStorage !== 'undefined') {
                            sessionStorage.setItem('hasClosedPointsOffer', 'true')
                        }
                        setHasClosedPointsOffer('true')
                        history.push(ROUTES.SIGNUP)
                    }}>Create a profile </span> today and we'll give you points for free! This is your first step to unlocking the incredible power of the Leasera rental community.
                </Message>)} */}
      {!hasClosedCookiesReminder &&
        typeof localStorage !== "undefined" &&
        !localStorage.getItem("hasClosedCookiesReminder") && (
          <Message className="cookies-alert">
            We use cookies to give you a safer and improved experience, by using
            the site, you consent to our use of cookies.
            <span
              onClick={() => {
                if (typeof localStorage !== "undefined") {
                  localStorage.setItem("hasClosedCookiesReminder", "true");
                }
                setHasClosedCookiesReminder("true");
              }}
            >
              <Icon name="check circle outline" />
              Accept
            </span>
          </Message>
        )}
    </div>
  );
};

export default compose(
  withState("isUserAgreedToDemo", "setIsUserAgreedToDemo", null),
  withState("hasClosedPointsOffer", "setHasClosedPointsOffer", null),
  withState("hasClosedCookiesReminder", "setHasClosedCookiesReminder", null)
)(Banners);
