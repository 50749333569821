import React, { Component } from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import ExpertsTestimonial from "../../components/ExpertsTestimonial";
import { testimonialContent } from "../../utils/common";

class PetServices extends Component {
  render() {
    const data = testimonialContent.pets;
    return (
      <>
        <div class="pet-services-wrapper">
          <Header />
          <div class="banner banner-local-sites">
            <div class="container text-center">
              <div class="row">
                <div class="col-12">
                  <h1>Pet Services</h1>
                  <h4>
                    When you're a Leaseran, it means your furry friends are too.
                    We've curated a group of pet experts that help you care for
                    your pets with experience and professionalism.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <section class="half-image-section expert-section">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="badge">pet experts</div>
                  <h3>
                    We've curated a group of pet experts that help you care for
                    your pets with experience and professionalism.
                  </h3>
                  <p class="text">
                    Giving your pet the best should come second nature. Our goal
                    is to make that process as seamless as possible.
                  </p>
                  <p class="text">
                    By giving you the tools you need to pamper your best friend
                    we aim to see everyone thrive.
                  </p>
                </div>
                <div class="col-md-6">
                  <div class="image-container">
                    <img
                      src="assets/img/marketing/pet-service.png"
                      alt="Illustration"
                      class="fixed-left-image"
                    />
                    <img
                      src="assets/img/marketing/ps_image_5.jpg"
                      alt="Plumber"
                      class="main-image"
                    />
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="full-back-image-section">
            <div class="container">
              <div class="image-container">
                <h5>Dog walking made easy</h5>
                <h3>Local, trusted dog walkers in your neighborhood</h3>
                {/* <button type="buton" class="book-now">Book a walker now</button> */}
              </div>
            </div>
          </section>
          <section class="services-platform">
            <div class="container">
              <div class="row service-platform-wrap">
                <div class="col-12 col-lg-4 services-platform-col">
                  <div class="platform-image">
                    <img
                      src="assets/img/marketing/icons-pet.svg"
                      alt="icons-pet"
                    />
                  </div>
                  <div class="platform-content">
                    <h4>Trusted dog walkers</h4>
                    <p>
                      All of our dog walking partners undergo vigorous vetting
                      by their employer in order to qualify for our platform.
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 services-platform-col">
                  <div class="platform-image">
                    <img
                      src="assets/img/marketing/icons-maintenance.svg"
                      alt="icons-maintenance"
                    />
                  </div>
                  <div class="platform-content">
                    <h4>Convenient tool</h4>
                    <p>
                      With a few clicks of a button you can give your little
                      buddy the fresh air he needs.
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 services-platform-col">
                  <div class="platform-image">
                    <img
                      src="assets/img/marketing/icons-calendar.svg"
                      alt="calendar"
                    />
                  </div>
                  <div class="platform-content">
                    <h4>Easy scheduling</h4>
                    <p>
                      One time deal or hoping to set up a recurring schedule we
                      have you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="half-image-section benifit-section">
            <div class="container">
              <div class="row">
                <div class="col-md-5">
                  <h5>Benefits of using leasera service</h5>
                  <h3>Your dog is safe with us</h3>
                  <p class="text">
                    Your dog's safety is our top priority. We only work with
                    highly rated partners in order to give peace of mind. All
                    Pet Service providers have gone through their own rigorous
                    vetting process.
                  </p>
                </div>
                <div class="col-md-7">
                  <div class="image-container">
                    <img
                      src="assets/img/marketing/pet-service.png"
                      alt="Illustration"
                      class="fixed-left-image"
                    />
                    <img
                      src="assets/img/marketing/ps_image_3.jpg"
                      alt="Plumber"
                      class="main-image"
                    />
                  </div>
                </div>
              </div>
              <div class="last-image-row">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/ps_image_2.jpg"
                    alt="Plumber"
                    class="last-image-1"
                  />
                  <img
                    src="assets/img/marketing/ps_image_1.jpg"
                    alt="Plumber"
                    class="last-image-2"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                </div>
              </div>
            </div>
          </section>
          <ExpertsTestimonial header={data.header} text={data.text} />
          <Newsletter />
          <FooterMain />
        </div>
      </>
    );
  }
}
export default PetServices;
