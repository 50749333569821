// @flow
import React, { useState, useEffect } from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  InfoWindow,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MapInfoCard from "../../../components/PropertyMapCard/MapInfoCard";

const PropertyMap = compose(
  withProps(({ height = 590 }) => ({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4&v=3.exp&language=en&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `${height}px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  })),
  lifecycle({
    componentWillMount() {
      this.setState({
        zoomToMarkers: (mapRef) => {
          if (!mapRef || this.props.zoom) return;
          const bounds = new window.google.maps.LatLngBounds();
          mapRef.props.children.forEach((child) => {
            if (child.type === Marker) {
              bounds.extend(
                new window.google.maps.LatLng(
                  child.props.position.lat,
                  child.props.position.lng
                )
              );
            }
          });
          mapRef.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  let lat;
  let long;

  const setMap = () => {
    if (props.properties.length > 0 && lat && long) {
      setProperties(props.properties);
      const center = props.searchStatus
        ? { lat: lat, lng: long }
        : { lat: 39.5, lng: -98.35 };
      setZoom(!props.searchStatus ? 4 : props.zoom);
      setDefaultCenter(center);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (props.properties[0].hotelId) {
      lat = props.properties[0].location[0];
      long = props.properties[0].location[1];
    } else {
      lat = props.properties[0].location[1];
      long = props.properties[0].location[0];
    }
    setMap();
  }, [props.properties]);
  /* eslint-enable */
  const [properties, setProperties] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState(null);
  const [defaultZoom, setZoom] = useState(null);
  let latitude;
  let longitude;
  return (
    <>
      {defaultCenter && (
        <GoogleMap
          defaultVisible={true}
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
        >
          {properties &&
            properties.map((propMark) => {
              const onClick =
                !props.isDetail && props.onClick.bind(this, propMark);
              if (propMark.location && propMark.hotelId) {
                latitude = propMark.location[0];
                longitude = propMark.location[1];
              } else {
                latitude = propMark.location[1];
                longitude = propMark.location[0];
              }
              return (
                <Marker
                  key={propMark.id}
                  defaultTitle={propMark.name}
                  onClick={onClick}
                  position={{
                    lat: propMark.location ? latitude : 47.6062,
                    lng: propMark.location ? longitude : -122.3321,
                  }}
                >
                  {!props.isDetail &&
                    props.selectedMarker?.name === propMark.name && (
                      <InfoWindow
                        visible={false}
                        onCloseClick={props.onClick.bind(this, {})}
                      >
                        <MapInfoCard
                          property={props.selectedMarker}
                          handleRedirect={props.handleRedirect}
                        />
                      </InfoWindow>
                    )}
                </Marker>
              );
            })}
        </GoogleMap>
      )}
    </>
  );
});

export default PropertyMap;
