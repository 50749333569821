import ReactStars from "react-rating-stars-component";
import React from "react";

const RatingStars = (props) => (
  <ReactStars
    count={5}
    value={props.rating}
    size={16}
    edit={false}
    activeColor="#3b1c5a"
  />
);

export default RatingStars;
