import React, { useState } from "react";
import { Button, Form, Label, Checkbox } from "semantic-ui-react";
import set from "lodash/set";
import { useHistory } from "react-router-dom";
import MoreFilters from "./MoreFilters";
import { updateUrl, getPropertyData } from "../../store/utils";
import SearchBox from "../../components/SearchBox";
import moment from "moment";
import { useBooking } from "../../context/booking-context";

function PropertySearchForm(props) {
  const bookingCtx = useBooking();
  const {
    values,
    setFieldValue,
    propertyFilter,
    isListPage,
    setLoading,
    getPropertiesList,
  } = props;

  const history = useHistory();

  const onChangeField = (key, value) => {
    if (key === "googlePlaceId") {
      set(values, key, value.googlePlaceId);
      set(values, "location", value.location);
    } else {
      set(values, key, value);
      setFieldValue(key, value);
    }
    // if (isListPage) {
    //     setLoading(true)
    //     setTimeout(() => onSubmit(propertyFilter, values), 2000);
    // }
  };
  const onSubmit = (initialValues, values) => {
    setLoading && setLoading();
    const newValues = getPropertyData({
      ...initialValues,
      ...values,
      duration: [
        moment(values.duration[0]).format("YYYY-MM-DD").toString(),
        moment(values.duration[1]).format("YYYY-MM-DD").toString(),
      ],
    });

    // Booking context guest info
    const bookingData = { adults: 1, children: [] };
    if (newValues.adults > 0) {
      bookingData.adults = newValues.aduts;
    }
    if (newValues.children && newValues.children.length > 0) {
      bookingData.children = newValues.children;
    }

    if (!newValues.children) newValues.children = [];
    bookingCtx.updateBooking(bookingData);
    // Booking context guest info - end

    isListPage && getPropertiesList(newValues);
    updateUrl({ query: newValues, pathname: "properties", history });
  };

  return (
    <Form fluid className="renter-form">
      <Form.Group>
        <Form.Field>
          {isListPage && (
            <>
              <label>{isListPage ? "" : "Location"}</label>
              <SearchBox
                id="searchbox"
                googlePlaceId={values.googlePlaceId}
                placeholder="Where are you going?"
                className="form-control"
                onChange={(newValue) => {
                  onChangeField(`googlePlaceId`, newValue);
                }}
                // hasIcon={isListPage}
              />
            </>
          )}
          {/* {isListPage && <i aria-hidden="true" className="search icon" />} */}
        </Form.Field>
        {isListPage && (
          <MoreFilters
            getPropertiesList={getPropertiesList}
            isListPage={isListPage}
            onChangeField={onChangeField}
            propertyFilter={propertyFilter}
            setFieldValue={setFieldValue}
            setLoading={setLoading}
            values={getPropertyData(values)}
          />
        )}
        {!isListPage && (
          <Button
            primary
            type="submit"
            size="huge"
            // className="homebtn"
            style={{
              backgroundImage: "linear-gradient(98deg, #3b1c5a, #374db1 163%)",
              marginTop: "5px",
            }}
            floated="right"
            onClick={() => {
              setTimeout(() => onSubmit(propertyFilter, values), 1000);
            }}
          >
            Get Started On Your Search
          </Button>
        )}
        {isListPage && (
          <Button
            onClick={() => {
              setTimeout(() => onSubmit(propertyFilter, values), 1000);
            }}
            className="btn-gradient btn-sm ml-2"
            type="submit"
          >
            Search
          </Button>
        )}
      </Form.Group>
    </Form>
  );
}

export default PropertySearchForm;
