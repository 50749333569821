import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Container, Grid, Image, Popup } from "semantic-ui-react";
import * as Yup from "yup";
import Header from "../../components/Header";
import { errorToast } from "../../components/Toaster";
import { useBooking } from "../../context/booking-context";
import { getClient } from "../../store/auth";
import { processBookingGql } from "../../store/booking/booking.mutation";
import { BOOKING_URL, formatCardNumber, Logger, ROUTES } from "../../utils";
import "./bookingPayment.scss";
import { MemoizedBookingBlockDetail } from "./components/BookingBlockDetail";
import BookingCancelationInfo from "./components/BookingCancelationInfo";
import FinePrint from "./components/FinePrint";

const bookingClient = getClient(BOOKING_URL);

const ProcessBookingSchema = Yup.object().shape({
  ccCardholder: Yup.string().required(),
  ccNumber: Yup.string().required(),
  ccExpirationDate: Yup.string().required(),
  ccCvc: Yup.string().min(3).max(4),
});

const BookingPaymentTwo = () => {
  const logger = Logger("BookingPaymenTwo");
  const bookingCtx = useBooking();
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [ccExpYear, setCcExpYear] = useState("");
  const [ccExpMonth, setCcExpMonth] = useState("");

  const {
    bookingPropertyData,
    selectedProperty: hotelInfo,
    addedRooms,
    form,
    selectedBlock, // last added block into context rooms. This will work just in single block algorithm for processBooking mutation.
  } = bookingCtx.bookingState;

  if (addedRooms.length < 1)
    history.push({ pathname: ROUTES.BOOKING_PAYMENT_ONE });

  const { checkin, checkout, block } = bookingPropertyData;
  const { checkin_checkout_times } = hotelInfo;

  const refundable_until = moment(selectedBlock.refundable_until).format(
    "HH:mm on DD MMM"
  );
  const checkInFormatted = moment(checkin).format("ddd,MMMM D,YYYY");
  const checkoutFormatted = moment(checkout).format("ddd,MMMM D,YYYY");

  const defaultParamsForComponents = {
    bookingPropertyData: { checkin, checkout },
    hotelInfo: { checkin_checkout_times },
  };

  /** create expiration date for cc */
  useEffect(() => {
    const century = new Date().getUTCFullYear().toString().slice(0, 2);
    const expDate = `${century}${ccExpYear}-${ccExpMonth}-01`;

    setFieldValue("ccExpirationDate", expDate);
  }, [ccExpMonth, ccExpYear]);

  const ProcessBookingInitializeValues = {
    ccCardholder: "",
    ccNumber: "",
    ccExpirationDate: "",
    ccCvc: "",
  };

  const paymentMutation = async (bookingParams) => {
    const logger = Logger("paymentMutation");
    const { ccCardholder, ccNumber, ccExpirationDate, ccCvc } = bookingParams;
    if (
      ccNumber < 15 ||
      ccExpYear.length < 2 ||
      ccExpMonth.length < 2 ||
      ccCvc.length < 3 ||
      ccCardholder.length < 3
    ) {
      errorToast("All fields are required");
    } else {
      const {
        checkin,
        checkout,
        hotelId,
        blockIds,
        incrementalPrices,
        firstName,
        lastName,
        address,
        zip,
        city,
        email,
        telephone,
        guestQuantities,
      } = bookingCtx.bookingState.form;

      const booking = {
        checkin,
        checkout,
        hotelId,
        blockIds,
        blockQuantities: addedRooms[0].noOfRooms.toString(), // will not work for multiple different block payment
        incrementalPrices,
        firstName,
        lastName,
        address,
        zip,
        city,
        email,
        telephone,
        guestQuantities,
        ccCardholder,
        ccNumber,
        ccExpirationDate,
        ccCvc,
      };

      if (addedRooms.length > 0) {
        booking.bookingDetails = {
          photo: selectedBlock.photos[0].url_original,
          name: selectedBlock.name,
          block: JSON.stringify(selectedBlock),
        };
      }

      try {
        setLoader(true);
        const data = await bookingClient.mutate({
          mutation: processBookingGql,
          variables: { booking },
        });
        if (data.data.processBooking) {
          const response = JSON.parse(data.data.processBooking.response);
          logger.log("response", response);
          if (response?.reservation_id) {
            bookingCtx.updateBooking({
              bookingDetails: {
                ...booking.bookingDetails,
                block: JSON.parse(booking.bookingDetails.block),
                response,
              },
            });
            history.push({
              pathname: ROUTES.BOOKING_PAYMENT_THREE,
            });
          } else {
            errorToast(response.errors[0].message);
          }
        }
        logger.log("processPayment", data);
      } catch (error) {
        errorToast(error.message);
        logger.error("processPayment", error);
      } finally {
        setLoader(false);
      }
    }
  };

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: ProcessBookingInitializeValues,
    validationSchema: ProcessBookingSchema,
    onSubmit: (values) => {
      logger.log("onSubmit", values);
      paymentMutation(values);
    },
  });

  logger.log("Formik Values", values);
  logger.error("Formik Errors", errors);

  logger.log("ProcessBookingInitializeValues", ProcessBookingInitializeValues);

  return (
    <div>
      <div className="renter-listing-header">
        <Header />
      </div>
      <div className="booking-payment-wrap">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="page-back">
                  <Image src="assets/img/back-icon.svg" alt="back" />
                  <div className="back-text">Back to detail page</div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <Card.Group>
                  <Card fluid>
                    <Card.Content>
                      <div className="payment-form-wrap">
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="guest-info-row">
                              <div className="guest-info-col">
                                <div className="guest-label">Guest Name</div>
                                <div className="guest-title">
                                  {[form.firstName, form.lastName].join(" ")}
                                </div>
                              </div>
                              <div className="guest-info-col">
                                <div className="guest-label">Guest Email</div>
                                <div className="guest-title">{form.email}</div>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </Card.Content>
                  </Card>
                </Card.Group>

                <Card.Group>
                  <Card fluid>
                    <Card.Content>
                      <div className="payment-form-wrap">
                        <div className="form-title">Payment Method</div>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="special-request-section">
                              <div className="request-title">
                                When would you like to pay?
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="payment-method-row">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="customRadio3"
                                  name="paymethod"
                                  value="pay3"
                                  checked="checked"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customRadio3"
                                >
                                  <div className="pay-info">
                                    <div className="pay-title">Pay Now</div>
                                    <div className="pay-subtitle">
                                      We will handle payment. You will receive a
                                      full refund if you change your mind before
                                      <br />
                                      {refundable_until}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid columns={1} padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="ccCardholder"
                                className={
                                  errors.ccCardholder ? "color-red" : ""
                                }
                              >
                                Card Holder *
                              </label>
                              <input
                                name="ccCardholder"
                                type="text"
                                className="form-control"
                                id="ccCardholder"
                                value={values.ccCardholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="ccNumber"
                                className={errors.ccNumber ? "color-red" : ""}
                              >
                                Card Number *:{" "}
                                <small>
                                  {formatCardNumber(values.ccNumber)}
                                </small>
                              </label>
                              <input
                                name="ccNumber"
                                type="text"
                                maxLength={16}
                                minLength={16}
                                className="form-control"
                                id="ccNumber"
                                value={values.ccNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid columns={2} padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label htmlFor="ccExpirationDate">Month *</label>
                              <input
                                type="text"
                                className="form-control"
                                id="ccExpirationDate"
                                placeholder="MM"
                                style={{ width: "calc(100% - 2px)" }}
                                value={ccExpMonth}
                                maxLength={2}
                                minLength={2}
                                onChange={(e) => {
                                  console.log(e);
                                  setCcExpMonth(e.target.value);
                                }}
                              />
                            </div>
                          </Grid.Column>

                          <Grid.Column>
                            <div className="form-group">
                              <label htmlFor="ccExpirationDate">Year *</label>
                              <input
                                type="text"
                                className="form-control"
                                id="ccExpirationDate"
                                placeholder="YY"
                                value={ccExpYear}
                                style={{ width: "calc(100% - 2px)" }}
                                maxLength={2}
                                minLength={2}
                                onChange={(e) => {
                                  console.log(e);
                                  setCcExpYear(e.target.value);
                                }}
                              />
                            </div>
                          </Grid.Column>

                          <Grid.Column>
                            <div className="form-group">
                              <div className="input-with-icon">
                                <label
                                  htmlFor="ccv2"
                                  className={errors.ccCvc ? "color-red" : ""}
                                >
                                  Cvc *
                                </label>
                                <input
                                  name="ccCvc"
                                  type="text"
                                  maxLength={4}
                                  minLength={3}
                                  className="form-control"
                                  id="ccCvc"
                                  value={values.ccCvc}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Popup
                                  content="Please find CVV on the back of your card"
                                  trigger={
                                    <Image
                                      className="info-icon"
                                      src="assets/img/info-icon.svg"
                                      alt="info"
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </Card.Content>
                  </Card>
                </Card.Group>
                <div className="payment-btn">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-color"
                    disabled={loader}
                  >
                    Complete Booking
                  </button>
                </div>
              </Grid.Column>

              {/* Right side card */}
              <Grid.Column width={6}>
                <MemoizedBookingBlockDetail
                  {...{
                    ...defaultParamsForComponents,
                    block: selectedBlock,
                    checkInFormatted: checkInFormatted,
                    checkoutFormatted: checkoutFormatted,
                  }}
                />

                {/* Cancellation card */}
                <BookingCancelationInfo
                  cancellation_info={selectedBlock.cancellation_info}
                />
                {/* Fine Print card */}
                <FinePrint />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default BookingPaymentTwo;
