// @flow
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

// import type { CreatePerson as CreatePersonResult, CreatePersonVariables } from './__generated__/CreatePerson'

export const CreatePerson = gql`
  mutation CreatePerson($input: CreatePersonInput!) {
    createPerson(input: $input) {
      person {
        ndbId
        firstName
        lastName
        dateOfBirth
        email
        phoneMobile
        pin
        identification {
          number
          country
          stateIssued
        }
      }
    }
  }
`;

export class CreatePersonMutation extends Mutation<any, any> {}
// export class CreatePersonMutation extends Mutation<CreatePersonResult, CreatePersonVariables> {}
