// @flow

const prodConfig = {
  apiKey: "AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4",
  authDomain: "leasera-200719.firebaseapp.com",
  databaseURL: "https://leasera-200719.firebaseio.com",
  projectId: "leasera-200719",
  storageBucket: "leasera-200719.appspot.com",
  messagingSenderId: "662469587140",
};

// const devConfig = {
//   apiKey: YOUR_API_KEY,
//   authDomain: YOUR_AUTH_DOMAIN,
//   databaseURL: YOUR_DATABASE_URL,
//   projectId: YOUR_PROJECT_ID,
//   storageBucket: '',
//   messagingSenderId: YOUR_MESSAGING_SENDER_ID,
// };

export const config =
  process.env.NODE_ENV === "production" ? prodConfig : prodConfig; // : devConfig;
