import React from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import ExpertsTestimonial from "../../components/ExpertsTestimonial";
import { testimonialContent } from "../../utils/common";

const MarketingProfessionals = () => {
  const header = testimonialContent.maintenance.header;
  const text = testimonialContent.maintenance.text;

  return (
    <>
      <div class="maintenance-wrapper">
        <Header />
        <div class="banner banner-local-sites">
          <div class="container text-center">
            <div class="row">
              <div class="col-12">
                <h1>Maintenance Professionals</h1>
                <h4>
                  We seek the right person for the right job based upon
                  skillsets and experience. Our members simply ask.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <section class="half-image-section expert-section">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="badge">Maintenance experts</div>
                <h3>
                  World class locally-owned maintenance professionals
                  recruiters, ready to work for you
                </h3>
                <p class="text">
                  Hire only the right people for your special projects. We
                  source top talent locally in order for you to both engage the
                  community and get your projects done in a flash.
                </p>
                <p class="text">
                  From plumbing work to roofing advice we have you covered.
                  Talent is only a click away on Leasera.
                </p>
              </div>
              <div class="col-md-6">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/house.png"
                    alt="Illustration"
                    class="fixed-left-image"
                  />
                  <img
                    src="assets/img/marketing/mp_image_1.jpg"
                    alt="Plumber"
                    class="main-image"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="full-back-image-section">
          <div class="container">
            <div class="image-container">
              <h5>Maintenance work made easy</h5>
              <h3>
                Local, trusted maintenance professionals in your neighborhood
              </h3>
              {/* <button type="buton" class="book-now">Book a maintenance professionals now</button> */}
            </div>
          </div>
        </section>
        <section class="services-platform">
          <div class="container">
            <div class="row service-platform-wrap">
              <div class="col-12 col-lg-4 services-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/mp-icons-badge.svg"
                    alt="mp-icons-badge"
                  />
                </div>
                <div class="platform-content">
                  <h4>Trustworthy professionals</h4>
                  <p>
                    We make sure our partners undergo all required background
                    checks and adhere to industry standards.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 services-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/icons-map-pin.svg"
                    alt="icons-map-pin"
                  />
                </div>
                <div class="platform-content">
                  <h4>Locally-owned</h4>
                  <p>
                    Helping the community is important to us and we aim to
                    spread that philosophy.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 services-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/icons-star-smile.svg"
                    alt="icons-star-smile"
                  />
                </div>
                <div class="platform-content">
                  <h4>Experienced</h4>
                  <p>
                    We know your projects are important to you. That's why we
                    source experience and proven talent just for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="half-image-section benifit-section">
          <div class="container">
            <div class="row">
              <div class="col-md-5">
                <h5>Benefits of using leasera service</h5>
                <h3>Why choose leasera for your maintenance needs?</h3>
                <p class="text">
                  Leasera wants to bring home projects and maintenance help
                  right to your fingertips. You shouldn't have to pay an arm and
                  a leg to get that help either, that's why we give you the
                  right person for the right price every time.
                </p>
              </div>
              <div class="col-md-7">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/house.png"
                    alt="Illustration"
                    class="fixed-left-image"
                  />
                  <img
                    src="assets/img/marketing/mp_image_2.jpg"
                    alt="Plumber"
                    class="main-image"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <ExpertsTestimonial header={header} text={text} />
        <Newsletter />
        <FooterMain />
      </div>
    </>
  );
};

export default MarketingProfessionals;
