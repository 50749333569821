import gql from "graphql-tag";

export const contactGQL = gql`
  mutation contactEmail($contact: ContactInput!) {
    contactEmail(contact: $contact) {
      response
    }
  }
`;

export const addContactGQL = gql`
  mutation addContact($contact: AddContactInput!) {
    addContact(contact: $contact) {
      response
    }
  }
`;

export const tourTypesGql = gql`
  query TourTypes($location: String!) {
    tourTypes(location: $location) {
      edges {
        node {
          id
          code
          name
          duration
          tourType
          connectionInfo
          selfBooking
        }
      }
    }
  }
`;

export const createLeadGql = gql`
  mutation CreateLead($input: CrLeadInput!) {
    createLead(lead: $input) {
      response
      lead {
        id
      }
    }
  }
`;

export const createTourGql = gql`
  mutation CreateTour($input: LeadTourInput) {
    createTour(tour: $input) {
      response
    }
  }
`;
