// @flow
import gql from "graphql-tag";

export const propertiesGql = gql`
  query location($filter: LocationInput) {
    location(filter: $filter) {
      edges {
        node {
          id
          name
          locationId
          organizationId
          totalUnits
          totalOccupied
          customId
          walkScore
          photos
          addresses {
            streetOne
            zip
            city
            state
          }
          fees
          amenities
          depositRules
          applicationConfig
          maxOccupancy
          isDelete
          yearBuilt
          createdAt
          updatedAt
          contact
          totalUnits
          location
          filteredUnits
          totalBaths
          totalPrices
          totalBeds
          totalSizes
          description
          beds
          baths
          prices
          sizes
          hotelId
          review
          address
          hotelUrl
          stars
          fees
          totalPrices
          duration
          rooms
          adults
          children
        }
      }
    }
  }
`;
export const unitsGql = gql`
  query unit($input: UnitInput!) {
    unit(filter: $input) {
      edges {
        node {
          id
          locationId
          unitTypesId
          photos
          amenities
          floor
          building
          number
          description
          bathrooms
          bedrooms
          sqft
          price
          petsPermitted
          isDelete
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getAmenities = gql`
  query {
    amenities {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const bookingAmenities = gql`
  query {
    hotelFacilities {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const getLocationType = gql`
  query {
    locationType {
      edges {
        node {
          id
          type
        }
      }
    }
  }
`;

export const shortTermDetail = gql`
  query blockAvailability($filter: BlockAvailabilityInput!) {
    blockAvailability(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const hotelDetail = gql`
  query hotelDetail($filter: HotelDetailInput!) {
    hotelDetail(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
