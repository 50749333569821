import React from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";
import Testimonial from "../../components/Testimonial";

const Manager = () => {
  return (
    <>
      <div class="property-manager-wrapper">
        <Header />
        <div class="home-banner row">
          <div className="container-fluid">
            <div
              id="carouselExampleCaptions"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/img/Landing/banner-1.jpg"
                    class="carousel-bg-img"
                    alt="banner"
                  />
                  <div class="container d-flex align-items-center">
                    <div class="carousel-caption">
                      <div class="carousel-caption-wrap">
                        <h5>Enter a new era of rental management. </h5>
                        <p>
                          Your software can no longer just offer maintenance and
                          accounting. Thanks to Leasera, you can now handle
                          flexible leasing opportunities and engage a supportive
                          network. You’re now part of an experience.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="points-card-wrap">
                  <img src="assets/img/points-icon.svg" alt="points" />
                  <div class="points-title">Get 500 Loyalty Points</div>
                  <div class="points-subtitle">
                    Lorem / dolor sit amet, con adipiscing elit amet.{" "}
                  </div>
                  {/* eslint-disable */}
                  <a href="#" class="btn-white mt-3">
                    Sign Up Now
                  </a>
                  {/* eslint-enable */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="home-widgets manager-widgets">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-3 widget-col">
                <div class="widget-rw">
                  <div class="widget-image cost-saving">
                    <img
                      src="assets/img/marketing/icons-money.svg"
                      alt="icon home"
                    />
                  </div>
                  <div class="widget-content">
                    <h4>Cost Savings</h4>
                    <p>
                      No SaaS fees. Lower your costs by leveraging our
                      affordable service platform. Get more for way less
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 widget-col">
                <div class="widget-rw">
                  <div class="widget-image">
                    <img
                      src="assets/img/marketing/icons-pie-chart.svg"
                      alt="icon home"
                    />
                  </div>
                  <div class="widget-content">
                    <h4>Increase in Retention</h4>
                    <p>
                      Increase your renter retention & renewals dramatically.
                      Our services help renters and your bottom line.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 widget-col">
                <div class="widget-rw">
                  <div class="widget-image">
                    <img
                      src="assets/img/marketing/icons-time.svg"
                      alt="icon home"
                    />
                  </div>
                  <div class="widget-content">
                    <h4>Decreased Response Time</h4>
                    <p>
                      Our fully integrated network allows for seamless
                      engagement with a host of on-demand services.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 widget-col">
                <div class="widget-rw">
                  <div class="widget-image">
                    <img
                      src="assets/img/marketing/icons-star-smile-blue.svg"
                      alt="icon home"
                    />
                  </div>
                  <div class="widget-content">
                    <h4>Increased Satisfaction</h4>
                    <p>
                      Renters witness dramatic increase in satisfaction across
                      the board. Immediately fill the gaps & start wow’ing.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="half-image-section home-image-section manager-time-wrap">
          <div class="container">
            <div class="row">
              <div class="col-md-6 left-wrap">
                <h3>Save time and money with our fully unified platform</h3>
                <p class="text">
                  Support your renters with award winning services, support and
                  access. Manage any kind of property, for any duration of lease
                  you choose.
                </p>
                <div class="win-rw">
                  <img
                    src="assets/img/marketing/property-manager-1.png"
                    alt="Illustration"
                    class="fixed-left-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_2.png"
                    alt="Plumber"
                    class="main-image"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                  <div class="content-rw">
                    <h4>Differentiate & Win</h4>
                    <p>
                      Whether you're listing, building leads or closing
                      applications, you now have an affordable, powerful
                      solution.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 right-wrap">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/pm_image_8.png"
                    alt="Illustration"
                    class="fixed-left-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_1.jpg"
                    alt="Plumber"
                    class="main-image"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                </div>
                <div class="management-wrapper">
                  <img
                    src="assets/img/marketing/property-manager-2.png"
                    alt="Illustration"
                    class="fixed-top-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_3.png"
                    alt="Illustration"
                    class="fixed-middle-image"
                  />
                  <img
                    src="assets/img/marketing/property-manager-2.png"
                    alt="Illustration"
                    class="fixed-bottom-image"
                  />
                  <div class="content-rw">
                    <h4>Maintenance Management</h4>
                    <p>
                      Whether you have your own service team or want to quickly
                      source and schedule a professional, Leasera's network has
                      you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="half-image-section property-section">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_10.jpg"
                    alt="Illustration"
                    class="renter-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_9.png"
                    alt="Illustration"
                    class="fixed-top-image"
                  />
                  <img
                    src="assets/img/marketing/homepage-house.svg"
                    alt="Illustration"
                    class="fixed-left-image"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <h3>Property Management Software that Wow's</h3>
                <p class="text">
                  If you’re seeking powerful, margin boosting, service rich,
                  consumer wow’ing property management software, welcome! Fill
                  listings with a single, affordable click.
                </p>
                <p class="text">
                  Our sophisticated digital approach frees you to focus
                  precisely and exclusively on information that will improve
                  time, energy and financial efficiencies. We’ve worked hard to
                  cut unnecessary costs and stress for you.
                </p>
                <div class="mt-5">
                  {/* eslint-disable */}
                  <a class="homebtn">Get Started Now</a>
                  {/* eslint-enable */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="whyLeasera-section explore-services-section">
          <div class="container-fluid">
            {/* eslint-disable */}
            <a class="badge-btn">Our Services</a>
            {/* eslint-enable */}
            <h4>Explore our services</h4>
            <p>Continually putting your interests first.</p>

            <div class="row">
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-1.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Property Marketing</div>
                  <div class="services-card-description">
                    We market rental homes aggressively, using traditional and
                    digital listings to connect with renters across the Seattle
                    metro. We work quickly to fill your vacany. We are the best
                    in Seattle Property Management.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-2.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Tenant Screening</div>
                  <div class="services-card-description">
                    Every application we receive undergoes careful screening to
                    keep you protected. We only place well-qualified tenats that
                    meet our stringent requirements.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-3.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Maintenance</div>
                  <div class="services-card-description">
                    Keep your home in great shape by letting us handle its
                    maintenace. We respond to requests, dispatch service
                    providers, and make sure the work meet our standards.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-4.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Rent Collection</div>
                  <div class="services-card-description">
                    Get your rent payment in on time. We handle all monthly
                    billing responsibilities and manage tenant relationships. We
                    distribute your earnings via ACH transfer.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-5.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Property Inspections</div>
                  <div class="services-card-description">
                    Stop worrying about your rental home. We schedule frequent
                    inspections to make certain your tenant is taking good care
                    of your property.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-6.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Financial Reporting</div>
                  <div class="services-card-description">
                    Our accounting experts create detailed financial reports
                    each month to document your recent performance. Access them
                    at any time by logging into your account.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-7.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Evictions</div>
                  <div class="services-card-description">
                    When a bad situation takes a turn for the worse, it’s
                    important to be prepared. We offer eviction support and walk
                    you through the process of legally removing the bad apple.
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services-card">
                  <img
                    src="assets/img/marketing/services-8.svg"
                    alt="icon"
                    class="services-icon"
                  />
                  <div class="services-card-title">Legal Guidance</div>
                  <div class="services-card-description">
                    Don’t find yourself in a legal conundrum. From HOA rules to
                    local housing ordinances, we work diligently to ensure your
                    property respects all applicable laws.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="whyLeasera-section lease-management-section half-image-section">
          <div class="container">
            {/* eslint-disable */}
            <a class="badge-btn">Lease Management</a>
            {/* eslint-enable */}
            <h4>Digitally manage your leasing activity and beyond</h4>
            <p>
              You've probably noticed, property management isn't just about the
              property or accounting anymore. Get more with Leasera.
            </p>

            <div class="row">
              <div class="col-md-6">
                <div class="win-container">
                  <div class="win-rw">
                    <img
                      src="assets/img/marketing/pm_image_5.png"
                      alt="Illustration"
                      class="fixed-left-image"
                    />
                    <img
                      src="assets/img/marketing/pm_image_2.png"
                      alt="Plumber"
                      class="main-image"
                    />
                    <img
                      src="assets/img/marketing/square_circles.png"
                      alt="Dots"
                      class="fixed-square-image"
                    />
                    <div class="content-rw">
                      <br></br> <br></br>
                      <h4>Manage Leasing Activity</h4>
                      <p>
                        We'll handle all of your administrative and reporting
                        needs from one single location, to an entire portfolio
                        of rentals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/about-house.png"
                    alt="Dots"
                    class="fixed-top-image"
                  />
                  <img
                    src="assets/img/marketing/square_circles.png"
                    alt="Dots"
                    class="fixed-square-image"
                  />
                  <img
                    src="assets/img/marketing/pm_image_6.jpg"
                    alt="Illustration"
                    class="renter-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="manage-properties-section half-image-section">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                {/* eslint-disable */}
                <a class="badge-btn">Property Management</a>
                {/* eslint-enable */}
                <h4>
                  Powerful system to manage all properties, units & renter
                  needs.
                </h4>
                <p>
                  Today's era of leasing activities go far beyond maintenance,
                  lead generation or accounting. You need software that goes
                  beyond as well, supporting you, your team and offering you an
                  affordable way of scaling operations.
                </p>
                <button type="buton" class="book-now">
                  List your property now
                </button>
              </div>
              <div class="right-image">
                <div class="image-container">
                  <img
                    src="assets/img/marketing/pm_image_7.png"
                    alt="Illustration"
                    class="renter-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </section>
        <Testimonial />
        <Newsletter />
        <FooterMain />
      </div>
      {/* <Segment className="manager">
      <div className="manager-image">
        <div className="manager-text-wrapper">
          <h1>Property Managers</h1>
          <h2>Let us serve you</h2>
        </div>
      </div>
      <Segment className="manager-what-we-did" attached>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
                <h1>What we did.</h1>
                <p>
                  Leasera is your partner, offering the tools, retention & service solutions you need for a new
                  era of unified, verified consumers. Build loyalty, create access & empower yourselves & your renters.
                  </p>
              </Grid.Column>
              <Grid.Column width={5}>
                <Image src={D_IMG} size='large' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment className="manager-points" attached>
        <Container>
          <h1>Our team.</h1>
          <p>
            Property managers, property owners and real estate investment groups can use Leasera to manage full lifecycle of long-term resident with real ease and operational
            intelligence. Our analytics and engagement system digitally automates the management processes including marketing,
            lead management, long term royalty, expedient turnover and tons more. Recommendations are driven by platform's
            proprietary machine learning and predictive analysis system. Our integrations and data drive decisions and recommendations in real-time for quick review and easy creation of the most
            important tasks.</p>
          <p>The people we partner with, our clients, can finally start focusing on relationships, not transactions
                  </p>
        </Container>
      </Segment>
      <Segment className="manager-benefits" attached>
        <Container>
          <h1>Benefits</h1>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Image src={SMILE} size='large' />
              </Grid.Column>
              <Grid.Column width={11}>
                <p>
                  We have created a new business model for properties and a new lifestyle of empowerment for
                  consumers. New value, new opportunities, & an all “new life on leasing™”.  Leasera is more than
                  just a platform, it is a lifestyle where opportunities are cosntantly presenting themselves.
                  </p>
                <p>
                  Leasera is a platform that inspirse users to continue their life journey with the Apartments they love.
                  By giving the Renter added benefits, Leasera offers the unique opportunity to add additional value to both the Manager and the Renter.
                  Good behavior and practices are rewarded, leading to more desirable and steadfast renters.
                  </p>
                <p>
                  Even though we've already served millions of people nation-wide, this is just the beginning of what we have planned. With our growing yet highly vetting team, the services
                  we're bringing to market will forever change the way people look at extended living.
                  </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment >
      <Segment className="manager-dynamic" attached>
        <Container>
          <h1>Our managers.</h1>
          <p>As a property manager on our platform you are able to unlock the unique power that Leasera offers. Regardless of how
          many doors you own, you can always count on Leasera having your back.  Efficiency and cost effective relationships are
          initiated from the first click. Using our proprietary bio-graph and profile evaluation rating, we can use secondary
          background and financial data, lifestyle goals and personal preferences to better serve you.
                  </p>
          <p>
            Our sophisticated digital approach frees you to focus precisely and exclusively on information that will improve time, energy and financial
            efficiencies. We’ve worked hard to cut unnecessary costs, stress and repeat information requests out of your day so you can find the perfect renters!
                  </p>
        </Container>
      </Segment>
      <FooterMain />
    </Segment > */}
    </>
  );
};

export default Manager;
