import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../utils";

const LOCAL_STORAGE_SAVE = "_bkh";
const EXPIRE_AFTER_MIN = 10;

const initialState = {
  addedRooms: [],
  selectedProperty: null,
  bookingPropertyData: null,
  selectedBlock: null, // last added block into context rooms. This will work just in single block algorithm for processBooking mutation.
  totalPrice: 0,
  form: {
    workTravel: false,
    bookingForSelf: false,
    firstName: "",
    lastName: "",
    emailAddress: "",
    confirmEmailAddress: "",
    nameOfGuests: "",
    specialRequest: "",
    estimateTime: "Not sure",
  },
  updatedAt: moment(),
  adults: 0,
  children: [], // [12,3,1] // 12 years old, 3 years old, 1 year old
};

const BookingContext = createContext(undefined);

const useBooking = () => useContext(BookingContext);

const BookingProvider = ({ children }) => {
  const [bookingState, setBookingState] = useState(initialState);
  const logger = Logger("BOOKING_CONTEXT");

  const updateBooking = (upState) => {
    setBookingState((prevState) => {
      logger.log("updateBooking", prevState, upState);
      return {
        ...prevState,
        ...upState,
        updatedAt: moment(),
      };
    });
  };

  const setSelectedProperty = (data) => {
    logger.log("setSelectedProperty", data);
    updateBooking({ selectedProperty: data });
  };

  const addRoom = (room) => {
    logger.log("addRoom", room);
    const { block } = bookingState.bookingPropertyData;

    setBookingState((prevState) => ({
      ...prevState,
      // addedRooms: [...prevState.addedRooms, room],
      addedRooms: [room],
      updatedAt: moment(),
      selectedBlock: block.filter((b) => b.block_id === room.id)[0],
    }));
  };

  const deleteRoom = (block_id) => {
    logger.log("deleteRoom", block_id);
    setBookingState((prevState) => ({
      ...prevState,
      addedRooms: prevState.addedRooms.filter((a) => a.id !== block_id),
      updatedAt: moment(),
    }));
  };

  const updateRoom = (room) => {
    logger.log("updateRoom", room);
    setBookingState((prevState) => ({
      ...prevState,
      updatedAt: moment(),
      addedRooms: prevState.addedRooms.reduce((a, b, i) => {
        if (a.id === room.id) {
          if (room.noOfRooms !== a.noOfRooms) {
            a[i] = room;
            console.log("room", room);
          }
        } else {
          a.push(b);
        }
        return a;
      }, []),
    }));
  };

  const saveBookingState = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_SAVE,
      JSON.stringify(bookingState)
    );
  };

  const loadBookingState = () => {
    const data = window.localStorage.getItem(LOCAL_STORAGE_SAVE);
    if (data !== null) {
      if (
        moment(data.updatedAt)
          .add(EXPIRE_AFTER_MIN, "minutes")
          .isAfter(moment())
      ) {
        console.log("LOADED");
        updateBooking({ ...JSON.parse(data), addedRooms: [] });
      } else {
        clearBookingState();
      }
    }
  };

  const clearBookingState = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_SAVE);
  };

  useEffect(() => {
    loadBookingState();
  }, []);

  useEffect(() => {
    logger.log("bookingState", bookingState);
    saveBookingState();
  }, [bookingState]);

  return (
    <BookingContext.Provider
      value={{
        bookingState,
        setBookingState,
        updateBooking,
        addRoom,
        deleteRoom,
        setSelectedProperty,
        updateRoom,
        saveBookingState,
        loadBookingState,
        clearBookingState,
      }}
      displayName="Booking Context"
    >
      {children}
    </BookingContext.Provider>
  );
};

const withBookingProvider = (Component) => (props) => {
  return (
    <BookingProvider>
      <Component {...props} />
    </BookingProvider>
  );
};

export { BookingContext, BookingProvider, useBooking, withBookingProvider };
