// @flow
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
// import type {
//   UpdatePerson as UpdatePersonResult,
//   UpdatePersonVariables,
// } from './__generated__/UpdatePerson'

export const UpdatePerson = gql`
  mutation UpdatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        ndbId
        firstName
        lastName
        dateOfBirth
        email
        phoneHome
        phoneMobile
        verificationType
        verificationNumber
        addresses {
          street
          postcode
          city
          state
        }
        identification {
          number
          country
          stateIssued
          # identType
          # identCountry
        }
        residencies {
          price
          relocationReason
          startDate
          endDate
          manager {
            name
            phone
          }
          address {
            street
            city
            state
            postcode
          }
        }
        employment {
          title
          supervisor
          salary
          startDate
          endDate
          employer
          employerPhone
          address {
            street
            city
            state
            postcode
          }
        }
        criminalEviction {
          isEvicted
          isConvictedOfMisdemeanor
          isConvictedOfFelony
          # files {
          #   id
          #   url
          # }
        }
        references {
          name
          title
          phone
          email
        }
      }
    }
  }
`;

export class UpdatePersonMutation extends Mutation {}
// export class UpdatePersonMutation extends Mutation<UpdatePersonResult, UpdatePersonVariables> {}
