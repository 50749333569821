import React from "react";
import { Card, Grid } from "semantic-ui-react";

export default function FinePrint() {
  return (
    <Card.Group>
      <Card fluid>
        <Card.Content>
          <div className="payment-form-wrap">
            <div className="form-title">The Fine Print</div>
            <Grid padded="vertically">
              <Grid.Column>
                <div className="room-fine-print">
                  <p>
                    Guests must be 21 years of age or older to check in without
                    a parent or official guardian.
                  </p>
                  <p>A non-refundable pet fee applies.</p>
                  <p>
                    Please note that pets of a non-aggressive breed are
                    permitted and must weigh 11 kg or less. The pet must have
                    up-to-date vaccination records. Only 1 pet is permitted per
                    suite. A non-refundable pet fee applies.
                  </p>
                  <p>
                    In response to Coronavirus (COVID-19), additional safety and
                    sanitation measures are in effect at this property.
                  </p>
                  <p>
                    Food &amp; beverage services at this property may be limited
                    or unavailable due to Coronavirus (COVID-19).
                  </p>
                  <p>
                    Due to Coronavirus (COVID-19), this property is taking steps
                    to help protect the safety of guests and staff. Certain
                    services and amenities may be reduced or unavailable as a
                    result.
                  </p>
                  <p>
                    Guests are required to show a photo identification and
                    credit card upon check-in. Please note that all Special
                    Requests are subject to availability and additional charges
                    may apply.
                  </p>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}
