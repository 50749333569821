import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuth } from "../Auth/index.js";
import ForgotPassworSuccess from "./ForgotPassSuccess";

function ForgotPassworForm({ setLoggedStatus, history }) {
  const { resetPassword } = useAuth();
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleLogin = (values) => {
    resetPassword(values.email)
      .then((link) => {
        setOpenConfirm(true);
        window.$("#forgotPassword").modal("toggle");
      })
      .catch((err) => {
        // setLoading(false)
        toast.error(err.message, {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  return (
    <>
      <div
        class="modal fade"
        id="forgotPassword"
        tabindex="-1"
        role="dialog"
        aria-labelledby="forgotPasswordModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content forgot-modal">
            <div class="modal-header">
              <h5 class="modal-title" id="forgotPasswordModalLabel">
                Forgot Password
              </h5>
              <p class="modal-sutitle">
                Please provide your email address and we’ll send reset password
                instructions.
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/marketing/close-modal.svg" alt="close" />
              </button>
            </div>
            <div class="modal-body">
              <Formik
                initialValues={{ email: "" }}
                onSubmit={async (values) => {
                  await handleLogin(values, true);
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required("Required"),
                })}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  handleSubmit,
                }) => (
                  <Form textAlign="center">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        className={`form-control ${
                          errors.email && "is-invalid"
                        }`}
                        type="email"
                        name="email"
                        placeholder="Please enter your email address..."
                      />
                      <ErrorMessage
                        className="invalid-feedback"
                        name="email"
                        component="div"
                      />
                    </div>
                    <button
                      primary
                      type="submit"
                      class="btn btn-block btn-color"
                      onClick={handleSubmit}
                    >
                      Send a link
                    </button>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {openConfirm && <ForgotPassworSuccess history={history} />}
    </>
  );
}

export default withApollo(ForgotPassworForm);
