// @flow
import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import {
  RELOAD,
  EXPIRE_TIME,
  SESSION_TIMEOUT_THRESHOLD,
  USER_ROLE_EVENT,
  USER_EVENT,
  RENTER,
  MANAGER,
  SERVICEPROFESSIONAL,
  TOKEN,
  PERSON_FN_URL,
  ROUTES,
} from "../../utils";
import Cookie from "js-cookie";
import { onRequestSuccess } from "../../store/auth/loginAction";
import { getEmitter } from "../../store/utils";
import SignIn from "../../containers/SignIn";
import UserMenu from "../UserMenu";
import { personGql } from "../../store/person";
import {
  getAuthToken,
  logoutAction,
  getUserRoles,
  getClient,
} from "../../store/auth";
import mixpanel from "mixpanel-browser";

const eventEmit = getEmitter();

class HeaderMain extends Component {
  _initAuth = false;
  _userPresent = false;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoggedIn: false,
      person: {},
      userRoles: [],
      shouldRedirect: false,
      to: "",
      isloading: false,
      showSignIn: false,
      isRenter: true,
      currentAction: "signIn",
    };
  }
  componentDidMount = async () => {
    // this.setLoggedStatus() //handling reload
    eventEmit.on(USER_EVENT, (data) => this.setLoggedStatus(data));
    eventEmit.on(USER_ROLE_EVENT, this.setUserRole);
    this.authInit();
    // this.getUserRole()
    this.setUserRole(); //handling reload
    this.getPersonInfo();
  };

  componentDidUpdate() {
    const header = document.getElementById("header");
    this.authInit();
    this.getUserRole();
    window.onscroll = () => {
      if (window.pageYOffset > 1) {
        header.classList.add("bg-color");
      } else {
        header.classList.remove("bg-color");
      }
    };
  }

  componentWillUnmount() {
    eventEmit.removeEventListener(USER_EVENT, this.setLoggedStatus);
    eventEmit.removeEventListener(USER_ROLE_EVENT, this.setUserRole);
  }

  headerClass = (type) => {
    const element = document.getElementById("header");
    if (type === "add") {
      element.classList.add("active-menu");
    } else {
      element.classList.remove("active-menu");
    }
  };

  setLoggedStatus = (data) => {
    this.setState({
      isLoggedIn: Boolean(getAuthToken()),
      person: data ? data.data.data : {},
    });
    if (!Boolean(getAuthToken())) {
      this.props.history.push("/");
    }
  };

  setLoading = (status) => {
    this.setState({ isloading: status });
  };
  getPersonInfo = () => {
    if (Cookie.get(TOKEN)) {
      this.setState({ loading: true });
      const newClient = getClient(PERSON_FN_URL);
      try {
        newClient
          .query({
            query: personGql,
          })
          .then((res) => {
            this.setState({
              person: res.data,
              loading: false,
            });
            return res;
          })
          .catch((e) => {
            this.setState({ loading: false });
            console.log(e);
            return;
          });
      } catch (e) {
        this.setState({ loading: false });
        return;
      }
    }
  };

  getUserRole = () => {
    if (
      this.props.auth &&
      this.props.auth.auth &&
      this.props.auth.auth.currentUser &&
      !this._userPresent
    ) {
      this._userPresent = true;
      if (
        Cookie.get(RENTER) === "undefined" &&
        Cookie.get(MANAGER) === "undefined" &&
        Cookie.get(SERVICEPROFESSIONAL) === "undefined"
      ) {
        getUserRoles(this.props.auth);
      }
    }
  };

  setUserRole = () => {
    const renterRole = Cookie.get(RENTER) === "true" ? RENTER : "";
    const managerRole = Cookie.get(MANAGER) === "true" ? MANAGER : "";
    const serviceProfessionalRole =
      Cookie.get(SERVICEPROFESSIONAL) === "true" ? SERVICEPROFESSIONAL : "";
    this.setState({
      userRoles: [renterRole, managerRole, serviceProfessionalRole],
    });
  };

  authInit = () => {
    if (this.props.auth && !this._initAuth) {
      this._initAuth = true;
      const currentTime = new Date().getTime();
      const expireTime = parseInt(Cookie.get(EXPIRE_TIME));
      // refresh token when there are 5 minutes left for expiry or has exceeded it
      if (
        currentTime < expireTime &&
        expireTime - currentTime <= SESSION_TIMEOUT_THRESHOLD * 1000
      ) {
        onRequestSuccess(this.props.client, RELOAD);
      }
    }
  };

  handleModalToggle = (isOpen, isLoggedIn) => {
    if (!isLoggedIn) {
      this.setState({ isOpen });
      this.setLoading(false);
    } else logoutAction(this.props.client);
  };

  render() {
    let { isOpen, person, userRoles, isloading } = this.state,
      { isHidden, history } = this.props;
    // console.log(this.state);
    return (
      <div class="homepage">
        <div id="header" class="fixed-top transparent-header">
          <nav class="navbar navbar-expand-lg navbar-dark container">
            <div className="header-left">
              <NavLink
                exact
                to={ROUTES.LANDING}
                activeClassName="active"
                className="nav-link header-logo"
              >
                <img
                  height="100"
                  width="175"
                  src={"assets/img/logo-white-2.svg"}
                  alt="Leasera"
                  onClick={() => history.push("./")}
                />
              </NavLink>
              <button
                class="navbar-toggler"
                type="button"
                onClick={() => this.headerClass("add")}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div class="collapse-menu navbar-collapse" id="">
              <img
                src="assets/img/marketing/icons-close.svg"
                alt="icons-close"
                className="mobile-close-menu"
                onClick={() => this.headerClass("remove")}
              />
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <NavLink
                    exact
                    to={ROUTES.LANDING}
                    activeClassName="active nav-active"
                    className="nav-link"
                  >
                    Home
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to={ROUTES.RENTER}
                    activeClassName="active nav-active"
                    className="nav-link"
                  >
                    Renter
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to={ROUTES.MANAGER}
                    activeClassName="active nav-active"
                    className="nav-link"
                  >
                    Property Manager
                  </NavLink>
                </li>
                <li class="nav-item active">
                  <NavLink
                    exact
                    to={ROUTES.SERVICE}
                    activeClassName="active nav-active"
                    className="nav-link"
                  >
                    Service Provider <span class="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li class="nav-item dropdown">
                  {/* eslint-disable */}
                  <a
                    class="nav-link dropdown-toggle"
                    id="dropdown01"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About
                  </a>
                  {/* eslint-enable */}
                  <div class="dropdown-menu" aria-labelledby="dropdown01">
                    <NavLink
                      exact
                      to={ROUTES.ABOUT_US}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      About Leasera
                    </NavLink>
                    <NavLink
                      exact
                      to={ROUTES.PLATFORM_PRODUCTS}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      Platform Products
                    </NavLink>
                    <NavLink
                      exact
                      to={ROUTES.BLOG}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      Blog
                    </NavLink>
                    <NavLink
                      exact
                      to={ROUTES.CONTACT_US}
                      activeClassName="active"
                      className="dropdown-item"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    to={ROUTES.MarketingDemo}
                    activeClassName="active nav-active"
                    className="nav-link"
                    onClick={() =>
                      mixpanel.track("Listing Action", {
                        ndbId: "0",
                        sub: "Request Demo Button",
                      })
                    }
                  >
                    Request demo
                  </NavLink>
                </li>
                <li class="nav-item">
                  {!Boolean(getAuthToken()) ? (
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#signin"
                      class="btn btn-transparent"
                      onClick={
                        //   () => history.push({
                        //   pathname: ROUTES.SIGNIN,
                        //   client: this.props.client,
                        //   isPropertyDetail: this.props.isPropertyDetail,
                        // })
                        () => this.setState({ currentAction: "signIn" })
                      }
                    >
                      Sign In
                    </button>
                  ) : (
                    <UserMenu
                      person={person}
                      isHidden={isHidden}
                      userRoles={userRoles}
                      handleModalToggle={this.handleModalToggle}
                    />
                  )}
                  {/* Disabled for now */}
                  {!Boolean(getAuthToken()) ? (
                    <button
                      type="button"
                      class="btn btn-transparent"
                      data-toggle="modal"
                      data-target="#signin"
                      onClick={() => this.setState({ currentAction: "signUp" })}
                    >
                      Sign up
                    </button>
                  ) : null}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <SignIn
          isOpen={isOpen}
          setOpen={this.handleModalToggle}
          setLoggedStatus={this.setLoggedStatus}
          isloading={isloading}
          setLoading={this.setLoading}
          currentAction={this.state.currentAction}
        />
      </div>
    );
  }
}

export default compose(withRouter, withApollo)(HeaderMain);
