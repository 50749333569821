// @flow
import React from "react";
import { Grid, Form, Dropdown, Radio } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { Formik } from "formik";
import PropertySearchForm from "../../containers/PropertySearch/PropertySearchForm";
import { propertyFilterDefaults } from "../../store/property";
import Header from "../Header/index";

function PropertyListBar({
  client,
  searchParam,
  properties,
  setLoading,
  loading,
  getPropertiesList,
}) {

  const setLoadingFunc = () => {
    setLoading(true);
  }

  return (
    <>
      <div className="renter-listing-header">
        <Header />
      </div>
      <div className="renter-header-search">
        <div className="primary container">
          <Formik
            initialValues={{ ...propertyFilterDefaults }}
            render={({ setValues, values, setFieldValue }) => {
              return (
                <>
                  {searchParam && (
                    <PropertySearchForm
                      propertyFilter={searchParam}
                      client={client}
                      setValues={setValues}
                      values={searchParam}
                      setFieldValue={setFieldValue}
                      isListPage={true}
                      setLoading={setLoadingFunc}
                      loading={loading}
                      getPropertiesList={getPropertiesList}
                    />
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default withApollo(PropertyListBar);
