import moment from "moment";
import React from "react";
import { Card, Grid } from "semantic-ui-react";

export default function BookingCancelationInfo({ cancellation_info }) {
  if (cancellation_info.length === 0) return null;
  return (
    <Card.Group>
      <Card fluid>
        <Card.Content>
          <div className="payment-form-wrap">
            <div className="form-title">Cancellation Cost</div>
            <Grid padded="vertically">
              <Grid.Column>
                <div className="room-detail-list">
                  {cancellation_info[0].fee === 0 && (
                    <div className="cancellation-label">
                      Free cancellation until{" "}
                      {moment(cancellation_info[0].until).format("HH:mm")} on{" "}
                      {moment(cancellation_info[0].until).format("DD MMM")}
                    </div>
                  )}
                  {cancellation_info.map((info, i) => {
                    return (
                      <div className="room-list-item" key={i}>
                        <div className="list-label">
                          <img
                            src="./assets/img/payment/date-icon.svg"
                            alt="calendar"
                          />
                          <span>
                            From {moment(info.until).format("HH:mm")} on{" "}
                            {moment(info.until).format("DD MMM")} ({info.timezone})
                          </span>
                        </div>

                        <div className="list-value">$ {info.fee}</div>
                      </div>
                    );
                  })}
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}
