import React, { useEffect, useState } from "react";
import { Segment, Grid, Image, Card, List, Button } from "semantic-ui-react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import { ToastContainer } from "react-toastify";
import PropertyMap from "../Properties/PropertyMap";
import { useHistory, Redirect, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { getClient } from "../../store/auth";
import { hotelDetail, shortTermDetail } from "../../store/property";
import { BOOKING_URL, ROUTES } from "../../utils";
import BookingRoomType from "./bookingRoomType";
import RatingStars from "../../utils/ratingStars";
import Loader from "../../components/Loader";
import { useBooking } from "../../context/booking-context";
import "./propertyDetail.scss";
import { Logger } from "../../utils";
import hotelDetailData from "./hotelDetail.json";
import blockAvailabilityData from "./blockAvailability.json";

const bookingClient = getClient(BOOKING_URL);

const BookingDetail = ({ client, auth }) => {
  const bookingCtx = useBooking();
  const history = useHistory();
  /* eslint-disable */
  const [hotelInfo, setHotelInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [noOfRooms, setNoOfRooms] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);
  const [extraCharges, setExtraCharges] = useState();
  const [selectedRoomData, setSelectedRoomData] = useState([
    {
      name: "",
      noOfRoom: "",
      id: "",
    },
  ]);
  const logger = Logger("BookingDetail");
  /* eslint-enable */
  const { bookingPropertyData, selectedProperty } = bookingCtx.bookingState;
  const property = selectedProperty;
  const propertyPhoto = property.photos && JSON.parse(property.photos);
  const address = property.address;

  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    fetchBookingDetail();
  }, [property.hotelId]);

  const fetchHotelDetail = async () => {
    const logger = Logger("fetchHotelDetail");
    setLoading(true);
    try {
      const response = await bookingClient.query({
        query: hotelDetail,
        variables: {
          filter: {
            hotelId: property.hotelId,
          },
        },
      });
      // const response = hotelDetailData;

      logger.log("fetch", response.data);
      if (response.data.hotelDetail) {
        const data = JSON.parse(response.data.hotelDetail.edges[0].node.id);
        setHotelInfo(data.hotel_data);
        bookingCtx.setSelectedProperty(data.hotel_data); // booking context selected property
        logger.log("data", data);
      }
    } catch (error) {
      logger.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBookingDetail = async () => {
    const logger = Logger("fetchBookingDetail");
    setLoading(true);
    try {
      const response = await bookingClient.query({
        query: shortTermDetail,
        variables: {
          filter: {
            hotelId: property.hotelId,
            duration: property.duration,
            // rooms: property.rooms,
            adults: property.adults,
            children: property.children,
          },
        },
      });

      // const response = blockAvailabilityData;
      logger.log("fetch", response.data);

      if (response.data.blockAvailability) {
        const data = JSON.parse(
          response.data.blockAvailability.edges[0].node.id
        );
        logger.log("data", data);
        bookingCtx.updateBooking({ bookingPropertyData: data });
        fetchHotelDetail();
      }
    } catch (error) {
      logger.error("fetch", error);
    } finally {
      setLoading(false);
    }
  };

  const redirectToStep1 = () => {
    bookingCtx.updateBooking({ totalPrice: roomPrice });

    history.push({
      pathname: ROUTES.BOOKING_PAYMENT_ONE,
      state: selectedRoomData,
      bookingPropertyData: bookingPropertyData,
      hotelInfo: hotelInfo,
      totalPrice: roomPrice,
      adults: bookingCtx.bookingState.adults,
      children: bookingCtx.bookingState.children,
    });
  };

  const selectedRoomsData = (...ele) => {
    const [totalPrice, rooms, roomName, id, tag] = ele;
    // const tempData = [...selectedRoomData];
    // it used to be single room each time.
    const tempData = [];
    if (tempData.length === 1 && tempData[0].name === "") {
      tempData[0] = { noOfRooms: rooms, name: roomName, id: id };
    } else {
      const result = tempData.some((ele, index) => {
        if (ele.id === id && rooms !== 0) {
          tempData[index] = { noOfRooms: rooms, name: roomName, id: id };
          return true;
        } else if (ele.id === id && rooms === 0) {
          tempData.splice(index, 1);
          return true;
        }
        // return false;
      });
      logger.log("selectedRoomsData - isExists", result);
      if (!result) {
        tempData.push({
          noOfRooms: rooms,
          name: roomName,
          id: id,
        });
      }
    }
    setSelectedRoomData(tempData);
    logger.log("selectedRoomsData - tempData", tempData);
    if (tag === "price") {
      setRoomPrice(totalPrice);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="renter-listing-header">
        <Header />
      </div>
      <Segment className="property-detail-layout booking-detail-layout">
        <div className="property-detail-wrapper">
          <Grid>
            <Grid.Row stretched className="property-images-row">
              <Grid.Column width={16}>
                {/* <Image className="property-img" src={propertyPhoto&&propertyPhoto[0] ? propertyPhoto[0] : 'assets/img/no-image-available.jpg'} /> */}

                <div id="demo" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-inner">
                      {hotelInfo &&
                        hotelInfo.hotel_photos &&
                        hotelInfo.hotel_photos.map((elem, index) => (
                          <div
                            class={`carousel-item ${index === 0 && "active"}`}
                          >
                            <Image src={elem.url_original} alt="image" />
                          </div>
                        ))}
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#demo"
                    data-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#demo"
                    data-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column width={13}>
                <div className="property-information">
                  <div className="property-title">{property.name}</div>
                  <div className="property-address">{address}</div>
                </div>
              </Grid.Column>
              <Grid.Column width={3}>
                <div>
                  <RatingStars rating={property.stars} />
                  <a
                    className="btn btn-gradient book-now-btn"
                    href={property.hotelUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Book Now
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <div className="property-description">
                  <span className="description-title">Description</span>
                  <pre style={{ fontFamily: "Be Vietnam" }}>
                    {hotelInfo?.hotel_description}
                  </pre>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column width={12}>
                {/* <Image src={propertyPhoto ? propertyPhoto[0] : 'assets/img/no-image-available.jpg'} /> */}
                <div>
                  <BookingRoomType
                    data={bookingPropertyData}
                    setNoOfRooms={(ele) => setNoOfRooms(ele)}
                    setRoomPrice={(...ele) =>
                      selectedRoomsData(...ele, "price")
                    }
                    // setRoomPrice={(ele) => setRoomPrice(ele)}
                    setExtraCharges={(ele) => setExtraCharges(ele)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="room-total-wrap">
                  {noOfRooms !== 0 && (
                    <>
                      <div className="total-room-count">
                        {noOfRooms} rooms for
                      </div>
                      <div className="total-room-price">
                        $ {roomPrice.toFixed(2)}
                      </div>
                      <div className="total-room-tax">
                        +$ {extraCharges} taxes
                      </div>
                    </>
                  )}
                  {noOfRooms > 0 && (
                    <Button
                      className="btn btn-gradient book-now-btn"
                      onClick={redirectToStep1}
                    >
                      I'll reserve
                    </Button>
                  )}
                  <ul className="payment-terms-list">
                    <li>Confirmation is immediate</li>
                    <li>No registration required</li>
                    <li>No booking or card fees!</li>
                  </ul>
                </div>
                <div className="property-details-map booking-detail-map">
                  <div className="property-location-label">location</div>
                  <PropertyMap
                    searchStatus={true}
                    properties={[property]}
                    zoom={9}
                    isDetail={true}
                  />
                </div>
                <div>
                  {hotelInfo &&
                    `${hotelInfo.review_score} ${hotelInfo.number_of_reviews} reviews`}
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={8}>
                <h2>House rules</h2>
                <div className="property-rules-wrap">
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/checkin-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">Check-In</div>
                        <div className="rule-subtitle">
                          Guests are required to show a photo identification.
                        </div>
                      </div>
                    </div>
                    <div className="rule-timing">
                      From {hotelInfo?.checkin_checkout_times.checkin_from}{" "}
                      hours
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/checkin-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">Check-out</div>
                        <div className="rule-subtitle">
                          Guests are required to show a photo identification.
                        </div>
                      </div>
                    </div>
                    <div className="rule-timing">
                      Until {hotelInfo?.checkin_checkout_times.checkout_to}{" "}
                      hours
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/info-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">Cancellation</div>
                        <div className="rule-subtitle">
                          Cancellation and prepayment policies vary according to
                          accommodation type. Please check what conditions may
                          apply to each option when making your selection.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/info-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">Refund</div>
                        <div className="rule-subtitle">
                          A damage deposit of USD 300 is required on arrival.
                          That's about 22848.74INR. This will be collected by
                          credit card. You should be reimbursed within 14 days
                          of check-out. Your deposit will be refunded in full
                          via credit card, subject to an inspection of the
                          property.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/person-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">Age restriction</div>
                        <div className="rule-subtitle">{`The minimum age for check-in is ${hotelInfo?.adult_age_restrictions?.min_age}`}</div>
                      </div>
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/person-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">
                          {hotelInfo?.hotel_policies[3].name}
                        </div>
                        <div className="rule-subtitle">
                          {hotelInfo?.hotel_policies[3].content}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="property-rules-list">
                    <div className="d-flex align-items-center">
                      <div className="rule-icon">
                        <Image src="./assets/img/pets-icon.svg" />
                      </div>
                      <div>
                        <div className="rule-title">
                          {hotelInfo?.hotel_policies[2]?.name}
                        </div>
                        <div className="rule-subtitle">
                          {hotelInfo?.hotel_policies[2]?.content}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
                                    <span>Check-in</span>
                                    <p>Guests are required to show a photo identification</p>
                                    <p>{`From ${hotelInfo?.checkin_checkout_times.checkin_from} hours`}</p>
                                </div>
                                <div>
                                    <span>Check-out</span>
                                    <p>Guests are required to show a photo identification</p>
                                    <p>{`From ${hotelInfo?.checkin_checkout_times.checkout_to} hours`}</p>
                                </div>
                                <div>
                                    <span>Age restriction</span>
                                    <p>{`The minimum age for check-in is ${hotelInfo?.adult_age_restrictions.min_age}`}</p>
                                </div>
                                <div>
                                    <span>{hotelInfo?.hotel_policies[3].name}</span>
                                    <p>{hotelInfo?.hotel_policies[3].content}</p>
                                </div> */}
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div className="property-print-wrap">
                  <h2>Fine print</h2>
                  <div className="property-print-card">
                    {bookingPropertyData &&
                      bookingPropertyData.important_information}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <FooterMain />
      </Segment>
      <FooterMain />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default compose(withRouter, withApollo)(BookingDetail);
