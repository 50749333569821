import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Container, Card, Image, Grid } from "semantic-ui-react";
import "./Blog.scss";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Slider from "react-slick";
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  componentDidMount() {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40leaserapm"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ medium: result.items });
        },

        (error) => {}
      );
  }

  render() {
    const settings = {
      // dots: true,
      arrows: true,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 10000,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <>
        <Header />

        <div class="banner banner-service-provider about-banner">
          <div class="container text-center">
            <div class="row">
              <div class="col-12">
                <h1>Blog</h1>
                <h4>See what we are up to.</h4>
              </div>
            </div>
          </div>
        </div>
        <Container className="blog-containter" fluid>
          <Header as="h1">Check out our recent posts</Header>
          <Slider {...settings}>
            {this.state.medium &&
              this.state.medium.map((item) => {
                return (
                  <Card className="blog-card" href={item.link} target="_blank">
                    <Image src={item.thumbnail} wrapped />
                    <Card.Content>
                      <Card.Header as="h1">{item.title}</Card.Header>
                      <Card.Meta>
                        <span className="date">
                          Barret Newberry, CEO Leasera
                        </span>
                      </Card.Meta>
                      <div className="description-blog">
                        <span
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </div>
                    </Card.Content>
                    <Card.Content extra>
                      <Image
                        src="assets/img/leasera-logonew-nav.png"
                        size="mini"
                      />
                      &nbsp;
                      <span>LEASERA</span>&nbsp;
                      {/* <span className="clap-icon"></span> &nbsp;
                                                        Joined in 2015 */}
                    </Card.Content>
                  </Card>
                );
              })}
          </Slider>
        </Container>
        <FooterMain />
      </>
    );
  }
}

export default withApollo(Blog);
