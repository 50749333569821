import React, { Component } from "react";
import { withApollo } from "react-apollo";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import { NavLink } from "react-router-dom";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div class="platform-products-wrapper privacy-wrapper">
          <Header />
          <div class="banner banner-service-provider">
            <div class="container text-center">
              <div class="row">
                <div class="col-12">
                  <h1>Terms & Conditions</h1>
                </div>
              </div>
            </div>
          </div>
          <section class="privacy-wrapper terms-wrapper">
            <div class="container">
              <div class="content-data">
                {/* <span>Last Modified: July 17, 2017</span> */}
                <p>
                  These Terms of Use are entered into by and between You (“
                  <b>You</b>”) and Real Estate Ally LLC, a Washington limited
                  liability company ("<b>Company</b>", "<b>we</b>" or "<b>us</b>
                  ") dba Leasera. The following terms and conditions ("
                  <b>Terms of Use</b>"), govern Your access to and use of
                  www.re-ally.com (the "<b>Website</b>").
                </p>
                <p>
                  By using the Website, You: (a) acknowledge that You have read
                  and understand these Terms of Use; (b) represent that You are
                  of legal age to enter into a binding agreement; and (c) accept
                  these Terms of Use and agree that You are legally bound by
                  them. If You do not, or cannot, agree to these Terms of Use,
                  do not use the Website.
                </p>
                <h3>
                  PLEASE READ THIS AGREEMENT CAREFULLY AS IT AFFECTS YOUR RIGHTS
                  AND INCLUDES A WAIVER OF A TRIAL BY JURY, TIME LIMITATIONS
                  WHEN BRINGING A CLAIM, AND LIMITATIONS ON YOUR ABILITY TO
                  BRING A CLAIM ON BEHALF OF OTHERS.{" "}
                </h3>
              </div>
              <ul>
                <li>
                  <h4>1. Changes to the Terms of Use</h4>
                  <p>
                    {" "}
                    We may revise and update these Terms of Use from time to
                    time in our sole discretion. All changes are effective
                    immediately when we post them. Your continued use of the
                    Website following the posting of revised Terms of Use means
                    that You accept and agree to the changes. You are expected
                    to check this page from time to time so You are aware of any
                    changes, as they are binding on You.{" "}
                  </p>
                </li>
                <li>
                  <h4>2. Registration through Social Media</h4>
                  <p>
                    You may login to the Website through an account with
                    Facebook or another social media platform allowed by the
                    Website (collectively, a “<b>Social Media Login</b>”).  If
                    You login to the Website using Your Social Media Login, You
                    authorize us to access and use certain Social Media Login
                    account information, including Your public profile and
                    related information. For more information regarding the
                    information we collect from You and how we use it, please
                    consult our{" "}
                    <NavLink exact to="/Privacy" activeClassName="active">
                      Privacy Policy
                    </NavLink>
                    . You are responsible for safeguarding Your login
                    credentials, including Your password, and for any activities
                    or actions taken under Your credentials. We will not be
                    liable for any actions taken on or through Your account with
                    Your credentials.{" "}
                  </p>
                </li>

                <li>
                  <h4>3. Accessing the Website </h4>
                  <p>
                    We will not be liable if, for any reason, all or any part of
                    the Website is unavailable at any time or for any period.
                    From time to time, we may restrict access to some parts of
                    the Website, or the entire Website, to users. You are
                    responsible for (a) making all arrangements necessary for
                    You to have access to the Website; and (b) ensuring that all
                    persons who access the Website through Your internet
                    connection are aware of these Terms of Use and comply with
                    them
                  </p>
                </li>
                <li>
                  <h4>4. Intellectual Property Rights </h4>
                  <p>
                    The Website and its entire contents, features and
                    functionality (including but not limited to all information,
                    software, text, displays, images, video and audio, and the
                    design, selection and arrangement thereof), are owned by
                    Company, its licensors or other providers of such material
                    and are protected by United States and international
                    copyright, trademark, patent, trade secret and other
                    intellectual property or proprietary rights laws. These
                    Terms of Use permit You to use the Website for Your
                    personal, non-commercial use only. You must not reproduce,
                    distribute, modify, create derivative works of, publicly
                    display, publicly perform, republish, download, store or
                    transmit any of the material on our Website without our
                    written consent. However, If we provide social media
                    features, such as the ability to share a social media post
                    from the Website, You may take such actions as are enabled
                    by such features. No right, title or interest in or to the
                    Website or any content on the Website is transferred to You,
                    and all rights not expressly granted herein are reserved by
                    Company. Any use of the Website not expressly permitted by
                    these Terms of Use is a breach of these Terms of Use and may
                    violate copyright, trademark and other laws.{" "}
                  </p>
                </li>
                <li>
                  <h4>5. Trademarks </h4>
                  <p>
                    Our company name, the Company logo, and all related names,
                    logos, product and service names, designs and slogans used
                    on the Website are trademarks of Company or its affiliates
                    or licensors. Other product and company names and logos
                    appearing on the Website may be registered or unregistered
                    trademarks, service marks or trade names of their respective
                    owners. Any use of the trademarks, service marks or trade
                    names displayed on the Website is strictly prohibited, and
                    nothing appearing on the Website will be construed as
                    granting, by implication or otherwise, any license or right
                    to use any of those trademarks, service marks or trade
                    names.{" "}
                  </p>
                </li>
                <li>
                  <h4>6. Prohibited Uses</h4>
                  <p>
                    Without limiting the foregoing, You may not use the Website
                    to: (a) take actions that are defamatory, obscene, indecent,
                    abusive, offensive, harassing, violent, hateful,
                    inflammatory or otherwise objectionable; (b) promote
                    sexually explicit or pornographic material, violence, or
                    discrimination based on race, sex, religion, nationality,
                    disability, sexual orientation or age; (c) infringe any
                    patent, trademark, trade secret, copyright or other
                    intellectual property or other rights of any other person;
                    (d) violate the legal rights (including the rights of
                    publicity and privacy) of others or behave in any way that
                    could give rise to any civil or criminal liability under
                    applicable laws or regulations or that otherwise may be in
                    conflict with these Terms of Use and our{" "}
                    <NavLink exact to="/Privacy" activeClassName="active">
                      Privacy Policy
                    </NavLink>{" "}
                    (e) take actions that would be likely to deceive any person,
                    including, without limitation, to impersonate or attempt to
                    impersonate Company, a Company employee, another user or any
                    other person or entity; (f) promote any illegal activity, or
                    advocate, promote or assist in any unlawful act; (g) cause
                    annoyance, inconvenience or needless anxiety to, or upset,
                    embarrass, alarm, or annoy any other person; (h) engage in
                    commercial activities or sales with others, such as
                    contests, sweepstakes and other sales promotions, bartering
                    or advertising, or transmit, or procure the sending of, any
                    advertising or promotional material, including any "junk
                    mail," "chain letter" or "spam" or any other similar
                    solicitation; (i) give the impression that Your behaviors or
                    comments emanate from or are endorsed by us or any other
                    person or entity;
                  </p>
                  <p>
                    Further, You may not: (j) use the Website in any manner that
                    could disable, overburden, damage, or impair the Website or
                    interfere with any other party's use of the Website; (k) use
                    any robot, spider or other automatic device, process or
                    means to access the Website for any reason whatsoever; (l)
                    use any manual process to monitor or copy any of the
                    material on the Website for any reason without our prior
                    written consent; (m) use any device, software or routine
                    that interferes with the proper working of the Website; (n)
                    introduce any viruses, Trojan horses, worms, logic bombs or
                    other material which is malicious or technologically
                    harmful; (o) attempt to gain unauthorized access to,
                    interfere with, damage or disrupt any parts of the Website,
                    the server on which the Website is stored, or any server,
                    computer or database connected to the Website; or (p)
                    otherwise attempt to interfere with the proper working of
                    the Website.{" "}
                  </p>
                </li>
                <li>
                  <h4>7. Third Party Materials</h4>
                  <p>
                    The Website may display, include, or make available
                    third-party content or provide links to third-party websites
                    or services ("<b>Third-Party Materials</b>"). Company is not
                    responsible for Third-Party Materials, including their
                    accuracy, completeness, timeliness, validity, legality,
                    decency, quality or any other aspect thereof. Company does
                    not assume and will not have any liability or responsibility
                    to You or any other person or entity for any Third-Party
                    Materials. Third-Party Materials and any links thereto are
                    provided solely as a convenience to You and Your access,
                    reliance on, and use of them is entirely at Your own risk
                    and is subject to such third-parties' terms and conditions
                    and privacy policies. We do not warrant the accuracy,
                    completeness or usefulness of any information contained in
                    any Third-Party Materials. We disclaim all liability and
                    responsibility arising from any reliance placed on
                    Third-Party Materials.{" "}
                  </p>
                </li>
                <li>
                  <h4>8.Response to Violations </h4>
                  <p>
                    We reserve the right to (a) take appropriate legal action,
                    including without limitation, referral to law enforcement,
                    for any illegal or unauthorized use of the Website; and/or
                    (b) terminate or suspend Your access to all or part of the
                    Website for any or no reason, including without limitation,
                    any violation of these Terms of Use.{" "}
                  </p>
                </li>
                <li>
                  <h4>9. Reliance on Information Posted</h4>
                  <p>
                    The information presented on or through the Website is made
                    available solely for general information purposes. We do not
                    warrant the accuracy, completeness or usefulness of this
                    information. Any reliance You place on such information is
                    strictly at Your own risk. We disclaim all liability and
                    responsibility arising from any reliance placed on such
                    materials by You or any other visitor to the Website, or by
                    anyone who may be informed of any of its contents. This
                    Website may include content provided by third parties,
                    including materials provided by other users, bloggers and
                    third party licensors, syndicators, aggregators and/or
                    reporting services. All statements and/or opinions expressed
                    in these materials, and all articles and responses to
                    questions and other content, other than the content provided
                    by Company, are solely the opinions and the responsibility
                    of the person or entity providing those materials. These
                    materials do not necessarily reflect the opinion of Company.
                    We are not responsible, or liable to You or any third party,
                    for the content or accuracy of any materials provided by any
                    third parties.{" "}
                  </p>
                </li>
                <li>
                  <h4>10. Changes to the Website</h4>
                  <p>
                    {" "}
                    We reserve the right to withdraw, update, or amend this
                    Website, and any material provided on the Website, in our
                    sole discretion without notice to You. The content of this
                    Website is not necessarily complete, accurate, or
                    up-to-date, and we are under no obligation to update such
                    material.{" "}
                  </p>
                </li>
                <li>
                  <h4>
                    11. Information About You and Your Visits to the Website{" "}
                  </h4>
                  <p>
                    All information we collect on this Website is subject to our{" "}
                    <NavLink exact to="/Privacy" activeClassName="active">
                      Privacy Policy
                    </NavLink>
                    . By using the Website, You consent to all actions taken by
                    us with respect to Your information in compliance with the
                    Privacy Policy.{" "}
                  </p>
                </li>
                <li>
                  <h4>12. Geographic Restrictions</h4>
                  <p>
                    We provide this Website for use only by persons located in
                    the United States. We make no claims that the Website or any
                    of its content is accessible or appropriate outside of the
                    United States. Access to the Website may not be legal by
                    certain persons or in certain countries. If You access the
                    Website from outside the United States, You do so on Your
                    own initiative and are responsible for compliance with local
                    laws.
                  </p>
                </li>
                <li>
                  <h4>13. Disclaimer of Warranties</h4>
                  <p>
                    You understand that we cannot and do not guarantee or
                    warrant that the Website or any of its contents will be free
                    of viruses or other destructive code. You are responsible
                    for implementing sufficient procedures and checkpoints to
                    satisfy Your particular requirements for anti-virus
                    protection and accuracy of data input and output, and for
                    maintaining a means external to our site for any
                    reconstruction of any lost data. WE WILL NOT BE LIABLE FOR
                    ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
                    ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
                    THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                    DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                    WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                    WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
                    OR ON ANY WEBSITE LINKED TO IT.
                  </p>
                  <p>
                    YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR
                    ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE
                    WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS
                    AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED. NEITHER COMPANY NOR ANY PERSON
                    ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION
                    WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                    QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
                    LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
                    ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
                    WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE
                    OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
                    SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.{" "}
                  </p>
                  <p>
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT
                    NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                    NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE
                    FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.{" "}
                  </p>
                </li>
                <li>
                  <h4>14. Limitation on Liability</h4>
                  <p>
                    IN NO EVENT WILL COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
                    SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS
                    BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                    ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY
                    TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
                    ON THE WEBSITE OR SUCH OTHER WEBSITES, OR ANY SERVICES OR
                    ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
                    INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                    CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                    TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                    LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                    ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                    DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                    BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE
                    FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.{" "}
                  </p>
                </li>
                <li>
                  <h4>15. Indemnification </h4>
                  <p>
                    You agree to defend, indemnify and hold harmless Company,
                    its affiliates, licensors and service providers, and its and
                    their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors and
                    assigns from and against any claims, liabilities, damages,
                    judgments, awards, losses, costs, expenses or fees
                    (including reasonable attorneys' fees) arising out of or
                    relating to Your violation of these Terms of Use or Your use
                    of the Website.{" "}
                  </p>
                </li>
                <li>
                  <h4>16. General Terms of Use </h4>
                  <ul class="subchild-element">
                    <li>
                      <span>Entire Agreement</span>
                      <p>
                        Except for any terms of service that you may agree to in
                        connection with the Company’s provision to you of
                        certain services, these Terms of Use and the Privacy
                        Policy constitute the entire agreement between You and
                        Company with respect to Your use of the Website, and
                        supersede all prior or contemporaneous understandings
                        and agreements, whether written or oral, with respect to
                        the subject matter contained herein. Your obligations to
                        indemnify Company, along with any other part of these
                        Terms of Use of Use which, by their nature should
                        survive termination of these Terms of Use or Your right
                        to use the Website, shall survive.{" "}
                      </p>
                    </li>
                    <li>
                      <span>No Assignment</span>
                      <p>
                        These Terms of Use and any rights or licenses granted to
                        You herein are personal to You, and may not be assigned
                        prior to obtaining Company’ written consent.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Arbitration </span>
                      <p>
                        PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR
                        RIGHTS AND INCLUDES WAIVER OF A TRIAL BY JURY IN A COURT
                        AND OF THE ABILITY TO BRING A CLAIM ON BEHALF OF OTHERS.
                        Any disputes or claims arising from the use of the
                        Website, including disputes arising from or concerning
                        the interpretation, violation, invalidity,
                        non-performance, or termination of these Terms of Use,
                        will be resolved by binding arbitration under the
                        Federal Arbitration Act, federal arbitration law and
                        rules, and Washington state law. To the fullest extent
                        permitted by law: (a) no arbitration under these Terms
                        of Use shall be joined to any other arbitration; (b) no
                        class arbitration proceedings will be permitted; (c) no
                        finding or stipulation of fact in any other arbitration,
                        judicial or similar proceeding may be given preclusive
                        or collateral estoppel effect in any arbitration
                        hereunder (unless determined in another proceeding
                        between You and Company); and (d) no conclusion of law
                        in any other arbitration may be given any weight in any
                        arbitration hereunder (unless determined in another
                        proceeding between You and Company). Both parties will
                        be responsible for their respective arbitration fees.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Governing Law and Jurisdiction </span>
                      <p>
                        These Terms of Use are governed by and construed in
                        accordance with the Federal Arbitration Act, federal
                        arbitration law, and the laws of the state of Washington
                        without giving effect to any choice or conflict of law
                        provision or rule. Notwithstanding the foregoing,
                        Company retains the right to bring any suit, action or
                        proceeding against You for breach of these Terms of Use
                        in Your country of residence or any other relevant
                        country.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Injunctive Relief</span>
                      <p>
                        You agree that Company may bring suit in any court of
                        competent jurisdiction in order to enjoin infringement
                        or other misuse of Company’s or its affiliates or
                        licensor’s intellectual property rights.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Limitation on Time to File Claims</span>
                      <p>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF
                        OR RELATING TO THESE TERMS OR THE WEBSITE MUST BE
                        COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                        ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                        PERMANENTLY BARRED (if applicable law prohibits a one
                        (1) year limitation period for asserting claims, the
                        claim must be asserted within the shortest time period
                        in excess of one (1) year that is permitted by
                        applicable law).{" "}
                      </p>
                    </li>
                    <li>
                      <span>Waiver and Severability</span>
                      <p>
                        No waiver by Company of any term or condition set forth
                        in these Terms of Use shall be deemed a further or
                        continuing waiver of such term or condition or a waiver
                        of any other term or condition, and any failure by
                        Company to assert a right or provision under these Terms
                        of Use shall not constitute a waiver of such right or
                        provision. If any provision of these Terms of Use is
                        held by a court or other tribunal of competent
                        jurisdiction to be invalid, illegal or unenforceable for
                        any reason, such provision shall be eliminated or
                        limited to the minimum extent such that the remaining
                        provisions of the Terms of Use of Use will continue in
                        full force and effect.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Notices, Questions or Concerns</span>
                      <p>
                        All notices, comments, requests for technical support,
                        and other communications relating to the Website should
                        be directed to info@re-ally.com.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>
          <Newsletter />
          <FooterMain />
        </div>
        {/* <div className="top-terms-background">
                    <h2 className="top-terms-header">Terms and Conditions</h2>
                </div>

                <div className="terms-data-container">
                    <p>These Terms of Use are entered into by and between You (“You”) and Real Estate Ally LLC, a Washington limited liability company ("Company", "we" or "us").  The following terms and conditions ("Terms of Use"), govern Your access to and use of www.re-ally.com (the "Website").</p>
                    <p>By using the Website, You: (a) acknowledge that You have read and understand these Terms of Use; (b) represent that You are of legal age to enter into a binding agreement; and (c) accept these Terms of Use and agree that You are legally bound by them.  If You do not, or cannot, agree to these Terms of Use, do not use the Website.</p>
                    <p style={{textDecoration:'underline',fontWeight:'500'}}>PLEASE READ THIS AGREEMENT CAREFULLY AS IT AFFECTS YOUR RIGHTS AND INCLUDES A WAIVER OF A TRIAL BY JURY, TIME LIMITATIONS WHEN BRINGING A CLAIM, AND LIMITATIONS ON YOUR ABILITY TO BRING A CLAIM ON BEHALF OF OTHERS.</p>
                    <ul>
                        <li>
                            <p><h3>Changes to the Terms of Use.</h3>  We may revise and update these Terms of Use from time to time in our sole discretion.  All changes are effective immediately when we post them.  Your continued use of the Website following the posting of revised Terms of Use means that You accept and agree to the changes.  You are expected to check this page from time to time so You are aware of any changes, as they are binding on You.</p>
                        </li>
                        <li>
                            <p><h3>Registration through Social Media.</h3>You may login to the Website through an account with Facebook or another social media platform allowed by the Website (collectively, a “Social Media Login”).  If You login to the Website using Your Social Media Login, You authorize us to access and use certain Social Media Login account information, including Your public profile and related information.  For more information regarding the information we collect from You and how we use it, please consult our Privacy Policy.  You are responsible for safeguarding Your login credentials, including Your password, and for any activities or actions taken under Your credentials. We will not be liable for any actions taken on or through Your account with Your credentials.</p>
                        </li>
                        <li>
                            <p><h3>Accessing the Website.</h3>Y We will not be liable if, for any reason, all or any part of the Website is unavailable at any time or for any period.  From time to time, we may restrict access to some parts of the Website, or the entire Website, to users.  You are responsible for (a) making all arrangements necessary for You to have access to the Website; and (b) ensuring that all persons who access the Website through Your internet connection are aware of these Terms of Use and comply with them.</p>
                        </li>
                        <li>
                            <p><h3>Intellectual Property Rights.</h3> The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.  These Terms of Use permit You to use the Website for Your personal, non-commercial use only.  You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website without our written consent.  However, If we provide social media features, such as the ability to share a social media post from the Website, You may take such actions as are enabled by such features.  No right, title or interest in or to the Website or any content on the Website is transferred to You, and all rights not expressly granted herein are reserved by Company.  Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
                        </li>
                        <li>
                            <p><h3>Trademarks.</h3> The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.  These Terms of Use permit You to use the Website for Your personal, non-commercial use only.  You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website without our written consent.  However, If we provide social media features, such as the ability to share a social media post from the Website, You may take such actions as are enabled by such features.  No right, title or interest in or to the Website or any content on the Website is transferred to You, and all rights not expressly granted herein are reserved by Company.  Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
                        </li>
                        <li>
                            <p><h3>Prohibited Uses.</h3> Without limiting the foregoing, You may not use the Website to: (a) take actions that are defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable; (b) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; (c) infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person; (d) violate the legal rights (including the rights of publicity and privacy) of others or behave in any way that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy; (e) take actions that would be likely to deceive any person, including, without limitation, to impersonate or attempt to impersonate Company, a Company employee, another user or any other person or entity; (f) promote any illegal activity, or advocate, promote or assist in any unlawful act; (g) cause annoyance, inconvenience or needless anxiety to, or upset, embarrass, alarm, or annoy any other person; (h) engage in commercial activities or sales with others, such as contests, sweepstakes and other sales promotions, bartering or advertising, or transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter" or "spam" or any other similar solicitation; (i) give the impression that Your behaviors or comments emanate from or are endorsed by us or any other person or entity;</p>
                        </li>
                        <li>
                            <p><h3>Prohibited Uses.</h3> Without limiting the foregoing, You may not use the Website to: (a) take actions that are defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable; (b) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; (c) infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person; (d) violate the legal rights (including the rights of publicity and privacy) of others or behave in any way that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy; (e) take actions that would be likely to deceive any person, including, without limitation, to impersonate or attempt to impersonate Company, a Company employee, another user or any other person or entity; (f) promote any illegal activity, or advocate, promote or assist in any unlawful act; (g) cause annoyance, inconvenience or needless anxiety to, or upset, embarrass, alarm, or annoy any other person; (h) engage in commercial activities or sales with others, such as contests, sweepstakes and other sales promotions, bartering or advertising, or transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter" or "spam" or any other similar solicitation; (i) give the impression that Your behaviors or comments emanate from or are endorsed by us or any other person or entity;</p>
                            <p>Further, You may not: (j) use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party's use of the Website; (k) use any robot, spider or other automatic device, process or means to access the Website for any reason whatsoever; (l) use any manual process to monitor or copy any of the material on the Website for any reason without our prior written consent; (m) use any device, software or routine that interferes with the proper working of the Website; (n) introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or technologically harmful; (o) attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website; or (p) otherwise attempt to interfere with the proper working of the Website.</p>
                        </li>
                        <li>
                            <p><h3>Third Party Materials.</h3> The Website may display, include, or make available third-party content or provide links to third-party websites or services ("Third-Party Materials").  Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, legality, decency, quality or any other aspect thereof.  Company does not assume and will not have any liability or responsibility to You or any other person or entity for any Third-Party Materials.  Third-Party Materials and any links thereto are provided solely as a convenience to You and Your access, reliance on, and use of them is entirely at Your own risk and is subject to such third-parties' terms and conditions and privacy policies.  We do not warrant the accuracy, completeness or usefulness of any information contained in any Third-Party Materials.  We disclaim all liability and responsibility arising from any reliance placed on Third-Party Materials.</p>
                        </li>
                        <li>
                            <p><h3>Response to Violations.</h3> We reserve the right to (a) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website; and/or (b) terminate or suspend Your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</p>
                        </li>
                        <li>
                            <p><h3>Response to Violations.</h3> We reserve the right to (a) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website; and/or (b) terminate or suspend Your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</p><p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order related to our Website, including, without limitation, users of our Website.  YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
                        </li>
                        <li>
                            <p><h3>Reliance on Information Posted.</h3>The information presented on or through the Website is made available solely for general information purposes.  We do not warrant the accuracy, completeness or usefulness of this information.  Any reliance You place on such information is strictly at Your own risk.  We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any other visitor to the Website, or by anyone who may be informed of any of its contents.  This Website may include content provided by third parties, including materials provided by other users, bloggers and third party licensors, syndicators, aggregators and/or reporting services.  All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Company, are solely the opinions and the responsibility of the person or entity providing those materials.  These materials do not necessarily reflect the opinion of Company.  We are not responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any third parties.</p>
                        </li>
                        <li>
                            <p><h3>Changes to the Website.</h3>We reserve the right to withdraw, update, or amend this Website, and any material provided on the Website, in our sole discretion without notice to You.  The content of this Website is not necessarily complete, accurate, or up-to-date, and we are under no obligation to update such material.</p>
                        </li>
                        <li>
                            <p><h3>Information About You and Your Visits to the Website.</h3>All information we collect on this Website is subject to our Privacy Policy.  By using the Website, You consent to all actions taken by us with respect to Your information in compliance with the Privacy Policy.</p>
                        </li>
                        <li>
                            <p><h3>Geographic Restrictions.</h3>We provide this Website for use only by persons located in the United States.  We make no claims that the Website or any of its content is accessible or appropriate outside of the United States.  Access to the Website may not be legal by certain persons or in certain countries.  If You access the Website from outside the United States, You do so on Your own initiative and are responsible for compliance with local laws.</p>
                        </li>
                        <li>
                            <p><h3>Disclaimer of Warranties.</h3>You understand that we cannot and do not guarantee or warrant that the Website or any of its contents will be free of viruses or other destructive code.  You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.  WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
                            <p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK.  THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.  NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE.  WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                            <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                        </li>
                        <li>
                            <p><h3>Limitation on Liability.</h3>IN NO EVENT WILL COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.  THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                        </li>
                        <li>
                            <p><h3>Indemnification.</h3>You agree to defend, indemnify and hold harmless Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to Your violation of these Terms of Use or Your use of the Website.</p>
                        </li>
                        <li>
                            <h3>General Terms of Use.</h3>
                            <ul>
                                <li><p><h3>Entire Agreement.</h3>Except for any terms of service that you may agree to in connection with the Company’s provision to you of certain services, these Terms of Use and the Privacy Policy constitute the entire agreement between You and Company with respect to Your use of the Website, and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the subject matter contained herein.  Your obligations to indemnify Company, along with any other part of these Terms of Use of Use which, by their nature should survive termination of these Terms of Use or Your right to use the Website, shall survive.</p></li>
                                <li><p><h3>No Assignment.</h3>These Terms of Use and any rights or licenses granted to You herein are personal to You, and may not be assigned prior to obtaining Company’ written consent.</p></li>
                                <li><p><h3>Arbitration.</h3>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND INCLUDES WAIVER OF A TRIAL BY JURY IN A COURT AND OF THE ABILITY TO BRING A CLAIM ON BEHALF OF OTHERS.  Any disputes or claims arising from the use of the Website, including disputes arising from or concerning the interpretation, violation, invalidity, non-performance, or termination of these Terms of Use, will be resolved by binding arbitration under the Federal Arbitration Act, federal arbitration law and rules, and Washington state law.  To the fullest extent permitted by law: (a) no arbitration under these Terms of Use shall be joined to any other arbitration; (b) no class arbitration proceedings will be permitted; (c) no finding or stipulation of fact in any other arbitration, judicial or similar proceeding may be given preclusive or collateral estoppel effect in any arbitration hereunder (unless determined in another proceeding between You and Company); and (d) no conclusion of law in any other arbitration may be given any weight in any arbitration hereunder (unless determined in another proceeding between You and Company).  Both parties will be responsible for their respective arbitration fees.</p></li>
                                <li><p><h3>Governing Law and Jurisdiction.</h3>These Terms of Use are governed by and construed in accordance with the Federal Arbitration Act, federal arbitration law, and the laws of the state of Washington without giving effect to any choice or conflict of law provision or rule.  Notwithstanding the foregoing, Company retains the right to bring any suit, action or proceeding against You for breach of these Terms of Use in Your country of residence or any other relevant country.</p></li>
                                <li><p><h3>Injunctive Relief.</h3>You agree that Company may bring suit in any court of competent jurisdiction in order to enjoin infringement or other misuse of Company’s or its affiliates or licensor’s intellectual property rights.</p></li>
                                <li><p><h3>Limitation on Time to File Claims.</h3>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED (if applicable law prohibits a one (1) year limitation period for asserting claims, the claim must be asserted within the shortest time period in excess of one (1) year that is permitted by applicable law).</p></li>
                                <li><p><h3>Waiver and Severability.</h3>No waiver by Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.  If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use of Use will continue in full force and effect.</p></li>
                                <li><p><h3>Notices, Questions or Concerns.</h3>All notices, comments, requests for technical support, and other communications relating to the Website should be directed to info@re-ally.com.</p></li>
                            </ul>
                        </li>
                        <li id="loyaltyPoints">
                            <p><h3>Leasera Points™ membership terms and conditions</h3> No rights of any kind accrue in the Leasera Points program except as specifically defined in these terms and conditions. There are no exceptions to these conditions of membership except for those set out in this document. Leasera reserves the right to interpret and apply these conditions of membership in its sole discretion. Leasera' Leasera Points program may be modified by Leasera at any time, or terminated with 180 days' notice.</p>
                            <ul>
                                <li>
                                    <p><h3>Membership</h3>Individuals may join the Leasera Points program&nbsp;online, by calling&nbsp;customer care, or other channels as may be designated by Leasera. Corporations and other entities are not eligible to become Leasera Points members. There are no group or family memberships; each individual must enroll separately.</p>
                                    <p>Enrollment in the Leasera Points program requires each individual to provide his/her name, address, phone contact, and birth date. The enrollment process must be completed by the prospective member himself/herself, unless the prospective member is a minor, in which case the parent or legal guardian may complete the enrollment process. Agents, arrangers, and unauthorized brokers may not create or manage Leasera Points accounts for others. Members will be asked to verify all of this information before speaking with Leasera employees about their accounts. If Leasera becomes aware that a member or a third party has misrepresented his/her identity in order to enroll in Leasera Points or perform a Leasera Points transaction, Leasera may, in its sole discretion, void the transaction.</p>
                                </li>
                                <li>
                                    <p><h3>Earning Leasera Points</h3>Leasera Points can be accrued by:</p>
                                    <ul>
                                        <li>Renting a participating Leasera property</li>
                                        <li>Using the services of a Leasera Points partner (including our programs to buy, share, or gift points)</li>
                                        <li>Promotional bonus points awarded by Leasera or one of its Leasera Points partners</li>
                                    </ul>
                                    <p>Leasera reserves the right to amend or delete any or all of the means currently recognized for accruing points and to exclude specific types of transactions or tickets from the accumulation of points.</p>
                                    <p>Points will accrue only in the Leasera Points account of the member who actually rents or purchases goods or services and whose name appears on the lease, hotel receipt, car rental agreement or goods or services invoice related to such lease or purchase.</p>
                                    <p>Leasera Points cannot be combined, accrued or transferred from multiple accounts (including those of family members) or other sources into a single Leasera Points account.</p>
                                    <p>For qualifying residential leases, Leasera Points members will receive points based upon the time accrued under the lease and the monthly rent paid. Actual points earned may vary depending on many factors, including but not limited to the level of participation of the leasing entity in the Leasera Points program.</p>
                                    <p>The following types of transactions are not eligible for accrual of Leasera Points:
                                        <ul>
                                            <li>Canceled leases</li>
                                            <li>Leasera Points award credits, services or stays</li>
                                            <li>Bonus months</li>
                                            <li>Zero-rent leases</li>
                                            <li>Reduced industry rate leases</li>
                                            <li>Any award acquired or used in violation of these conditions of membership</li>
                                            <li>Any discount service deemed ineligible by Leasera</li>
                                        </ul>
                                    </p>
                                    <p>In addition, Leasera Points are not awarded for leases or stays cancelled due to weather or acts of God.</p>
                                    <p>Leasera reserves the right to determine the eligibility of new transaction types for points credit as they become available.</p>
                                    <p>It is the responsibility of the member to retain receipts, rental agreements, etc. until points credit appears on a membership statement or their “My account” page on www.leasera.com.</p>
                                    <p>The member must notify Leasera customer care of any discrepancies in his or her account within one year of the activity date unless a shorter time period is identified by partner. The request and supporting documentation should be sent directly to the Leasera Points program at one of the points of contact listed above. Requests must include the member's name, Leasera Points account number and supporting documentation.. All documentation must be legible and include the full legal name of the member (initials are not acceptable) and the dollar value of the transaction. Some Leasera Points partners may require the supporting documentation be sent directly to them to determine points eligibility.</p>
                                    <p>Requests for retroactive Leasera Points credit for eligible activity may be made&nbsp;or by calling Leasera customer care. Points may be credited for up to one year from the date of the qualifying activity. Points cannot be credited for activity that occurred prior to enrollment in the Leasera Points program.</p>
                                </li>
                                <li><p><h3>Redeeming Leasera Points</h3></p></li>
                                <li>
                                    <p><h3>Redeeming points for Partner Services (Awards)</h3>Leasera reserves the right to establish redemption rules and to set the number of points required to obtain Leasera Points benefits, which are subject to change at Leasera's sole discretion without notice. Any such changes shall apply regardless of award levels and rules in effect at the time points were accrued. The number of points required to redeem an award shall be that number of points set by Leasera when points are redeemed for the award, not when points is accrued. Notifications or changes concerning the procedures for redeeming points, accruing points, points award levels, and membership rules will be published by Leasera and will be available at www.leasera.com. Members will be deemed to accept these conditions of membership, in their then-current form, each time they redeem points for an award, regardless of the award levels and rules in effect at the time the points were accrued.</p>
                                    <p>Points are deducted at the time an award election is made and an electronic award certificate issued. Any applicable taxes, fees or costs will be collected at time of issuance.</p>
                                    <p>Leasera reserves the right to charge administrative, membership or other fees associated with the Leasera Points program, including points redemption fees for redemptions performed through the award reservations page on Leasera’ website or through our Reservations offices.</p>
                                    <p>A member will not be permitted to redeem Leasera Points for an award unless he or she has the full points balance required for the award in his or her Leasera Points account. Awards must be redeemed via the award page on Leasera's website. Once issued, all Leasera Points awards are the responsibility of the member.</p>
                                    <p>Awards have no cash value and are not refundable. All service fees are nonrefundable.</p>
                                    <p>If a change is made to an award which requires an additional amount of points, those points must be taken from the same account from which the original award was redeemed.</p>
                                    <p>Leasera Points, awards and certificates may not be sold, purchased or bartered. Agents, arrangers and unauthorized brokers are not permitted to issue Leasera Points awards or to process or facilitate any other Leasera Points transactions (including Leasera Points account creation, account inquiries, and points or award ticket transfers) on behalf of others. If Leasera becomes aware that a member or a third party has misrepresented his/her identity in order to perform a Leasera Points transaction, Leasera may, in its sole discretion, void the transaction. Points or award tickets issued, transferred or obtained in violation of these conditions of membership are voidable, in Leasera’s sole discretion. The member shall be liable for the full, unrestricted value of awards issued as a result of improper or fraudulent transfers or otherwise in violation of these conditions of membership. Leasera shall not be responsible for any inconvenience, damage or loss incurred by the member if a stay is interrupted or an award is invalidated due to violation of these conditions of membership. Leasera reserves the right to deactivate the Leasera Points account and/or remove the points from the account of any member who violates these terms until liability is fulfilled, and all other rights under applicable law to enforce these conditions of membership.</p>
                                    <p>Leasera Points members are responsible for all taxes, fees and surcharges applicable to awards. Additionally, benefits received under the Leasera Points may be subject to taxes. Any such tax is the sole responsibility of the Leasera Points member.</p>
                                </li>
                                <li>
                                    <p><h3>Changes to the Leasera Points and member accounts</h3>Leasera may terminate the Leasera Points program 180 days after publishing notice of program termination on www.leasera.com.</p>
                                    <p>Leasera may, in its sole discretion, amend the Leasera Points conditions of membership, including terminating any member's ability to redeem points already accrued, at any time.</p>
                                    <p>Any and all changes in the conditions of membership, including rules regarding the number of points needed for a Leasera Points award, are retroactive and apply to all previously accumulated points.</p>
                                    <p>The accumulation of points in a member's account does not entitle the member to any vested rights with respect to any specific awards or specific Leasera Points benefits. In accumulating points, members may not rely upon the continued availability of any award or award level, and members may not be able to obtain awards for all goods or services. Leasera may, among other things:
                                        <ul>
                                            <li>Change Leasera Points benefits, participant affiliations, or cities served</li>
                                            <li>Limit the number of awards available or otherwise restrict the continued availability of awards or special offers</li>
                                            <li>Increase or decrease the points required for an award</li>
                                            <li>Modify transferability of points and awards</li>
                                        </ul>
                                    </p>
                                    <p>Leasera Points partners may on occasion implement changes affecting Leasera Points benefits without prior notice to members.</p>
                                </li>
                                <li>
                                    <p><h3>Account and points expiration</h3>Leasera Points do not have an expiration date and may remain in an active account indefinitely, provided that the Leasera Points program has not been terminated pursuant to these conditions of membership. Activity in an account includes redeeming a Leasera Points award or accruing points in the Leasera Points program. However, notwithstanding any term to the contrary, if a Leasera Points account is inactive for 2 years, Leasera may close the account, delete any points balance and reassign the Leasera Points number. Deleted points can be reinstated for a fee for up to 1 year. To reinstate a Leasera Points account please contact customer service.</p>
                                </li>
                                <li>
                                    <p><h3>Account information</h3>Leasera does not mail paper Leasera Points program statements or notices. Members may elect to receive a monthly e-Statement via e-mail, may view current activity on Leasera' website, or may call Leasera Points customer care for any updates to the member's Leasera Points account.</p>
                                    <p>Leasera is unable to provide copies of previously issued e-Statements. However, members can obtain detailed Leasera Points account activity from the previous 24 months online. Activity that occurred more than 24 months ago cannot be accessed. A My account profile is required to view activity online. Members may set up a My account profile at www.leasera.com. A user ID and password will be required to set up a My account profile. Members will be asked to provide the user ID and password when accessing account information online. It is the member's responsibility to protect his or her user ID and password so that unauthorized users may not gain access to their My account information. Leasera reserves the right to cease transmitting e-Statements, and/or related materials at any time.</p>
                                </li>
                                <li>
                                    <p><h3>Leasera partners.</h3>Leasera has a variety of partners that permit the accrual and/or redemption of points in the Leasera Points program to members who utilize their products or services. These partners are independent entities and Leasera is not responsible for the nature or quality of the products or services provided by those partners.</p>
                                    <p>Leasera is not responsible for, and assumes no liability for changes imposed by partners, including changes in award levels, accrual amounts, rules, award cancellations, withdrawal of the partner, or discontinued services.</p>
                                    <p>Partners may, at their discretion and without notice, change the amount of points required for their awards, the amount of points credited for an activity, or impose other restrictions upon the use of awards for their services. Points accrued through utilization of a partner’s products or services are subject to such partner’s eligibility guidelines.</p>
                                </li>
                                <li>
                                    <p><h3>Points transfers</h3>Leasera Points cannot be combined, accrued or transferred from multiple accounts (including those of family members) or other sources into a single Leasera Points account. Property managers, real estate agents, travel arrangers and unauthorized brokers are not permitted to perform points transfers or to process or facilitate any other Leasera Points transactions (including Leasera Points account creation, account inquiries, and award transfers) on behalf of others.</p>
                                </li>
                                <li>
                                    <p><h3>General Terms and Conditions</h3>Awards or accrued Points, including purchased Points, do not constitute the property of the member to whose Leasera Points account the Points have been posted. However, awards or accrued Points do provide the member with an option to exchange such Points at the redemption level in effect at the time of redemption (not at the time of accrual).</p>
                                    <p>Leasera reserves the right to audit Leasera Points accounts at any time and without notice to the member to ensure compliance with these Leasera Points Conditions of Membership and applicable conditions of carriage and/or tariffs. In the event that an audit reveals discrepancies or violations, the processing of Leasera Points awards, points accrual or redemption of accrued points may be suspended until the discrepancies or violations are resolved to Leasera' satisfaction. Leasera reserves the right to terminate the membership and/or suspend access to the points balance of any member at any time for any reason it deems appropriate.</p>
                                    <p>No benefit of Leasera Points membership - including points credit, awards, or certificates - may be sold, purchased, or bartered. Points, certificates, tickets, or other benefits of Leasera Points membership that are sold, purchased or bartered, and awards or Points that are obtained from any unauthorized source (including agents, travel arrangers and unauthorized brokers), are voidable, in Leasera’ sole discretion. Attempts to purchase, sell or barter these products or benefits shall be considered an act of fraud against Leasera. The member shall be liable for the full, unrestricted value of awards issued as a result of improper or fraudulent transfers or otherwise in violation of these conditions of membership. Leasera reserves the right to deactivate the Leasera Points account of any member involved in such activities until liability is fulfilled.</p>
                                    <p>In the event a Leasera Points member violates these conditions of membership, perpetrates a fraudulent or deceitful act against Leasera, any of its partners or any of its customers, or engages in behavior offensive to Leasera' employees, partners or customers, Leasera may, without notice and in its sole discretion, suspend the Leasera Points member's account; terminate membership in the Leasera Points program; cancel all previously accumulated points; refuse to redeem points on a temporary or permanent basis; and/or confiscate any certificates or awards previously issued. Reinstatements are subject to an administrative fee, if Leasera elects to reinstate the account. Leasera reserves its right to seek all available damages at law and in equity that may result from a member's violation of these conditions of membership. Participation in the Leasera Points program may be prohibited or restricted in some countries. Nothing in these conditions of membership should be read as an attempt to override or circumvent any such foreign laws, and the Leasera Points program is void where prohibited by such laws.</p>
                                </li>
                                <li>
                                    <p><h3>Privacy</h3>Members may view Leasera’s most up-to-date privacy notice, including opt-out options, at www.leasera.com.</p>
                                    <p>The member's name, address, telephone number and Leasera Points account number should be included in all written communications. Additional information may be requested to verify membership in telephonic communications.</p>
                                </li>
                                <li>
                                    <p><h3>Limitations on damages</h3>IN NO EVENT SHALL LEASERA OR ITS RESPECTIVE OFFICERS, DIRECTORS AND EMPLOYEES BE LIABLE TO ANY MEMBER, OR ANYONE CLAIMING THROUGH A MEMBER, FOR ANY DIRECT, INDIRECT OR CONSEQUENTIAL DAMAGES ARISING OUT OF ACTS OR OMISSIONS BY LEASERA, ITS AFFILIATES, ANY LEASERA POINTS PARTNER OR ANY LEASERA POINTS MEMBER IN CONNECTION WITH THE LEASERA POINTS. IF LEASERA AND/OR ANY LEASERA POINTS PARTNER IMPROPERLY DENIES A MEMBER POINTS CREDIT, AWARD OR SOME OTHER BENEFIT, THE MEMBER'S EXCLUSIVE REMEDY SHALL BE THE ISSUANCE OF THE IMPROPERLY DENIED CREDIT, AWARD OR BENEFIT, IF AVAILABLE, OR SUCH ALTERNATIVE, COMPARABLE BENEFIT AS DETERMINED BY LEASERA IN ITS SOLE DISCRETION.</p>
                                </li>
                                <li>
                                    <p><h3>Leasera's partners terms and conditions</h3>In addition to Leasera' conditions of membership and program guidelines, Leasera Points partners may have their own policies and rules that affect members' Leasera Points benefits. It is incumbent upon members to check directly with the particular Leasera Points Partner to obtain additional terms, conditions or restrictions.</p>
                                    <p>Contact our Leasera Points partners directly for additional information.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div> */}
      </>
    );
  }
}

export default withApollo(Terms);
