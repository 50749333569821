import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Form, Button, Grid, Radio } from "semantic-ui-react";
import { MIN, MAX, AGE_RANGE } from "../../utils";
import DatePicker from "../../components/DatePicker";
import {
  getPriceList,
  priceText,
  getBedBath,
  getValue,
  getTextVal,
  getBedText,
  getBathText,
  getSizeText,
  useOutsideClickDetection,
} from "./helper";
import AdditionalFilters from "./AdditionalFilters";

function MoreFilters({ values, onChangeField }) {
  const { totalPrices, totalBeds, totalBaths, totalSizes, amenities, listType, locationTypes } = values;
  const [listingDropdown, setListingDropdown] = useState(false);
  const [listingType, setListingType] = useState("1");
  const [listingTypeName, setListingTypeName] = useState();
  const [minBedOption, setMinBedOption] = useState([]);
  const [maxBedOption, setMaxBedOption] = useState([]);
  const [minBathOption, setMinBathOption] = useState([]);
  const [maxBathOption, setMaxBathOption] = useState([]);
  const [priceKey, setPriceKey] = useState(MIN);
  const [showAdditional, setShowAdditional] = useState(false);
  const [adults, setAdults] = useState(values.adults ? values.adults : 1);
  const [rooms, setRooms] = useState(values.rooms ? values.rooms : 1);
  const [childrenCount, setChildrenCount] = useState(0);
  const [children, setChildren] = useState([]);

  const closeListingDropdown = (x) => {
    // function to close the listing dropdown after a option selection
    setListingDropdown(x);
  };

  //wrapper to detect click outside of div
  const wrapperRef = useRef(null);
  useOutsideClickDetection(wrapperRef, closeListingDropdown, false);

  useEffect(() => {
    setMinBedOption(getBedBath(null, MIN));
    setMaxBedOption(getBedBath(null, MAX));
    setMinBathOption(getBedBath(null, MIN));
    setMaxBathOption(getBedBath(null, MAX));
  }, []);

  useEffect(() => {
    switch(listType) {
      case '1':
        setListingTypeName('All')
        break;
      case '2':
        setListingTypeName('Short')
        break;
      case '3':
        setListingTypeName('Long');
        break;
      default:
        setListingTypeName('All')
    }
  }, [listType]);

  // updates the children field after multiple selection of children age range
  useEffect(() => {
    onChangeField("children", children);
  }, [children]);

  // function to update the count of adults on the basis of plus or minus action
  const setAdultsInt = async (action) => {
    (await action) === "plus"
      ? setAdults((adults) => adults + 1)
      : setAdults((adults) => adults - 1);
    onChangeField("adults", adults + 1);
  };

  // function to update the count of children on the basis of plus or minus action
  const setChildrenNo = async (action) => {
    (await action) === "plus"
      ? setChildrenCount((childrenCount) => childrenCount + 1)
      : setChildrenCount((childrenCount) => childrenCount - 1);
  };

  // function to update child age in an array
  const updateChildAge = async (value) => {
    await setChildren((children) => [...children, value]);
  };

  // function to update the count of rooms on the basis of plus or minus action
  const setRoomsInt = async (action) => {
    (await action) === "plus"
      ? setRooms((rooms) => rooms + 1)
      : setRooms((rooms) => rooms - 1);
    onChangeField("rooms", rooms + 1);
  };

  // function to display children age range dropdowns
  const ageNeededDropdown = () => (
    <div className="rental-age-col">
      <Dropdown
        placeholder="Age needed"
        fluid
        search
        selection
        onChange={(e, ele) => updateChildAge(ele.value)}
        options={AGE_RANGE(18)}
      />
    </div>
  );

  const trigger = (
    <div className="short-rental-placeholder">
      <span>{adults} Adult(s)</span> <span>{childrenCount} Children</span>{" "}
      <span>{rooms} Rooms</span>
    </div>
  );

  // function to display booking.com filters
  const shortAdditionalFilter = () => (
    <>
      <div className="search-rental-wrap">
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-adult.svg"
            className="input-icon adult"
          />
          <Form.Dropdown
            className="link item short-rental-dropdown"
            trigger={trigger}
            fluid
            placeholder="Property Type"
            selection
            simple
            text="Property Type"
          >
            <Dropdown.Menu className="inner-dropdown short-rental-row">
              <div className="short-rental-column">
                <div className="short-rental-label">Adults</div>
                <div className="short-rental-buttons">
                  <Button
                    onClick={() => setAdultsInt("minus")}
                    disabled={adults === 1}
                  >
                    -
                  </Button>
                  <span className="rental-btn-count"> {adults} </span>
                  <Button onClick={() => setAdultsInt("plus")}>+</Button>
                </div>
              </div>
              <div className="short-rental-column">
                <div className="short-rental-label">
                  Children <span className="text-sm">(Ages 0-17)</span>
                </div>
                <div className="short-rental-buttons">
                  <Button
                    onClick={() => setChildrenNo("minus")}
                    disabled={childrenCount === 0}
                  >
                    -
                  </Button>
                  <span className="rental-btn-count">{childrenCount}</span>
                  <Button onClick={() => setChildrenNo("plus")}>+</Button>
                </div>
              </div>
              <div className="rental-age-wrap">
                {[...Array(childrenCount)].map(() => ageNeededDropdown())}
              </div>
              <div className="short-rental-column">
                <div className="short-rental-label">Rooms</div>
                <div className="short-rental-buttons">
                  <Button
                    onClick={() => setRoomsInt("minus")}
                    disabled={rooms === 1}
                  >
                    -
                  </Button>
                  <span className="rental-btn-count">{rooms}</span>
                  <Button onClick={() => setRoomsInt("plus")}>+</Button>
                </div>
              </div>
            </Dropdown.Menu>
          </Form.Dropdown>
        </div>

        {/* <div>
                <span>Adults</span>
                <Button onClick={() => setAdultsInt('minus')}>-</Button>
                <span>{adults}</span>
                <Button onClick={() => setAdultsInt('plus')}>+</Button>
            </div>
            <div>
                <span>Children</span><span>Ages 0-17</span>
                <Button onClick={() => setChildrenNo('minus')}>-</Button>
                <span>{childrenCount}</span>
                <Button onClick={() => setChildrenNo('plus')}>+</Button>
            </div>
            <div>
                {[...Array(childrenCount)].map(() => ageNeededDropdown())}
            </div>
            <div>
                <span>Rooms</span>
                <Button onClick={() => setRoomsInt('minus')}>-</Button>
                <span>{rooms}</span>
                <Button onClick={() => setRoomsInt('plus')}>+</Button>
            </div> */}
      </div>
    </>
  );

  // function to display long term filters
  const longAdditionalFilter = () => (
    <>
      <div className="longsearch-rental-wrap">
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-bed.svg"
            className="input-icon"
          />
          <Dropdown
            className="dropdown-col"
            clearable
            fluid
            onChange={() => {
              onChangeField(`totalBeds`, null);
              setMinBedOption(getBedBath(null, MIN));
              setMaxBedOption(getBedBath(null, MAX));
            }}
            placeholder="Beds"
            pointing="top"
            selection
            text={getBedText(totalBeds)}
            value={getBedText(totalBeds)}
          >
            <Dropdown.Menu className="dropdown-col-menu inner-dropdown beds-inner-dropdown">
              <Form.Group widths="equal" className="min-max-input">
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={8}>
                      <Form.Dropdown
                        className="dropdown-col-min"
                        fluid
                        onChange={(e, { value }) => {
                          e.preventDefault();
                          onChangeField(`totalBeds[0]`, getValue(value));
                        }}
                        onMouseEnter={() => {
                          totalBeds &&
                            setMinBedOption(getBedBath(totalBeds[1], MIN));
                        }}
                        options={minBedOption}
                        placeholder="Min"
                        pointing="top"
                        selection
                        simple
                        text={totalBeds && getTextVal(totalBeds[0])}
                        value={totalBeds && getTextVal(totalBeds[0])}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Dropdown
                        className="dropdown-col-max"
                        fluid
                        onChange={(e, { value }) => {
                          e.preventDefault();
                          onChangeField(`totalBeds[1]`, getValue(value));
                        }}
                        onMouseEnter={() => {
                          totalBeds &&
                            setMaxBedOption(getBedBath(totalBeds[0], MAX));
                        }}
                        options={maxBedOption}
                        placeholder="Max"
                        pointing="top"
                        selection
                        simple
                        text={totalBeds && getTextVal(totalBeds[1])}
                        value={totalBeds && getTextVal(totalBeds[1])}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Group>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-bath.svg"
            className="input-icon"
          />
          <Dropdown
            className="dropdown-col"
            clearable
            fluid
            onChange={() => {
              onChangeField(`totalBaths`, null);
              setMinBathOption(getBedBath(null, MIN));
              setMaxBathOption(getBedBath(null, MAX));
            }}
            placeholder="Baths"
            pointing="top"
            selection
            text={getBathText(totalBaths)}
            value={getBathText(totalBaths)}
          >
            <Dropdown.Menu className="dropdown-col-menu inner-dropdown beds-inner-dropdown">
              <Form.Group widths="equal" className="min-max-input">
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={8}>
                      <Form.Dropdown
                        className="dropdown-col-min"
                        fluid
                        onChange={(e, { value }) => {
                          onChangeField(`totalBaths[0]`, getValue(value));
                        }}
                        onMouseEnter={() => {
                          totalBaths &&
                            setMinBathOption(getBedBath(totalBaths[1], MIN));
                        }}
                        options={minBathOption}
                        placeholder="Min"
                        pointing="top"
                        selection
                        simple
                        text={totalBaths && getTextVal(totalBaths[0])}
                        value={totalBaths && getTextVal(totalBaths[0])}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Dropdown
                        className="dropdown-col-max"
                        fluid
                        onChange={(e, { value }) => {
                          onChangeField(`totalBaths[1]`, getValue(value));
                        }}
                        onMouseEnter={() => {
                          totalBaths &&
                            setMaxBathOption(getBedBath(totalBaths[0], MAX));
                        }}
                        options={maxBathOption}
                        placeholder="Max"
                        pointing="top"
                        selection
                        simple
                        text={totalBaths && getTextVal(totalBaths[1])}
                        value={totalBaths && getTextVal(totalBaths[1])}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Group>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-size.svg"
            className="input-icon"
          />
          <Dropdown
            className="dropdown-col"
            placeholder="Size (sq.ft)"
            text={getSizeText(totalSizes)}
            value={getSizeText(totalSizes)}
            onChange={() => {
              onChangeField("totalSizes", null);
            }}
            fluid
            selection
            clearable
          >
            <Dropdown.Menu
              className="dropdown-col-menu inner-dropdown beds-inner-dropdown"
              scrolling={false}
              onClick={(e) => e.stopPropagation()}
            >
              <Form.Group widths="equal" className="min-max-input">
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={8}>
                      <Form.Input
                        className="search"
                        fluid
                        placeholder="Min"
                        className="dropdown-col-min"
                        type="number"
                        value={totalSizes ? getTextVal(totalSizes[0]) : ""}
                        onChange={(e, { value }) => {
                          e.stopPropagation();
                          onChangeField(`totalSizes[0]`, getValue(value));
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPriceKey(MIN);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        className="dropdown-col-max"
                        fluid
                        placeholder="Max"
                        value={totalSizes ? getTextVal(totalSizes[1]) : ""}
                        type="number"
                        onChange={(e, { value }) => {
                          e.stopPropagation();
                          setPriceKey(MAX);
                          onChangeField(`totalSizes[1]`, getValue(value));
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPriceKey(MAX);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Group>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );

  return (
    <>
      <DatePicker values={values} onChangeField={onChangeField} />
      <Form.Field>
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-price.svg"
            className="input-icon price"
          />
          <Dropdown
            className="dropdown-col"
            placeholder="Price"
            text={priceText(totalPrices)}
            value={priceText(totalPrices)}
            onChange={(e) => onChangeField(`totalPrices`, null)}
            fluid
            selection
            clearable
          >
            <Dropdown.Menu
              className="dropdown-col-menu"
              scrolling={false}
              onClick={(e) => e.stopPropagation()}
            >
              <Form.Group widths="equal" className="min-max-input">
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={8}>
                      <Form.Input
                        className="search"
                        fluid
                        placeholder="Min"
                        className="dropdown-col-min"
                        type="number"
                        value={totalPrices ? getTextVal(totalPrices[0]) : ""}
                        onChange={(e, { value }) => {
                          e.stopPropagation();
                          onChangeField(`totalPrices[0]`, getValue(value));
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPriceKey(MIN);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        className="dropdown-col-max"
                        fluid
                        placeholder="Max"
                        value={totalPrices ? getTextVal(totalPrices[1]) : ""}
                        type="number"
                        onChange={(e, { value }) => {
                          e.stopPropagation();
                          setPriceKey(MAX);
                          onChangeField(`totalPrices[1]`, getValue(value));
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPriceKey(MAX);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Group>
              <Dropdown.Divider />
              {getPriceList(totalPrices, priceKey).map((option) => (
                <Dropdown.Item
                  className={priceKey === MAX && "menu-align-right"}
                  key={option.value}
                  {...option}
                  onClick={(e, { value }) => {
                    let key = priceKey === MIN ? 0 : 1;
                    setPriceKey(priceKey === MIN ? MAX : MIN);
                    onChangeField(`totalPrices[${key}]`, value);
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Form.Field>
      {/* <Form.Field> */}
      <div className="search-with-icon" ref={wrapperRef}>
        <img
          src="assets\img\search-icons\icon-rental.svg"
          className="input-icon rental"
        />
        <Form.Dropdown
          placeholder={listingTypeName}
          className="link item listing-type"
          fluid
          selection
          open={listingDropdown}
          onClick={() => setListingDropdown(true)}
        >
          <Dropdown.Menu className="inner-dropdown">
            <Form.Group widths="equal">
              <Form.Field>
                <Radio
                  checked={listingType === "1" || listType === "1"}
                  label="All"
                  name="listingType"
                  onChange={(e, { value }) => {
                    e.stopPropagation();
                    setListingDropdown(false);
                    setListingTypeName(value);
                    onChangeField("listType", getTextVal("1"));
                  }}
                  onClick={() => setListingType("1")}
                  value={"All"}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  checked={listingType === "2" || listType === "2"}
                  label="Short Term Rentals"
                  name="listingType"
                  onChange={(e, { value }) => {
                    e.stopPropagation();
                    setListingDropdown(false);
                    setListingTypeName(value);
                    onChangeField("listType", getTextVal("2"));
                  }}
                  onClick={() => setListingType("2")}
                  value={"Short"}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  checked={listingType === "3" || listType === "3"}
                  label="Long Term Rentals"
                  name="listingType"
                  onChange={(e, { value }) => {
                    e.stopPropagation();
                    setListingDropdown(false);
                    setListingTypeName(value);
                    onChangeField("listType", getTextVal("3"));
                  }}
                  onClick={() => setListingType("3")}
                  value={"Long"}
                />
              </Form.Field>
            </Form.Group>
          </Dropdown.Menu>
        </Form.Dropdown>
      </div>
      {/* </Form.Field> */}
      {showAdditional ? (
        <AdditionalFilters
          onChangeField={onChangeField}
          listingType={listType ? listType.toString() : listingType}
          amenities={amenities}
          longAdditionalFilter={longAdditionalFilter}
          shortAdditionalFilter={shortAdditionalFilter}
          locationTypes={locationTypes}
        />
      ) : (
        <div className="filters-spacer"></div>
      )}
      <Button
        className={`btn-gray btn-sm ml-2 filters-more-btn${
          showAdditional ? " all" : ""
        }`}
        onClick={() => setShowAdditional(!showAdditional)}
      >
        {showAdditional ? 'Hide Filters' : 'More Filters'}
      </Button>
    </>
  );
}

export default MoreFilters;
