import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, Image } from "semantic-ui-react";
import Header from "../../components/Header";
import { useBooking } from "../../context/booking-context";
import "./bookingPayment.scss";

const BookingPaymentThree = () => {
  const history = useHistory();
  const bookingCtx = useBooking();

  useEffect(() => {
    bookingCtx.clearBookingState();
  }, []);

  if (!bookingCtx.bookingState.bookingDetails)
    history.push({ pathname: "/renter" });

  const { selectedProperty, bookingDetails } = bookingCtx.bookingState;

  return (
    <div>
      <div className="renter-listing-header">
        <Header />
      </div>
      <div className="booking-payment-wrap">
        <Container>
          <Grid>
            {/* Booking Success component start */}
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="booking-completed-card">
                  <div className="booking-img">
                    <Image src="assets/img/booking-check.svg" alt="check" />
                  </div>
                  <div className="booking-card-title">
                    Booking Completed! #{bookingDetails.response.reservation_id}
                  </div>
                  <div className="booking-card-subtitle">{}</div>
                  <div className="booked-room-row">
                    <div className="room-img">
                      <Image
                        src="https://www.intechnic.com/hubfs/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg"
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="room-title">{selectedProperty.name}</div>
                      <div className="room-title primary mt-2">
                        {selectedProperty.hotel_important_information}
                      </div>
                      <div className="room-subtitle">
                        {bookingDetails.block.name}
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <button
                      type="submit"
                      onClick={() => history.push({ pathname: "/renter" })}
                      className="btn btn-color"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {/* Booking Success component end */}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default BookingPaymentThree;
