// @flow
import merge from "lodash/merge";

import * as authUser from "./authUser";

export * from "./authUser";
export * from "./loginAction";
export * from "./logoutAction";
export * from "./getAuthToken";
export * from "./init-apollo-googleFn";

export const schema = [authUser.schema];

export const defaults = merge({}, authUser.defaults);

export const resolvers = merge({}, authUser.resolvers);
