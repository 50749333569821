import React, { Component } from "react";
import { withApollo } from "react-apollo";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Header />
        <div class="banner banner-service-provider faq-banner">
          <div class="container text-center">
            <div class="row">
              <div class="col-12">
                <h1>FAQ's</h1>
                <h4>Answers to commonly asked questions.</h4>
              </div>
            </div>
          </div>
        </div>
        <section class="faqs-section">
          <div class="container">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="Access-tab"
                  data-toggle="tab"
                  href="#Access"
                  role="tab"
                  aria-controls="Access"
                  aria-selected="false"
                >
                  Access
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="Search-tab"
                  data-toggle="tab"
                  href="#Search"
                  role="tab"
                  aria-controls="Search"
                  aria-selected="false"
                >
                  Search
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="Pay-tab"
                  data-toggle="tab"
                  href="#Pay"
                  role="tab"
                  aria-controls="Pay"
                  aria-selected="false"
                >
                  Pay
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="General-tab"
                  data-toggle="tab"
                  href="#General"
                  role="tab"
                  aria-controls="General"
                  aria-selected="false"
                >
                  General
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Access"
                role="tabpanel"
                aria-labelledby="Access-tab"
              >
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Can I change the Language of my Online Portal?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Right now we only support English on our platform. If
                        you use Chrome or Firefox there are built in translation
                        tools to help with your journey.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How do I sign up as a Renter?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Head on over to our Sign Up button in the upper right of
                        this portal.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How do I sign up as a Manager?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Head on over to our Sign Up button in the upper right of
                        this portal.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What if I am both a Renter and a Manager?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Traditionally we aim to separate the logins due to
                        security. If you need to combine accounts into one email
                        then please contact the Website Admin with required
                        Manager information.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How do I sign in?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Head on over to our Sign In button in the upper right of
                        this portal.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="Search"
                role="tabpanel"
                aria-labelledby="Search-tab"
              >
                <div class="accordion" id="accordionExample2">
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          How do I search Properties?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample2"
                    >
                      <div class="card-body">
                        On the main search portal, click the button in the top
                        right that says Sign In.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          How do I search for short term trips?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample2"
                    >
                      <div class="card-body">
                        Our platform allows for the seamless transition for all
                        lengths of trips. Use the search portal as you normally
                        would and set your duration for any length you wish!
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEight">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          What do I do once I want to apply?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordionExample2"
                    >
                      <div class="card-body">
                        Once you find the property that looks interesting click
                        on the Rent Now button.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingNine">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          Why can’t I apply?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseNine"
                      class="collapse"
                      aria-labelledby="headingNine"
                      data-parent="#accordionExample2"
                    >
                      <div class="card-body">
                        Sometimes a property will set a requirement of a
                        background check that will cause your application to be
                        set on hold. Please contact the Property manager if this
                        happens.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="Pay"
                role="tabpanel"
                aria-labelledby="Pay-tab"
              >
                <div class="accordion" id="accordionExample3">
                  <div class="card">
                    <div class="card-header" id="headingTen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="true"
                          aria-controls="collapseTen"
                        >
                          I do not have a bank account or credit card, is there
                          another option?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTen"
                      class="collapse"
                      aria-labelledby="headingTen"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Please contact your property manager, as this is a
                        service they can choose whether or not to offer their
                        residents.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEleven">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          What type of credit or debit card can I use?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseEleven"
                      class="collapse"
                      aria-labelledby="headingEleven"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Credit or debit card payments can be made from a Visa,
                        Mastercard, JCB, Diners Club or Discover branded card
                        from any country. American Express, gift cards, or
                        prepaid cards are not accepted.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwelve">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                          Can my property manager see any of my payment
                          information?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwelve"
                      class="collapse"
                      aria-labelledby="headingTwelve"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        No. Your property manager cannot see any of the payment
                        information you have saved or have previously entered.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThirteen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThirteen"
                          aria-expanded="false"
                          aria-controls="collapseThirteen"
                        >
                          How is my payment information protected?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThirteen"
                      class="collapse"
                      aria-labelledby="headingThirteen"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        We take privacy and security seriously and invest in
                        state of the art systems to protect payment information.
                        Security is baked into our software development
                        lifecycle. All sensitive data is encrypted both in
                        transit and at rest.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFourteen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFourteen"
                          aria-expanded="false"
                          aria-controls="collapseFourteen"
                        >
                          Is there a fee to make payments online?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFourteen"
                      class="collapse"
                      aria-labelledby="headingFourteen"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        eCheck payments are free to you, but there is a
                        non-refundable online portal convenience fee for a
                        Credit or Debit card payment. This is in place to comply
                        with current card network regulations. You are notified
                        of the fee amount when you select the Credit or Debit
                        card payment option, and are given the option to switch
                        to eCheck.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="General"
                role="tabpanel"
                aria-labelledby="General-tab"
              >
                <div class="accordion" id="accordionExample4">
                  <div class="card">
                    <div class="card-header" id="headingFifteen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFifteen"
                          aria-expanded="true"
                          aria-controls="collapseFifteen"
                        >
                          What are Leasera points?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFifteen"
                      class="collapse"
                      aria-labelledby="headingFifteen"
                      data-parent="#accordionExample4"
                    >
                      <div class="card-body">
                        Leasera Points are out way of giving back to you as a
                        renter. By doing everyday tasks and simply being a great
                        renter you can earn both passively and actively. These
                        points can be used through any of our partners to save
                        you money and get you some truly amazing deals!
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSixteen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSixteen"
                          aria-expanded="false"
                          aria-controls="collapseSixteen"
                        >
                          As renter, how do I take advantage of the benefits
                          provided by Leasera?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSixteen"
                      class="collapse"
                      aria-labelledby="headingSixteen"
                      data-parent="#accordionExample4"
                    >
                      <div class="card-body">
                        When you’re a renter with us, you don’t have to wait to
                        start benefiting. We immediately begin showing you the
                        points you’ve earned, the services directly available
                        through your renter’s portal and the discounts and added
                        benefits you can take advantage of.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeventeen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="collapseSeventeen"
                        >
                          If I’m a solo manager operating my personal portfolio,
                          does this platform still work for me?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSeventeen"
                      class="collapse"
                      aria-labelledby="headingSeventeen"
                      data-parent="#accordionExample4"
                    >
                      <div class="card-body">
                        We built our services to be intuitive and automated. The
                        flexibility we offer allows for smaller portfolios and
                        teams as well as services that scale with larger teams
                        across multiple geographies.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEighteen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseEighteen"
                          aria-expanded="false"
                          aria-controls="collapseEighteen"
                        >
                          What sort of clients do you work with?
                          <em>
                            <img
                              src="assets/img/marketing/icons-arrow-down.svg"
                              alt="arrow down"
                            />
                          </em>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseEighteen"
                      class="collapse"
                      aria-labelledby="headingEighteen"
                      data-parent="#accordionExample4"
                    >
                      <div class="card-body">
                        We’re excited to work with all kinds of property
                        managers that are innovative, understand or wish to
                        unlock the true value of the consumer-renter and know
                        there’s far more they can get out of the business. These
                        clients are usually multi-family but we do see mixed use
                        and single family clients taking advantage of the tools
                        and providing better services to their renter community.
                      </div>
                    </div>
                  </div>
                  {/* <div class="card">
                                        <div class="card-header" id="headingFive">
                                            <h2 class="mb-0">
                                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                                    How do I sign in?
                                                                    <em>
                                                        <img src="assets/img/marketing/icons-arrow-down.svg" alt="arrow down" />
                                                    </em>
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div class="card-body">
                                                Traditionally we aim to separate the logins due to security. If you need to combine accounts into one email then please contact the Website Admin with required Manager information.
                                                                </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Newsletter />
        <FooterMain />
      </>
    );
  }
}

export default withApollo(FAQ);
