import React from 'react'
import { Modal, Image } from 'semantic-ui-react'

function RoomDetailModal({ data, getAmenity }) {
    
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      className="roomDetailModal"
      closeIcon
      open={open}
      trigger={<div className="room-title">{data.name}</div>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
        <Modal.Content image>
            <div>
            <div id="roomlist" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    {data.photos.map((elem, index) => (
                        <div class={`carousel-item ${index === 0 && 'active'}`}>
                            <Image src={elem.url_original} alt="image" />
                        </div>
                    ))}
                </div>
                <a class="carousel-control-prev" href="#roomlist" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#roomlist" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>
            </div>
            <div className="room-title">{data.name}</div>
            {getAmenity}
            <div className="room-facilities-list">
                {data.facilities.map((item) => (<div className="facilities-list-item">{item}</div>))}
            </div>
            </div>
        </Modal.Content>
    </Modal>
  )
}

export default RoomDetailModal