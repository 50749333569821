import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Card, Container, Grid, Image } from "semantic-ui-react";
import * as Yup from "yup";
import Header from "../../components/Header";
import { useBooking } from "../../context/booking-context";
import { Logger, ROUTES } from "../../utils";
import "./bookingPayment.scss";
import { MemoizedBookingBlockDetail } from "./components/BookingBlockDetail";
import BookingCancelationInfo from "./components/BookingCancelationInfo";
import FinePrint from "./components/FinePrint";

const ProcessBookingSchema = Yup.object().shape({
  checkin: Yup.string().required(),
  checkout: Yup.string().required(),
  hotelId: Yup.string().required(),
  blockIds: Yup.string().required(),
  blockQuantities: Yup.string().required(),
  incrementalPrices: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  address: Yup.string().required(),
  zip: Yup.string().required(),
  city: Yup.string().required(),
  email: Yup.string().email().required(),
  emailVerify: Yup.string().email().required(),
  telephone: Yup.string().required(),
  guestQuantities: Yup.string().required(),
  travelForWork: Yup.bool().default(false).required(),
  bookingForSelf: Yup.bool().default(false).required(),
});

const BookingPaymentOne = () => {
  const history = useHistory();
  const bookingCtx = useBooking();
  const logger = Logger("BookingPaymentOne");

  logger.log("bookingCtx", bookingCtx.bookingState);

  const {
    bookingPropertyData,
    selectedProperty: hotelInfo,
    selectedBlock, // last added block into context rooms. This will work just in single block algorithm for processBooking mutation.
    addedRooms,
    totalPrice,
    adults,
    children,
  } = bookingCtx.bookingState;

  logger.log("History", bookingCtx.bookingState);

  const ProcessBookingInitializeValues = {
    checkin: "",
    checkout: "",
    hotelId: "",
    blockIds: "",
    blockQuantities: addedRooms.length.toString(),
    incrementalPrices: totalPrice.toString(),
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zip: "",
    email: "",
    emailVerify: "",
    telephone: "",
    bookingDetails: {},
    guestQuantities: "1",
    travelForWork: false,
    bookingForSelf: false,
  };

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: ProcessBookingInitializeValues,
    validationSchema: ProcessBookingSchema,
    onSubmit: (values) => {
      logger.log("onSubmit", values);

      bookingCtx.updateBooking({ form: values }); // add the form values

      history.push({
        pathname: ROUTES.BOOKING_PAYMENT_TWO,
        formData: values,
        bookingState: bookingCtx.bookingState,
      });
    },
  });

  if (
    addedRooms.length === 0 ||
    bookingCtx.bookingState.bookingPropertyData === null ||
    bookingCtx.bookingState.selectedProperty === null
  ) {
    history.push({ pathname: "/booking-details" });
    return null;
  } else {
    const blockIds = addedRooms[0].id.toString();
    if (values.blockIds !== blockIds) {
      setFieldValue("blockIds", blockIds);
    }
    if (values.hotelId !== bookingPropertyData?.hotel_id.toString()) {
      setFieldValue("hotelId", bookingPropertyData.hotel_id.toString());
    }
  }

  const { checkin, checkout, block } = bookingPropertyData;
  const { checkin_checkout_times } = hotelInfo;
  if (values.checkin !== checkin) {
    setFieldValue("checkin", checkin);
  }
  if (values.checkout !== checkout) {
    setFieldValue("checkout", checkout);
  }

  const checkInFormatted = moment(checkin).format("ddd,MMMM D,YYYY");
  const checkoutFormatted = moment(checkout).format("ddd,MMMM D,YYYY");

  const defaultParamsForComponents = {
    bookingPropertyData: { checkin, checkout },
    hotelInfo: { checkin_checkout_times },
  };

  logger.log("Formik - value", values);
  logger.log("Formik - error", errors);

  return (
    <div>
      <div className="renter-listing-header">
        <Header />
      </div>
      <div className="booking-payment-wrap">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="page-back">
                  <Link to={ROUTES.BOOKING_DETAIL}>
                    <Image src="assets/img/back-icon.svg" alt="back" />
                  </Link>
                  <Link to={ROUTES.BOOKING_DETAIL} className="back-text">
                    Back to detail page
                  </Link>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <Card.Group>
                  <Card fluid>
                    <Card.Content>
                      <div className="payment-form-wrap">
                        <div className="form-title">
                          Fill out the information
                        </div>
                        {/* <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-switch-row">
                              <div className="switch-label">
                                Travelling for work?
                              </div>
                              <Radio
                                toggle
                                value={values.travelForWork.toString()}
                                onClick={() =>
                                  setFieldValue(
                                    "travelForWork",
                                    !values.travelForWork
                                  )
                                }
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-switch-row">
                              <div className="switch-label">
                                Booking for self?
                              </div>
                              <Radio
                                toggle
                                value={values.bookingForSelf}
                                onClick={() =>
                                  setFieldValue(
                                    "bookingForSelf",
                                    !values.bookingForSelf
                                  )
                                }
                              />
                            </div>
                          </Grid.Column>
                        </Grid> */}
                        <Grid columns={2} padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="firstName"
                                className={errors.firstName ? "color-red" : ""}
                              >
                                First Name *
                              </label>
                              <input
                                name="firstName"
                                type="text"
                                className="form-control"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="lastName"
                                className={errors.lastName ? "color-red" : ""}
                              >
                                Last Name *
                              </label>
                              <input
                                name="lastName"
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className={errors.email ? "color-red" : ""}
                              >
                                Email Address *
                              </label>
                              <input
                                name="email"
                                type="email"
                                className="form-control"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="emailVerify"
                                className={
                                  errors.emailVerify ? "color-red" : ""
                                }
                              >
                                Confirm Email Address *
                              </label>
                              <input
                                name="emailVerify"
                                type="email"
                                className="form-control"
                                id="emailVerify"
                                value={values.emailVerify}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="telephone"
                                className={errors.telephone ? "color-red" : ""}
                              >
                                Telephone *
                              </label>
                              <input
                                name="telephone"
                                type="text"
                                className="form-control"
                                id="telephone"
                                placeholder="+1..."
                                value={values.telephone}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label htmlFor="guestname">Name of Guests</label>
                              <input
                                name="guestname"
                                type="text"
                                className="form-control"
                                id="guestname"
                                value={values.guestName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="address"
                                className={errors.address ? "color-red" : ""}
                              >
                                Address *
                              </label>
                              <input
                                name="address"
                                type="text"
                                className="form-control"
                                id="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="city"
                                className={errors.city ? "color-red" : ""}
                              >
                                City *
                              </label>
                              <input
                                name="city"
                                type="text"
                                className="form-control"
                                id="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label
                                htmlFor="zip"
                                className={errors.zip ? "color-red" : ""}
                              >
                                Zip *
                              </label>
                              <input
                                name="zip"
                                type="text"
                                className="form-control"
                                id="zip"
                                value={values.zip}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid.Column>
                        </Grid>

                        <Grid padded="vertically">
                          <Grid.Column>
                            <div className="special-request-section">
                              <div className="request-title">
                                Special Requests
                              </div>
                              <div className="request-subtitle">
                                Special requests cannot be guaranteed - but the
                                property will do its best to meet your needs.
                                You can always make a special request after your
                                booking is complete!
                              </div>
                              <div className="form-group">
                                <input
                                  name="specialrequest"
                                  type="textarea"
                                  className="form-control pt-0"
                                  id="specialrequest"
                                  placeholder="Type your request"
                                />
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                        {/* <Grid padded="vertically">
                          <Grid.Column>
                            <div className="special-request-section">
                              <div className="request-title">
                                Your Arrival Time
                              </div>
                              <div className="arrival-checklist">
                                Your rooms will be ready for check-in at{" "}
                                {checkin_checkout_times.checkin_from}
                              </div>
                              <div className="arrival-checklist">
                                24-hour front desk - Help whenever you need it!
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid> */}
                        {/* <Grid padded="vertically">
                          <Grid.Column>
                            <div className="form-group">
                              <label htmlFor="guestname">
                                Your Estimated Arrival Time
                              </label>
                              <select className="form-control">
                                <option>Not sure</option>
                                <option>Before 4pm</option>
                                <option>After 4pm</option>
                              </select>
                            </div>
                          </Grid.Column>
                        </Grid> */}
                      </div>
                    </Card.Content>
                  </Card>
                </Card.Group>
                <div className="payment-btn">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-color"
                  >
                    Proceed to payment
                  </button>
                </div>
              </Grid.Column>

              {/* Right side card */}
              <Grid.Column width={6}>
                <MemoizedBookingBlockDetail
                  {...{
                    ...defaultParamsForComponents,
                    block: selectedBlock,
                    checkInFormatted: checkInFormatted,
                    checkoutFormatted: checkoutFormatted,
                  }}
                />

                {/* Cancellation card */}
                <BookingCancelationInfo
                  cancellation_info={selectedBlock.cancellation_info}
                />
                {/* Fine Print card */}
                <FinePrint />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(BookingPaymentOne);
